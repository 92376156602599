import React from 'react';
import { Outlet, Link, useParams } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FooterComponent from '../../../containers/Generic/Footer';
import CONFIG from '../../../config/config.json';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
var HeaderGenericLayoutComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_COMPANY, {
        variables: {
            ecName: params.ecName,
        },
    }).data;
    if (!params)
        return React.createElement("div", null);
    if (!data)
        return React.createElement("div", null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-row bg-slate-100 z-[999] fixed w-full' },
            React.createElement("div", { className: 'bg-white flex flex-row w-full items-center justify-between shadow-md md:h-24 h-16' },
                React.createElement(Link, { to: "/".concat(params.ecName, "/menu") },
                    React.createElement("img", { className: 'w-16  ml-6 md:w-20 md:ml-14 md:mt-2', src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(data.logoFileId || '27707'), alt: "product-".concat(data.logoFileId || '27707', " ") })),
                React.createElement("div", { className: 'flex flex-row items-center' },
                    React.createElement(Link, { to: 'reviewsProffesional', className: 'no-underline text-black flex flex-col items-center' },
                        React.createElement("div", null,
                            React.createElement(ArticleOutlinedIcon, { className: 'w-10 ' }))),
                    React.createElement("div", { className: 'w-1 h-12  md:ml-3  rounded-full' }),
                    React.createElement(Link, { to: 'favorites', className: 'no-underline text-black flex flex-col items-center' },
                        React.createElement("div", null,
                            React.createElement(FavoriteIcon, { className: 'w-10 text-red-600' }))),
                    React.createElement("div", { className: 'w-1 h-12  md:ml-3  rounded-full' }),
                    React.createElement(Link, { to: 'car', className: 'no-underline text-black flex flex-col items-center mr-8' },
                        React.createElement(Badge, { badgeContent: 4, color: 'error' },
                            React.createElement(ShoppingBagOutlinedIcon, { className: 'w-10 ' })))))),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full w-screen bg-slate-100' },
            React.createElement(Outlet, null)),
        React.createElement("div", null,
            React.createElement(FooterComponent, null))));
};
export default HeaderGenericLayoutComponent;
