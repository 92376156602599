import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
var ReturnsStaticInformationComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_COMPANY, {
        variables: {
            ecName: params.ecName,
        },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'mx-10 h-full md:h-screen mt-32' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h6', className: 'text-black/60', gutterBottom: true }, "Devoluciones"),
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "Pol\u00EDticas de Devoluciones"))),
            React.createElement("div", { className: 'flex flex-row flex-1 mt-10' },
                React.createElement("div", { className: 'h-full w-3 bg-capitalike-gray-font rounded-full mr-5' }),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' },
                        "En ",
                        data.name,
                        " queremos que tu experiencia sea 100% satisfactoria, por ello queremos comunicarte nuestra pol\u00EDtica de cambios y devoluciones:"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "- Las ventas no tendr\u00E1n que exceder de 7 d\u00EDas despu\u00E9s de haber recibido la compra para que proceda la devoluci\u00F3n."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "- Los productos a devolver tendr\u00E1 que estar en perfecto estado, asimismo, con etiquetas originales, por lo que validaremos que los productos se encuentren sin se\u00F1ales de uso y cuente con el empaque original para proceder con la devoluci\u00F3n."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "- Al recibir una devoluci\u00F3n el cliente tendr\u00E1 que contar con su ticket de compra, sin excepci\u00F3n."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "- No proceder\u00E1 la devoluci\u00F3n cuando los art\u00EDculos sean devueltos en malas condiciones o productos que por higiene no pueden ponerse a la venta de nuevo."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "\u00BFCu\u00E1l es el proceso para solicitar una devoluci\u00F3n?"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "El proceso de devoluci\u00F3n ser\u00E1 a trav\u00E9s de nuestra p\u00E1gina web. Una vez que tu compra se marca como entregada, tendr\u00E1 la opci\u00F3n de solicitar la devoluci\u00F3n, \u00FAnicamente deber\u00E1 llenar el formulario que se solicita."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Una vez recibido tu paquete, ser\u00E1 evaluado para verificar que cumple con los requisitos establecidos. De ser as\u00ED, ser\u00E1 devuelto el importe de la compra (sin incluir el pago del env\u00EDo, ya que la empresa de paqueter\u00EDa no lo reintegra)."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Los gastos derivados de la devoluci\u00F3n del producto ser\u00E1n a cargo del comprador. S\u00F3lo en caso que el producto enviado presente fallas de f\u00E1brica o se env\u00EDe un producto incorrecto, correremos con los gastos de env\u00EDo del nuevo producto y retorno de la pieza en mal estado o incorrecta."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' },
                        React.createElement("strong", null, "\u00DAltima actualizaci\u00F3n 3 de febrero de 2020")))))));
};
export default ReturnsStaticInformationComponent;
