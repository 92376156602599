import React from 'react';
import CONFIG from '../../../../../../config/config.json';
var ProductItemProductListComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-col overflow-auto' },
        React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                    React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId || '28311'), alt: "product-".concat(props.fileId || '28311'), className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, props.name)))));
};
export default ProductItemProductListComponent;
