import React from 'react';
import { Divider, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';
import CONFIG from '../../../../../../../../config/config.json';
import currencyFormat from '../../../../../../../../utils/currencyFormat';
var ProductItemProductListComponent = function (props) {
    var navigate = useNavigate();
    var handleButtonClick = function () {
        navigate("/".concat(props.ecName, "/menu/classification/").concat(props.classificationId, "/productSet/").concat(props.id));
    };
    return (React.createElement("div", { className: 'flex flex-col md:w-72 lg:w-60 bg-white rounded-l-md  rounded-md mb-2 md:mb-10 md:ml-12 lg:ml-0 mr-7 md:mr-0 logo ' },
        React.createElement("div", { className: 'flex md:flex-col flex-row rounded-xl  mt-5', role: 'button', tabIndex: -1, onClick: handleButtonClick },
            React.createElement("div", { className: 'md:w-full' },
                React.createElement("img", { className: "flex ml-3 md:p-0 rounded-t-xl object-contain overflow-hidden my-3 bg-white \n              ".concat(props.fileId
                        ? 'w-32 h-32 md:ml-0 md:w-full md:h-52'
                        : 'w-32 p-5 h-32 md:ml-10 md:w-40 md:h-52'), src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId || '28311'), alt: "product-".concat(props.fileId || '28311') })),
            React.createElement(Divider, { className: 'bg-capitalike-gray-font' }),
            React.createElement("div", { className: 'rounded-xl md:rounded-t-none w-full md:w-72 lg:w-60  h-16 flex flex-col md:flex-row' },
                React.createElement("div", { className: 'flex flex-1 flex-col my-1 md:ml-3' },
                    React.createElement("div", { className: 'mt-3 md:mt-1 md:ml-2 text-lg md:text-sm text-gray-600 overflow-hidden whitespace-nowrap w-44 md:w-40' }, props.name),
                    React.createElement("div", { className: 'md:ml-2 text-base font-semibold' },
                        "$",
                        currencyFormat(props.price))),
                React.createElement("div", { className: 'mt-3 mr-1' },
                    React.createElement(IconButton, null,
                        React.createElement(FavoriteBorderIcon, { className: 'text-red-500' })))))));
};
export default ProductItemProductListComponent;
