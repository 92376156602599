var getRouteFromProductMenuValueEs = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'services';
        case 2:
            return 'aboutUs';
        case 3:
            return 'contact';
        default:
            return false;
    }
};
export default getRouteFromProductMenuValueEs;
