import React from 'react';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useParams } from 'react-router-dom';
import getTranslation from '../../../../locale/lachime/translation';
var ContactEsComponentChime = function () {
    var lang = useParams().lang;
    var currentLang = lang === 'en' ? 'en' : 'es';
    return (React.createElement("div", { className: 'flex flex-col flex-1 h-screen w-full text-white overflow-auto' },
        React.createElement("div", { className: 'mb-28' }),
        React.createElement("img", { className: 'w-full mb-4', src: 'https://storage.capitalike.com/files/31821', alt: 'IMAGEN' }),
        React.createElement("div", { className: 'md:px-44 ' },
            React.createElement("div", { className: "font-['GoBoldBold'] text-chime-pink text-center text-3xl font-bold" }, getTranslation(currentLang, 'contact.contact')),
            React.createElement("div", { className: 'flex flex-col px-10' },
                React.createElement("div", { className: 'flex flex-row mt-5' },
                    React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: getTranslation(currentLang, 'contact.lname') }),
                    React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: getTranslation(currentLang, 'contact.llastname') })),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: getTranslation(currentLang, 'contact.lemail') }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: getTranslation(currentLang, 'contact.lreason') }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: getTranslation(currentLang, 'contact.lstate') }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: getTranslation(currentLang, 'contact.lcity') }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: getTranslation(currentLang, 'contact.lmessage') })),
            React.createElement("div", { className: 'text-center' },
                React.createElement(Button, { variant: 'contained', endIcon: React.createElement(SendIcon, null), className: 'bg-chime-pink mb-10 mt-4 w-32' }, getTranslation(currentLang, 'contact.send'))))));
};
export default ContactEsComponentChime;
