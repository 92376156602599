import { IconButton } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import React, { useEffect, useRef, useState } from 'react';
var SliderPrincipal = function () {
    var sliderRef = useRef(null);
    var scrollLeft = function () {
        if (sliderRef.current)
            sliderRef.current.scrollBy({ left: -1440, behavior: 'smooth' });
    };
    var _a = useState(0), scrollPosition = _a[0], setScrollPosition = _a[1];
    var scrollAmount = 1440;
    var scrollRight = function () {
        if (sliderRef.current) {
            var newPosition = scrollPosition + scrollAmount;
            sliderRef.current.scrollTo({ left: newPosition, behavior: 'smooth' });
            setScrollPosition(newPosition);
        }
    };
    useEffect(function () {
        var interval = setInterval(function () {
            if (sliderRef.current) {
                var maxScrollLeft = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
                if (scrollPosition >= maxScrollLeft) {
                    setScrollPosition(0);
                    sliderRef.current.scrollTo({ left: 0, behavior: 'smooth' });
                }
                else {
                    scrollRight();
                }
            }
        }, 3000);
        return function () { return clearInterval(interval); };
    }, [scrollPosition]);
    return (React.createElement("div", { className: 'w-full' },
        React.createElement("div", { ref: sliderRef, className: 'flex overflow-scroll whitespace-nowrap' },
            React.createElement("div", { className: 'w-screen' },
                React.createElement("div", { className: 'hidden md:flex w-screen' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/35112', alt: 'Slide', className: 'w-full' })),
                React.createElement("div", { className: 'w-screen md:hidden' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/35468', alt: '', className: 'w-full h-100' }))),
            React.createElement("div", { className: ' w-screen' },
                React.createElement("div", { className: 'hidden md:flex w-screen' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/33697', alt: 'Slide', className: 'w-full' })),
                React.createElement("div", { className: 'w-screen md:hidden' },
                    React.createElement("img", { src: 'https://efeverde.com/wp-content/uploads/2022/01/Delmon1-scaled-1.jpg', alt: '', className: 'w-full' }))),
            React.createElement("div", { className: 'w-screen' },
                React.createElement("div", { className: 'w-screen md:flex hidden' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/33636', alt: 'Slide', className: 'w-full' })),
                React.createElement("div", { className: 'w-screen md:hidden' },
                    React.createElement("img", { src: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Sarsaparilla-1271142_1920.jpg', alt: '', className: 'w-full' })))),
        React.createElement("div", { className: 'text-center' },
            React.createElement(IconButton, { onClick: scrollLeft },
                React.createElement(ArrowCircleLeftIcon, { fontSize: 'large', className: 'text-capitalike-gray-font/40' })),
            React.createElement(IconButton, { onClick: scrollRight },
                React.createElement(ArrowCircleRightIcon, { fontSize: 'large', className: 'text-capitalike-gray-font/40' })))));
};
export default SliderPrincipal;
