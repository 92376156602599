var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
var usePresentationStore = create(function (set) { return ({
    selectedPresentation: null,
    selectedIngredients: {},
    setSelectedPresentation: function (presentation) { return set({ selectedPresentation: presentation }); },
    setSelectedIngredient: function (variationName, ingredient) {
        return set(function (state) {
            var _a;
            return {
                selectedIngredients: __assign(__assign({}, state.selectedIngredients), (_a = {}, _a[variationName] = {
                    ingredient: ingredient,
                }, _a)),
            };
        });
    },
    resetSelectedPresentation: function () { return set({ selectedPresentation: null, selectedIngredients: {} }); },
}); });
export default usePresentationStore;
