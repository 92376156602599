import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import CONFIG from '../../../config/config.json';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
var AboutUsStaticInformationComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_COMPANY, {
        variables: {
            ecName: params.ecName,
        },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: ' h-full mt-24' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, textAlign: 'center', className: 'h-32 md:h-52 mt-4' },
                React.createElement("img", { className: 'w-40 md:w-80', src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(data.logoFileId || '27707'), alt: "product-".concat(data.logoFileId || '27707') })),
            React.createElement("div", { className: 'flex flex-row h-screen' },
                React.createElement("div", { className: 'h-3/5 w-3 bg-capitalike-gray-font rounded-full mr-5 md:ml-52' }),
                React.createElement("div", { className: ' p-4 md:p-0 md:pr-20 text-justify leading-none' },
                    React.createElement(Grid, { item: true, xs: 12, className: 'mt-7' },
                        React.createElement(Typography, { variant: 'h5' },
                            React.createElement("strong", null, "\u00BFQuien\u00E9s somos?"))),
                    React.createElement(Grid, { item: true, xs: 12, className: '' },
                        React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Nosotros creemos que la vida es un regalo del universo, y como seres humanos tenemos la misi\u00F3n de crearla y planificarla para ser felices."),
                        React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta te da la idea del regalo perfecto, tu compromiso y misi\u00F3n es personalizarlo, plasmando emociones, momentos, gustos, personalidades de los seres que forman parte de tu vida. Son regalos indestructibles que se vuelven peque\u00F1os recuerdos f\u00EDsicos de su historia.")),
                    React.createElement(Grid, { item: true, xs: 12, className: 'mt-20' },
                        React.createElement(Typography, { variant: 'h5' },
                            React.createElement("strong", null, "Historia"))),
                    React.createElement(Grid, { item: true, xs: 12, className: '' },
                        React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta Cln comienza con el sue\u00F1o de una madre de crear una tienda de regalos fuera de lo ordinario, en donde el cliente pudiera expresar sus ideas y sentimientos para as\u00ED crear entre ambos recuerdos indestructibles."),
                        React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "El sue\u00F1o llev\u00F3 a\u00F1os volverse realidad hasta que un d\u00EDa junto con su hija decide ver las posibilidades de cumplirlo."),
                        React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta abre sus puertas en febrero del 2019 en Culiac\u00E1n Sinaloa, comenzando una traves\u00EDa en donde no solo se han conocido personas/clientes espectaculares, sino que tambi\u00E9n ha abierto las puertas para fortalecer el v\u00EDnculo entre una madre e hija."),
                        React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta espera seguir siendo parte de los momentos especiales de cada una de las personas por un largo tiempo.")))))));
};
export default AboutUsStaticInformationComponent;
