import React from 'react';
// import CopyrightEnComponent from '../../Copyright';
var TopMenuEn = function () {
    return (React.createElement("div", null,
        React.createElement("div", { className: 'flex flex-col overflow-auto' },
            React.createElement("div", { className: 'text-center text-5xl font-bold m-10 text-chime-pink mt-20 mb-32' },
                "CHIME ",
                React.createElement("span", { className: 'text-black' }, "FAVORITES!")),
            React.createElement("div", { className: 'flex-row flex-1 items-center justify-center grid grid-cols-2 md:grid-cols-4' },
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/10254', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "CHIMENEA CARAMELO"))),
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/16825', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "BOBA CHOCO BOMBASTIC"))),
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/10271', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "BOBA MOKA CARBON"))),
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/10268', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "BOBA ZARZAMORA"))),
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/10306', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "SUNDAE CHEESECAKE"))),
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/10459', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "CHIMENEA CARAMELO"))),
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/15624', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "SUNDAE MICKEY"))),
                React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
                    React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                        React.createElement("div", { className: 'flex justify-center h-48 md:h-60 p-3' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/10320', alt: '', className: 'h-36 md:object-contain md:w-32 md:h-52' })),
                        React.createElement("span", { className: 'flex border-solid border-chime-brown bg-chime-brown w-44 md:w-56 md:ml-9' }),
                        React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center text-center text-base' }, "SODA BLUE MAGIC")))))));
};
export default TopMenuEn;
