import { Divider, IconButton } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CounterGenericComponent from '../Counter';
var ProductGenericComponent = function () {
    return (React.createElement("div", { className: '' },
        React.createElement("div", { className: 'bg-slate-100 text-2xl font-bold ml-6' }, "Productos"),
        React.createElement("div", { className: 'flex flex-col flex-1 bg-white rounded-xl shadow-md mx-5 mt-3 overflow-auto' },
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement("img", { className: 'w-10 md:w-[120px] md:h-[120px] ml-5 mt-3 rounded-xl object-contain mr-2', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "Mango Fit"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 h-12 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar."),
                    React.createElement("div", { className: 'flex flex-row items-center mt-6 justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold' }, "$449.00"),
                        React.createElement(CounterGenericComponent, null),
                        React.createElement(IconButton, { className: 'text-red-600 mr-10' },
                            React.createElement(DeleteIcon, null))))),
            React.createElement(Divider, { className: 'w-100 mt-3' }))));
};
export default ProductGenericComponent;
