import { Button } from '@mui/material';
import React from 'react';
import usePresentationStore from '../../../../../../../../../store/usePresentationStore';
var BuyProductComponent = function () {
    var selectedIngredients = usePresentationStore(function (state) { return state.selectedIngredients; });
    var selectedPresentation = usePresentationStore(function (state) { return state.selectedPresentation; });
    var saveToLocalStorage = function () {
        if (selectedIngredients)
            localStorage.setItem('selectedIngredients', JSON.stringify(selectedIngredients));
        if (selectedPresentation)
            localStorage.setItem('selectedPresentation', JSON.stringify(selectedPresentation));
        alert('Datos guardados en localStorage');
    };
    return (React.createElement("div", { className: 'ml-5 flex-col flex rounded-lg items-center' },
        React.createElement(Button, { className: 'bg-capitalike-blue font-bold text-white mb-3 w-52 hover:bg-blue-600' }, "Comprar"),
        React.createElement(Button, { className: 'bg-capitalike-gray-font/20 font-bold text-black w-52', onClick: saveToLocalStorage }, "Agregar")));
};
export default BuyProductComponent;
