import React from 'react';
import { Link, useParams } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FooterLinkComponent from './Link';
import MediaButtonComponent from './MediaButton';
import ChatButtonComponent from './ChatButton';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
var FooterComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_COMPANY, {
        variables: {
            ecName: params.ecName,
        },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col bg-white' },
        React.createElement("div", { className: 'flex p-4 sm:px-10 sm:py-8 flex-col sm:flex-row border-0 border-t-4 border-solid border-capitalike-gray-font' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'text-lg mb-1' }, "\u00BFNecesitas ayuda?"),
                React.createElement("div", null,
                    React.createElement(ChatButtonComponent, null)),
                React.createElement("div", { className: 'text-sm leading-none mt-2' }, "De lunes a viernes de 10:00 a 18:00")),
            React.createElement("div", { className: 'flex flex-1 pt-4 sm:pt-0 sm:pl-16' },
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: 'text-lg' }, "Informaci\u00F3n General"),
                    React.createElement(FooterLinkComponent, { to: 'howBuy' }, "\u00BFC\u00F3mo comprar?"),
                    React.createElement(FooterLinkComponent, { to: 'paymentMethods' }, "Formas de pago"),
                    React.createElement(FooterLinkComponent, { to: 'shipping' }, "Env\u00EDos y Paqueter\u00EDas"),
                    React.createElement(FooterLinkComponent, { to: 'returns' }, "Devoluciones"),
                    React.createElement(FooterLinkComponent, { to: 'invoicing' }, "Facturaci\u00F3n")),
                React.createElement("div", { className: 'sm:ml-16 flex flex-col ml-8 md:ml-0' },
                    React.createElement("div", { className: 'text-lg' }, "Acerca de Nosotros"),
                    React.createElement(FooterLinkComponent, { to: 'aboutUs' }, "\u00BFQui\u00E9nes S\u00F3mos?"),
                    React.createElement(FooterLinkComponent, { to: 'ourShops' }, "Nuestras Tiendas"),
                    React.createElement(FooterLinkComponent, { to: 'contactUs' }, "Cont\u00E1ctanos"))),
            React.createElement("div", { className: 'flex flex-col items-end' },
                React.createElement("div", null,
                    React.createElement(MediaButtonComponent, { href: 'https://www.facebook.com/encantacln/' },
                        React.createElement(FacebookIcon, null)),
                    React.createElement(MediaButtonComponent, { href: 'https://www.instagram.com/encantacln/' },
                        React.createElement(InstagramIcon, null)),
                    React.createElement(MediaButtonComponent, { href: 'https://wa.me/526672941267' },
                        React.createElement(WhatsAppIcon, null))),
                React.createElement("div", { className: 'mt-1 text-sm' }, "Encu\u00E9ntranos en redes sociales."))),
        React.createElement("div", { className: 'flex p-4 sm:px-10 flex-col items-center sm:items-baseline sm:flex-row sm:py-4 border-0 border-t-2 border-b border-solid border-capitalike-gray-font text-sm' },
            React.createElement("div", { className: 'flex flex-1' },
                "\u00A9 2024 ",
                data.name,
                " Todos los derechos reservados."),
            React.createElement("div", { className: 'flex pt-2 sm:pt-0' },
                React.createElement(Link, { to: 'terms', className: 'text-[#333333] font-normal no-underline hover:underline mr-4' }, "T\u00E9rminos y Condiciones"),
                React.createElement(Link, { to: 'privacy', className: 'text-[#333333] font-normal no-underline hover:underline' }, "Aviso de Privacidad")))));
};
export default FooterComponent;
