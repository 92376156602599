import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import CONFIG from '../../../config/config.json';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
var AboutUsStaticInformationComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_COMPANY, {
        variables: {
            ecName: params.ecName,
        },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: ' h-full mt-24' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, textAlign: 'center', className: 'h-32 md:h-52 mt-4' },
                React.createElement("img", { className: 'w-40 md:w-80', src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(data.logoFileId || '27707'), alt: "product-".concat(data.logoFileId || '27707') })),
            React.createElement("div", { className: 'flex flex-row h-screen' },
                React.createElement("div", { className: 'h-3/5 w-3 bg-capitalike-gray-font rounded-full mr-5 md:ml-52' }),
                React.createElement("div", { className: ' p-4 md:p-0 md:pr-20 text-justify leading-none' },
                    React.createElement(Grid, { item: true, xs: 12, className: 'mt-7' },
                        React.createElement(Typography, { variant: 'h5' },
                            React.createElement("strong", null, "\u00BFQuien\u00E9s somos?"))),
                    React.createElement(Grid, { item: true, xs: 12, className: 'mt-20' },
                        React.createElement(Typography, { variant: 'h5' },
                            React.createElement("strong", null, "Historia"))))))));
};
export default AboutUsStaticInformationComponent;
