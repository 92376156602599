import React from 'react';
import ProductGenericComponent from './Product';
import ResumenGenericComponent from './Resumen';
var CarGenericComponent = function () {
    return (React.createElement("div", { className: 'flex flex-col h-screen w-full bg-slate-100 overflow-auto mt-24' },
        React.createElement("div", { className: 'flex flex-col md:flex-row flex-1 md:mx-32 mt-4' },
            React.createElement("div", { className: 'flex flex-1 flex-col md:ml-20' },
                React.createElement(ProductGenericComponent, null)),
            React.createElement("div", { className: 'flex flex-1 flex-col h-64' },
                React.createElement(ResumenGenericComponent, null))),
        React.createElement("div", { className: 'mb-10' })));
};
export default CarGenericComponent;
