import React from 'react';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import CopyrightCapitalikeComponent from '../Copyright';
var AboutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col mt-20 h-full' },
        React.createElement("div", { className: 'items-center flex flex-col flex-1 w-screen' },
            React.createElement("img", { 
                // src='https://storage.capitalike.com/files/35988'
                // src='https://storage.capitalike.com/files/36077'
                src: 'https://storage.capitalike.com/files/36079', alt: '', className: 'object-cover object-top w-screen h-[600px] blurr relative' }),
            React.createElement("div", { className: 'text-center text-white font-bold text-5xl mt-48 absolute' }, "Capitalike"),
            React.createElement("div", { className: 'text-white text-5xl font-bold text-center absolute mt-60' }, "El socio ideal para el crecimiento de tu negocio.")),
        React.createElement("div", { className: 'flex flex-1 flex-row mt-20' },
            React.createElement("div", { className: 'flex flex-1' },
                React.createElement("div", { className: 'h-[420px] border-2 border-solid border-capitalike-orange flex flex-1 flex-col mx-36 rounded-xl items-center text-capitalike-gray-font font-bold' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/36019', alt: '', className: 'w-40 mt-2' }),
                    React.createElement("div", { className: 'mt-5 text-3xl' }, "Misi\u00F3n"),
                    React.createElement("div", { className: 'h-1 w-16 bg-capitalike-orange' }),
                    React.createElement("div", { className: 'flex flex-1 mx-10 text-justify mt-3' }, "Nuestro objetivo es empoderar a nuestros clientes con herramientas eficientes, accesibles y adaptadas a sus necesidades, para impulsar el crecimiento y la transformaci\u00F3n digital de sus negocios."))),
            React.createElement("div", { className: 'flex flex-1' },
                React.createElement("div", { className: 'h-[420px] border-2 border-solid border-capitalike-orange flex flex-1 flex-col mx-36 rounded-xl items-center text-capitalike-gray-font font-bold' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/36020', alt: '', className: 'w-40 mt-7' }),
                    React.createElement("div", { className: 'mt-5 text-3xl' }, "Visi\u00F3n"),
                    React.createElement("div", { className: 'h-1 w-16 bg-capitalike-orange' }),
                    React.createElement("div", { className: 'flex flex-1 mx-10 text-justify mt-3' }, "Convertirnos en un referente l\u00EDder en soluciones tecnol\u00F3gicas integrales para la gesti\u00F3n de negocios, siendo reconocidos por nuestra innovaci\u00F3n, excelencia en el servicio y capacidad de adaptaci\u00F3n a las demandas del mercado.")))),
        React.createElement("div", { className: 'bg-capitalike-gray-font/10 h-80 text-center mt-20' },
            React.createElement("div", { className: 'mx-52 text-justify mt-16 font-bold text-capitalike-gray-font' },
                "En ",
                React.createElement("span", { className: 'text-capitalike-blue' }, "Capitalike"),
                ", somos una empresa comprometida con el crecimiento y \u00E9xito de tu negocio. Nos especializamos en ofrecer soluciones integrales a trav\u00E9s de puntos de venta que operan tanto offline como online, asegurando que nunca pierdas una venta, sin importar las circunstancias.",
                React.createElement("br", null),
                React.createElement("br", null),
                "Entendemos que cada negocio es \u00FAnico, por eso nuestro enfoque es proporcionar soluciones flexibles y escalables que se adapten a tus necesidades. Nuestro objetivo es ser el aliado estrat\u00E9gico que necesitas para impulsar tu crecimiento, ofreci\u00E9ndote las mejores herramientas para facilitar tu operaci\u00F3n diaria. Tu \u00E9xito es nuestro motor, y trabajamos incansablemente para brindarte la tecnolog\u00EDa y el soporte que necesitas para llegar m\u00E1s lejos.")),
        React.createElement("div", { className: 'h-screen' },
            React.createElement("div", { className: 'text-center mt-20 text-3xl text-capitalike-blue font-bold' }, "Nuestros Valores"),
            React.createElement("div", { className: ' mt-20 text-capitalike-gray-font font-bold mx-12 text-justify' }, "Creemos que el \u00E9xito de cualquier negocio no solo depende de las herramientas que utiliza, sino tambi\u00E9n de los principios que lo gu\u00EDan. Nuestros valores son la base sobre la cual construimos nuestras soluciones y relaciones, y representan el compromiso que tenemos con nuestros clientes. Son la br\u00FAjula que dirige cada aspecto de nuestro trabajo, desde el desarrollo de nuestros productos hasta la atenci\u00F3n personalizada que brindamos a cada uno de nuestros usuarios."),
            React.createElement("div", { className: 'grid grid-cols-6 gap-5 mx-10 mt-20' },
                React.createElement("div", { className: 'h-80 bg-capitalike-gray-font/10 rounded-xl text-capitalike-gray-font font-bold' },
                    React.createElement("div", { className: 'text-center mt-4 mb-4 text-capitalike-blue' },
                        React.createElement(LightbulbOutlinedIcon, { className: 'w-20 h-20' })),
                    React.createElement("div", { className: 'text-center' },
                        React.createElement("div", null, "Innovaci\u00F3n"),
                        React.createElement("div", { className: 'text-sm text-justify mt-4 mx-2' }, "Nos mantenemos a la vanguardia tecnol\u00F3gica, buscando constantemente nuevas formas de optimizar y simplificar la gesti\u00F3n de tu negocio."))),
                React.createElement("div", { className: 'h-80 bg-capitalike-gray-font/10 rounded-xl text-capitalike-gray-font font-bold' },
                    React.createElement("div", { className: 'text-center mt-4 mb-4 text-capitalike-blue' },
                        React.createElement(HandshakeOutlinedIcon, { className: 'w-20 h-20' })),
                    React.createElement("div", { className: 'text-center' },
                        React.createElement("div", null, "Compromiso"),
                        React.createElement("div", { className: 'text-sm text-justify mt-4 mx-2' }, "Nos dedicamos plenamente a ofrecerte las mejores soluciones y soporte. Sabemos que tu \u00E9xito es el nuestro."))),
                React.createElement("div", { className: 'h-80 bg-capitalike-gray-font/10 rounded-xl text-capitalike-gray-font font-bold' },
                    React.createElement("div", { className: 'text-center mt-4 mb-4 text-capitalike-blue' },
                        React.createElement(VisibilityOutlinedIcon, { className: 'w-20 h-20' })),
                    React.createElement("div", { className: 'text-center' },
                        React.createElement("div", null, "Transparencia"),
                        React.createElement("div", { className: 'text-sm text-justify mt-4 mx-2' }, "La confianza es la base de nuestras relaciones. Nos esforzamos por ser claros y honestos en cada interacci\u00F3n."))),
                React.createElement("div", { className: 'h-80 bg-capitalike-gray-font/10 rounded-xl text-capitalike-gray-font font-bold' },
                    React.createElement("div", { className: 'text-center mt-4 mb-4 text-capitalike-blue' },
                        React.createElement(AltRouteOutlinedIcon, { className: 'w-20 h-20' })),
                    React.createElement("div", { className: 'text-center' },
                        React.createElement("div", null, "Flexibilidad"),
                        React.createElement("div", { className: 'text-sm text-justify mt-4 mx-2' }, "Cada negocio es \u00FAnico, y en Capitalike lo entendemos. Nuestras soluciones est\u00E1n dise\u00F1adas para adaptarse a tus necesidades y crecer junto contigo."))),
                React.createElement("div", { className: 'h-80 bg-capitalike-gray-font/10 rounded-xl text-capitalike-gray-font font-bold' },
                    React.createElement("div", { className: 'text-center mt-4 mb-4 text-capitalike-blue' },
                        React.createElement(SecurityOutlinedIcon, { className: 'w-20 h-20' })),
                    React.createElement("div", { className: 'text-center' },
                        React.createElement("div", null, "Cercan\u00EDa"),
                        React.createElement("div", { className: 'text-sm text-justify mt-4 mx-2' }, "Creemos en relaciones cercanas y humanas. Nos preocupamos por comprender los desaf\u00EDos de tu negocio y ofrecerte asesor\u00EDas personalizadas."))),
                React.createElement("div", { className: 'h-80 bg-capitalike-gray-font/10 rounded-xl text-capitalike-gray-font font-bold' },
                    React.createElement("div", { className: 'text-center mt-4 mb-4 text-capitalike-blue' },
                        React.createElement(WorkspacePremiumOutlinedIcon, { className: 'w-20 h-20' })),
                    React.createElement("div", { className: 'text-center' },
                        React.createElement("div", null, "Excelencia"),
                        React.createElement("div", { className: 'text-sm text-justify mt-4 mx-2' }, "Nos comprometemos a ofrecer un servicio de alta calidad en todo lo que hacemos. Nos esforzamos por ser la mejor herramienta empresarial."))))),
        React.createElement(CopyrightCapitalikeComponent, null)));
};
export default AboutComponent;
