import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, useTheme, useMediaQuery, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import React, { useState } from 'react';
var ReviewsProComponent = function () {
    var theme = useTheme();
    var fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement("div", { className: 'h-full mt-28 md:m-40' },
        React.createElement("div", { className: 'flex flex-row flex-1 mb-5 justify-between' },
            React.createElement("div", { className: 'ml-5 text-xl md:text-2xl font-bold mt-5' }, "Rese\u00F1as de profesionales"),
            React.createElement("div", { className: 'bg-white rounded-full h-10 w-60 mr-7 border border-solid border-capitalike-gray-font/20 mt-8 md:mt-3 flex flex-row items-center' },
                React.createElement(SearchIcon, { className: 'mt-1 md:mt-2 ml-2 text-capitalike-gray-font' }),
                React.createElement(TextField, { variant: 'standard', className: 'ml-2 w-28 md:w-44 ' }))),
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-3 gap-8 mx-5' },
            React.createElement("div", { className: 'bg-white shadow-xl h-[610px] flex flex-col rounded-xl border border-solid border-capitalike-gray-font/50' },
                React.createElement("div", { className: 'flex flex-1 overflow-hidden w-100' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: '', className: 'object-fill w-full rounded-t-xl' })),
                React.createElement("div", { className: 'h-20 flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-2 text-capitalike-gray-font' },
                        React.createElement("span", { className: 'font-bold' }, "Nombre:"),
                        " L.N Sandra Arias Lopez"),
                    React.createElement("div", { className: 'flex flex-1 flex-row justify-between' },
                        React.createElement("div", { className: 'flex flex-rows items-center' },
                            React.createElement("div", { className: 'ml-3 font-bold text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarBorderIcon, { className: 'text-2xl' }))),
                        React.createElement(Button, { className: 'border border-solid border-capitalike-gray-font text-capitalike-gray-font font-bold rounded-full text-xs h-8 mt-2 mr-2', onClick: handleClickOpen }, "Ver m\u00E1s")))),
            React.createElement(Dialog, { open: open, fullScreen: fullScreen, onClose: handleClose, maxWidth: 'lg', PaperProps: {
                    style: {
                        width: '1000px',
                    },
                } },
                React.createElement(DialogTitle, { className: 'flex flex-1 flex-row-reverse md:flex-row ' },
                    React.createElement("div", { className: 'flex flex-1' },
                        React.createElement("div", { className: 'flex flex-1 flex-col md:flex-row items-center md:ml-14 mr-32 md:mr-0' },
                            React.createElement("div", { className: 'font-bold text-capitalike-gray-font text-base md:text-2xl' }, "Puntuaci\u00F3n:"),
                            React.createElement("div", { className: 'md:ml-3 font-bold text-base md:text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400 text-xs md:text-2xl' },
                                React.createElement(StarIcon, null),
                                React.createElement(StarIcon, null),
                                React.createElement(StarIcon, null),
                                React.createElement(StarIcon, null),
                                React.createElement(StarBorderIcon, null)))),
                    React.createElement("div", { className: 'hidden md:flex flex-1 text-center md:ml-24' },
                        React.createElement("div", { className: 'flex md:text-3xl text-capitalike-gray-font ' }, "Rese\u00F1a")),
                    React.createElement("div", { className: 'flex flex-1 flex-row md:justify-between' },
                        React.createElement("div", { className: 'hidden md:flex' }),
                        React.createElement("div", null,
                            React.createElement(IconButton, { onClick: handleClose },
                                React.createElement(CloseIcon, { className: 'text-capitalike-gray-font text-3xl hidden md:flex' }),
                                React.createElement(ArrowBackIcon, { className: 'text-capitalike-gray-font text-3xl md:hidden' }))))),
                React.createElement(DialogContent, { className: 'h-screen  flex flex-col md:flex-row' },
                    React.createElement("div", { className: 'flex flex-1' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: 'LOGO', className: 'md:ml-14 rounded-md w-full mr-10' })),
                    React.createElement("div", { className: 'flex flex-1 flex-col mt-5 md:mt-0' },
                        React.createElement("div", { className: 'bg-capitalike-gray-font/20 rounded-sm' },
                            React.createElement("div", { className: 'font-bold my-2 ml-2' }, "Nombre")),
                        React.createElement("div", { className: 'mb-4' }, "L.N Sandra Arias L\u00F3pez"),
                        React.createElement("div", { className: 'bg-capitalike-gray-font/20 rounded-sm' },
                            React.createElement("div", { className: 'font-bold my-2 ml-2' }, "N\u00FAmero")),
                        React.createElement("div", { className: 'mb-4' }, "6677522535"),
                        React.createElement("div", { className: 'bg-capitalike-gray-font/20 rounded-sm' },
                            React.createElement("div", { className: 'font-bold my-2 ml-2' }, "Instagram")),
                        React.createElement("div", { className: 'mb-4' }, "@sandraariasclinica"),
                        React.createElement("div", { className: 'bg-capitalike-gray-font/20 rounded-sm' },
                            React.createElement("div", { className: 'font-bold my-2 ml-2' }, "Facebook")),
                        React.createElement("div", { className: 'mb-4' }, "sandra arias cl\u00EDnica"),
                        React.createElement("div", { className: 'bg-capitalike-gray-font/20 rounded-sm' },
                            React.createElement("div", { className: 'font-bold my-2 ml-2' }, "Web")),
                        React.createElement("div", { className: 'mb-4' }, "sandraariasclinica.com"),
                        React.createElement("div", { className: 'font-bold bg-capitalike-gray-font/20 rounded-sm' },
                            React.createElement("div", { className: 'font-bold my-2 ml-2' }, "Rese\u00F1a")),
                        React.createElement("div", { className: 'text-justify leading-7' }, "Desde que empec\u00E9 a usar este suplemento, he visto mejoras notables en mi bienestar. Mi calidad de sue\u00F1o ha mejorado, durmiendo m\u00E1s profundamente y despertando m\u00E1s descansado. Me siento m\u00E1s relajado y menos ansioso, lo que ha impactado positivamente mi estado emocional. La digesti\u00F3n tambi\u00E9n ha mejorado; el estre\u00F1imiento se ha aliviado y mi cuerpo parece absorber mejor los nutrientes.")))),
            React.createElement("div", { className: 'bg-white shadow-xl h-[610px] flex flex-col rounded-xl border border-solid border-capitalike-gray-font/50' },
                React.createElement("div", { className: 'flex flex-1 overflow-hidden w-100' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: '', className: 'object-fill w-full rounded-t-xl' })),
                React.createElement("div", { className: 'h-20 flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-2 text-capitalike-gray-font' },
                        React.createElement("span", { className: 'font-bold' }, "Nombre:"),
                        " L.N Sandra Arias Lopez"),
                    React.createElement("div", { className: 'flex flex-1 flex-row justify-between' },
                        React.createElement("div", { className: 'flex flex-rows items-center' },
                            React.createElement("div", { className: 'ml-3 font-bold text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarBorderIcon, { className: 'text-2xl' }))),
                        React.createElement(Button, { className: 'border border-solid border-capitalike-gray-font text-capitalike-gray-font font-bold rounded-full text-xs h-8 mt-2 mr-2', onClick: handleClickOpen }, "Ver m\u00E1s")))),
            React.createElement("div", { className: 'bg-white shadow-xl h-[610px] flex flex-col rounded-xl border border-solid border-capitalike-gray-font/50' },
                React.createElement("div", { className: 'flex flex-1 overflow-hidden w-100' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: '', className: 'object-fill w-full rounded-t-xl' })),
                React.createElement("div", { className: 'h-20 flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-2 text-capitalike-gray-font' },
                        React.createElement("span", { className: 'font-bold' }, "Nombre:"),
                        " L.N Sandra Arias Lopez"),
                    React.createElement("div", { className: 'flex flex-1 flex-row justify-between' },
                        React.createElement("div", { className: 'flex flex-rows items-center' },
                            React.createElement("div", { className: 'ml-3 font-bold text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarBorderIcon, { className: 'text-2xl' }))),
                        React.createElement(Button, { className: 'border border-solid border-capitalike-gray-font text-capitalike-gray-font font-bold rounded-full text-xs h-8 mt-2 mr-2', onClick: handleClickOpen }, "Ver m\u00E1s")))),
            React.createElement("div", { className: 'bg-white shadow-xl h-[610px] flex flex-col rounded-xl border border-solid border-capitalike-gray-font/50' },
                React.createElement("div", { className: 'flex flex-1 overflow-hidden w-100' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: '', className: 'object-fill w-full rounded-t-xl' })),
                React.createElement("div", { className: 'h-20 flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-2 text-capitalike-gray-font' },
                        React.createElement("span", { className: 'font-bold' }, "Nombre:"),
                        " L.N Sandra Arias Lopez"),
                    React.createElement("div", { className: 'flex flex-1 flex-row justify-between' },
                        React.createElement("div", { className: 'flex flex-rows items-center' },
                            React.createElement("div", { className: 'ml-3 font-bold text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarBorderIcon, { className: 'text-2xl' }))),
                        React.createElement(Button, { className: 'border border-solid border-capitalike-gray-font text-capitalike-gray-font font-bold rounded-full text-xs h-8 mt-2 mr-2', onClick: handleClickOpen }, "Ver m\u00E1s")))),
            React.createElement("div", { className: 'bg-white shadow-xl h-[610px] flex flex-col rounded-xl border border-solid border-capitalike-gray-font/50' },
                React.createElement("div", { className: 'flex flex-1 overflow-hidden w-100' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: '', className: 'object-fill w-full rounded-t-xl' })),
                React.createElement("div", { className: 'h-20 flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-2 text-capitalike-gray-font' },
                        React.createElement("span", { className: 'font-bold' }, "Nombre:"),
                        " L.N Sandra Arias Lopez"),
                    React.createElement("div", { className: 'flex flex-1 flex-row justify-between' },
                        React.createElement("div", { className: 'flex flex-rows items-center' },
                            React.createElement("div", { className: 'ml-3 font-bold text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarBorderIcon, { className: 'text-2xl' }))),
                        React.createElement(Button, { className: 'border border-solid border-capitalike-gray-font text-capitalike-gray-font font-bold rounded-full text-xs h-8 mt-2 mr-2', onClick: handleClickOpen }, "Ver m\u00E1s")))),
            React.createElement("div", { className: 'bg-white shadow-xl h-[610px] flex flex-col rounded-xl border border-solid border-capitalike-gray-font/50' },
                React.createElement("div", { className: 'flex flex-1 overflow-hidden w-100' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: '', className: 'object-fill w-full rounded-t-xl' })),
                React.createElement("div", { className: 'h-20 flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-2 text-capitalike-gray-font' },
                        React.createElement("span", { className: 'font-bold' }, "Nombre:"),
                        " L.N Sandra Arias Lopez"),
                    React.createElement("div", { className: 'flex flex-1 flex-row justify-between' },
                        React.createElement("div", { className: 'flex flex-rows items-center' },
                            React.createElement("div", { className: 'ml-3 font-bold text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarBorderIcon, { className: 'text-2xl' }))),
                        React.createElement(Button, { className: 'border border-solid border-capitalike-gray-font text-capitalike-gray-font font-bold rounded-full text-xs h-8 mt-2 mr-2', onClick: handleClickOpen }, "Ver m\u00E1s")))),
            React.createElement("div", { className: 'bg-white shadow-xl h-[610px] flex flex-col rounded-xl border border-solid border-capitalike-gray-font/50' },
                React.createElement("div", { className: 'flex flex-1 overflow-hidden w-100' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/34322', alt: '', className: 'object-fill w-full rounded-t-xl' })),
                React.createElement("div", { className: 'h-20 flex flex-col' },
                    React.createElement("div", { className: 'ml-3 mt-2 text-capitalike-gray-font' },
                        React.createElement("span", { className: 'font-bold' }, "Nombre:"),
                        " L.N Sandra Arias Lopez"),
                    React.createElement("div", { className: 'flex flex-1 flex-row justify-between' },
                        React.createElement("div", { className: 'flex flex-rows items-center' },
                            React.createElement("div", { className: 'ml-3 font-bold text-2xl text-capitalike-gray-font' }, "4.5"),
                            React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarIcon, { className: 'text-2xl' }),
                                React.createElement(StarBorderIcon, { className: 'text-2xl' }))),
                        React.createElement(Button, { className: 'border border-solid border-capitalike-gray-font text-capitalike-gray-font font-bold rounded-full text-xs h-8 mt-2 mr-2', onClick: handleClickOpen }, "Ver m\u00E1s"))))),
        React.createElement("div", { className: 'mb-10' })));
};
export default ReviewsProComponent;
