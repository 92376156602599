import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import NotFoundComponent from './NotFound';
// import LandingComponent from '../containers/Landing';
import LandingLayoutComponent from './LandingLayout';
// import ClassificationComponent from '../containers/Classification';
// import ProductComponent from '../containers/Product';
// import SignupComponent from '../containers/_Session/Signup';
import ForgotPasswordComponent from '../containers/_Session/ForgotPassword';
import ContactCapitalikeComponent from '../containers/Capitalike/Contact';
import PaymentComponentEcommerce from '../containers/_Payment';
import ContactEsComponentChime from '../containers/LaChime/LaChimeTemplateEs/Contact';
import FranchiseEsComponent from '../containers/LaChime/LaChimeTemplateEs/Franchise';
import LocationEsComponent from '../containers/LaChime/LaChimeTemplateEs/Location';
import LaChimeEnTemplateComponent from '../containers/LaChime/LaChimeTemplateEn';
import HearderEnComponentChime from './LaChime/LaChimeEn/LaChimeLayout';
import MenuEnComponentChime from '../containers/LaChime/LaChimeTemplateEn/Menu';
import ContactEnComponentChime from '../containers/LaChime/LaChimeTemplateEn/Contact';
import LocationEnComponent from '../containers/LaChime/LaChimeTemplateEn/Location';
import FranchiseEnComponent from '../containers/LaChime/LaChimeTemplateEn/Franchise';
import MenuEsComponentChime from '../containers/LaChime/LaChimeTemplateEs/Menu';
import HearderEsComponentChime from './LaChime/LaChimeEs/LaChimeLayout';
import LaChimeEsTemplateComponent from '../containers/LaChime/LaChimeTemplateEs';
import TopMenuEs from '../containers/LaChime/LaChimeTemplateEs/Menu/TopMenu';
import ClassificationItemProductListComponent from '../containers/LaChime/productList/Body/ClassificationItem';
import TopMenuEn from '../containers/LaChime/LaChimeTemplateEn/Menu/TopMenu';
import HeaderGenericLayoutComponent from './Generic/GenericLayout';
// import LoginGenericComponent from '../containers/Generic/Login';
// import CategoryGenericComponent from '../containers/Generic/Car/Product/ProductList/Category';
// import PrincipalGenericComponent from '../containers/Generic/First';
import CapitalikeComponent from '../containers/Capitalike';
import HeaderCapitalikeComponent from './CompanyLayout/Capitalike/HeaderCapitalike';
import FavoriteGenericComponent from '../containers/Generic/Favorite';
import CarGenericComponent from '../containers/Generic/Car';
import ShippingGenericComponent from '../containers/Generic/Car/Shipping';
import ClassificationGenericItemProductListComponent from '../containers/Generic/Car/Product/ProductList/Body/ClassificationItem';
import PrincipalV2GenericComponent from '../containers/Generic/Car/Product/ProductList/Category';
// import FirstGenericComponent from '../containsers/Generic/First';
import AboutUsStaticInformationComponent from '../containers/Generic/StaticInformation/aboutUs';
import ContactUsStaticInformationComponent from '../containers/StaticInformation/ContactUs/Form';
import InvoivingStaticInformationComponent from '../containers/Generic/StaticInformation/Invoicing';
import HowBuyStaticInformationComponent from '../containers/Generic/StaticInformation/howBuy';
import OurShopsStaticInformationComponent from '../containers/Generic/StaticInformation/ourShops';
import PaymentMethodsStaticInformationComponent from '../containers/Generic/StaticInformation/paymentMethods';
import PrivacyStaticInformationComponent from '../containers/Generic/StaticInformation/privacy';
import ReturnsStaticInformationComponent from '../containers/Generic/StaticInformation/returns';
import ShippingStaticInformationComponent from '../containers/Generic/StaticInformation/shipping';
import TermsStaticInformationComponent from '../containers/Generic/StaticInformation/terms';
// import ReviewsGenericComponent from '../containers/Generic/ReviewsGeneric';
import ProductSetComponent from '../containers/Generic/Car/Product/ProductList/Body/ClassificationItem/ProductSet';
// import SimpleSlider from '../containers/Generic/Slider';
// import ReviewsGenericComponent from '../containers/Generic/ReviewsGeneric';
import ReviewsProComponent from '../containers/Generic/reviewsPro';
import ChatComponent from '../containers/Chat';
import AboutUsComponent from '../containers/Capitalike/About';
import ServicesComponent from '../containers/Capitalike/PlanServices';
// const children = [
//   { index: true, element: <LandingComponent /> },
//   { path: 'aboutUs', element: <AboutUsStaticInformationComponent /> },
//   { path: 'chat', element: <ChatComponent /> },
//   { path: 'classification/:classificationId', element: <ClassificationComponent /> },
//   { path: 'contactUs', element: <ContactUsStaticInformationComponent /> },
//   { path: 'forgotPassword', element: <ForgotPasswordComponent /> },
//   { path: 'howBuy', element: <HowBuyStaticInformationComponent /> },
//   { path: 'invoicing', element: <InvoivingStaticInformationComponent /> },
//   { path: 'ourShops', element: <OurShopsStaticInformationComponent /> },
//   { path: 'paymentMethods', element: <PaymentMethodsStaticInformationComponent /> },
//   { path: 'product/:productId', element: <ProductComponent /> },
//   { path: 'privacy', element: <PrivacyStaticInformationComponent /> },
//   { path: 'returns', element: <ReturnsStaticInformationComponent /> },
//   { path: 'shipping', element: <ShippingStaticInformationComponent /> },
//   { path: 'signup', element: <SignupComponent /> },
//   { path: 'terms', element: <TermsStaticInformationComponent /> },
//   { path: 'comments', element: <ReviewsGenericComponent /> },
// ];
var children = [
    { index: true, element: React.createElement(HeaderGenericLayoutComponent, null) },
    {
        path: 'menu',
        element: React.createElement(PrincipalV2GenericComponent, null),
        children: [
            { index: true, element: React.createElement(ClassificationGenericItemProductListComponent, null) },
            {
                path: 'classification/:classificationId',
                element: React.createElement(ClassificationGenericItemProductListComponent, null),
            },
        ],
    },
    { path: 'favorites', element: React.createElement(FavoriteGenericComponent, null) },
    {
        path: 'car',
        children: [
            { index: true, element: React.createElement(CarGenericComponent, null) },
            {
                path: 'pay',
                element: React.createElement(ShippingGenericComponent, null),
            },
        ],
    },
    { path: 'aboutUs', element: React.createElement(AboutUsStaticInformationComponent, null) },
    { path: 'chat', element: React.createElement(ChatComponent, null) },
    { path: 'contactUs', element: React.createElement(ContactUsStaticInformationComponent, null) },
    { path: 'forgotPassword', element: React.createElement(ForgotPasswordComponent, null) },
    { path: 'howBuy', element: React.createElement(HowBuyStaticInformationComponent, null) },
    { path: 'invoicing', element: React.createElement(InvoivingStaticInformationComponent, null) },
    { path: 'ourShops', element: React.createElement(OurShopsStaticInformationComponent, null) },
    { path: 'paymentMethods', element: React.createElement(PaymentMethodsStaticInformationComponent, null) },
    { path: 'privacy', element: React.createElement(PrivacyStaticInformationComponent, null) },
    { path: 'returns', element: React.createElement(ReturnsStaticInformationComponent, null) },
    { path: 'shipping', element: React.createElement(ShippingStaticInformationComponent, null) },
    { path: 'terms', element: React.createElement(TermsStaticInformationComponent, null) },
    { path: 'reviewsProffesional', element: React.createElement(ReviewsProComponent, null) },
    {
        path: 'productSet',
        element: React.createElement(ProductSetComponent, null),
    },
];
export var domainRouter = createBrowserRouter([
    {
        path: '/',
        element: React.createElement(LandingLayoutComponent, null),
        children: children,
    },
    { path: 'payment', element: React.createElement(PaymentComponentEcommerce, null) },
    { path: '*', element: React.createElement(NotFoundComponent, null) },
]);
var router = createBrowserRouter([
    // {
    //   path: ':companyName',
    //   element: <LandingLayoutComponent />,
    //   children,
    // },
    // { path: 'payment', element: <PaymentComponentEcommerce /> },
    // { path: '*', element: <NotFoundComponent /> },
    {
        path: 'lachime/es',
        element: React.createElement(HearderEsComponentChime, null),
        children: [
            { index: true, element: React.createElement(LaChimeEsTemplateComponent, null) },
            {
                path: 'menu',
                element: React.createElement(MenuEsComponentChime, null),
                children: [
                    { index: true, element: React.createElement(TopMenuEs, null) },
                    {
                        path: 'classification/:classificationId',
                        element: React.createElement(ClassificationItemProductListComponent, null),
                    },
                ],
            },
            {
                path: 'contact',
                element: React.createElement(ContactEsComponentChime, null),
            },
            {
                path: 'location',
                element: React.createElement(LocationEsComponent, null),
            },
            {
                path: 'franchise',
                element: React.createElement(FranchiseEsComponent, null),
            },
        ],
    },
    {
        path: 'lachime/en',
        element: React.createElement(HearderEnComponentChime, null),
        children: [
            { index: true, element: React.createElement(LaChimeEnTemplateComponent, null) },
            {
                path: 'menu',
                element: React.createElement(MenuEnComponentChime, null),
                children: [
                    { index: true, element: React.createElement(TopMenuEn, null) },
                    {
                        path: 'classification/:classificationId',
                        element: React.createElement(ClassificationItemProductListComponent, null),
                    },
                ],
            },
            {
                path: 'contact',
                element: React.createElement(ContactEnComponentChime, null),
            },
            {
                path: 'location',
                element: React.createElement(LocationEnComponent, null),
            },
            {
                path: 'becomeOurPartner',
                element: React.createElement(FranchiseEnComponent, null),
            },
        ],
    },
    {
        path: ':ecName',
        element: React.createElement(HeaderGenericLayoutComponent, null),
        children: children,
    },
    {
        path: 'capitalike',
        element: React.createElement(HeaderCapitalikeComponent, null),
        children: [
            { index: true, element: React.createElement(CapitalikeComponent, null) },
            { path: 'services', element: React.createElement(ServicesComponent, null) },
            { path: 'aboutUs', element: React.createElement(AboutUsComponent, null) },
            { path: 'contact', element: React.createElement(ContactCapitalikeComponent, null) },
        ],
    },
    // {
    //   path: 'capi/login',
    //   element: <LoginGenericComponent />,
    // },
    // { path: 'payment', element: <PaymentComponentEcommerce /> },
    // { path: '*', element: <NotFoundComponent /> },
]);
export default router;
