import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderProductListComponent from './Header';
var ProductListComponent = function () {
    return (React.createElement("div", { className: 'w-full flex flex-1 flex-col overflow-auto basis-0' },
        React.createElement("div", { className: 'mb-24' }),
        React.createElement("div", { className: 'fixed' },
            React.createElement(HeaderProductListComponent, null)),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden mt-28' },
            React.createElement(Outlet, null))));
};
export default ProductListComponent;
