import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
var LanguageButtonComponent = function () {
    var params = useParams();
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleClick = function (e) {
        setAnchorEl(e.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: handleClick, size: 'small', className: '-ml-2' },
            params.lang === 'es' && (React.createElement("img", { src: 'https://res.cloudinary.com/dqup64jlq/image/upload/v1732952120/LaChime/mx.webp', className: 'w-6', alt: 'mx-ico' })),
            params.lang === 'en' && (React.createElement("img", { src: 'https://res.cloudinary.com/dqup64jlq/image/upload/v1732951366/LaChime/usa.webp', className: 'w-6', alt: 'en-ico' }))),
        React.createElement(Menu, { anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, MenuListProps: {
                'aria-labelledby': 'languageMenu',
            } },
            React.createElement(MenuItem, { onClick: handleClose, className: 'items-center justify-between  ', component: RouterLink, to: '/lachime/es' },
                React.createElement("div", { className: 'font-bold text-sm' }, "ES - MEX"),
                React.createElement("img", { src: 'https://res.cloudinary.com/dqup64jlq/image/upload/v1732952120/LaChime/mx.webp', className: 'w-6 ml-2', alt: '' })),
            React.createElement(MenuItem, { onClick: handleClose, className: 'items-center justify-between', component: RouterLink, to: '/lachime/en' },
                React.createElement("div", { className: 'font-bold text-sm' }, "EN - EUA"),
                React.createElement("img", { src: 'https://res.cloudinary.com/dqup64jlq/image/upload/v1732951366/LaChime/usa.webp', className: 'w-6', alt: '' })))));
};
export default LanguageButtonComponent;
