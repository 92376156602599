import React from 'react';
import { Button } from '@mui/material';
// import CopyrightEsComponent from './Copyright';
var LaChimeEsTemplateComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col ' },
        React.createElement("div", { className: 'mb-24' }),
        React.createElement("div", { className: 'flex flex-col items-center' },
            React.createElement("div", { className: 'place-content-center' },
                React.createElement("img", { className: 'w-screen relative', src: 'https://storage.capitalike.com/files/29298', alt: 'IMAGEN' })),
            React.createElement("div", { className: 'absolute mt-3 md:mt-16' },
                React.createElement(Button, { className: 'font-bold md:text-xl  text-white bg-chime-pink w-20 text-xs md:w-48 rounded-full  border-solid border-2 md:border-8 border-white hover:bg-pink-600' }, "Ver m\u00E1s"))),
        React.createElement("div", { className: 'flex flex-col items-center mt-5' },
            React.createElement("h1", { className: 'text-center text-2xl text-bold' },
                React.createElement("span", { className: "text-chime-pink font-['GoBoldBold'] text-5xl" }, "LA CHIME"),
                " ",
                React.createElement("br", null),
                React.createElement("div", { className: "font-['GoBoldLight'] text-2xl" }, "\u00A1La mejor boba que puedas probar!"))),
        React.createElement("div", { className: 'flex flex-col bg-chime-brown md:flex-row' },
            React.createElement("div", { className: 'flex flex-1 flex-col text-white font-bold items-center text-lg md:text-xl w-full p-3' },
                React.createElement("p", { className: 'flex md:px-20 m-2 text-center md:justify-normal mt-8' }, "La Chime naci\u00F3 de una pasi\u00F3n compartida por la cultura de las bebidas con boba y el deseo de brindar experiencias \u00FAnicas a los amantes de las bebidas. Fundada en un peque\u00F1o local en el coraz\u00F3n de la ciudad."),
                React.createElement("p", { className: 'flex md:px-20 m-2 text-center md:justify-normal mt-8' }, "Con el tiempo, su reputaci\u00F3n creci\u00F3, atrayendo a una clientela fiel y diversa que buscaba no solo una deliciosa bebida, sino tambi\u00E9n un ambiente acogedor y vibrante. Inspirados por el \u00E9xito inicial, el equipo detr\u00E1s de La Chime se embarc\u00F3 en un viaje de expansi\u00F3n, abriendo varias ubicaciones en toda la ciudad y m\u00E1s all\u00E1.")),
            React.createElement("div", { className: 'flex md:place-content-end mr-5' },
                React.createElement("img", { className: 'w-full ml-2 md:w-100 md:h-80 md:m-2 mb-2 md:mb-0 p-3', 
                    // src='https://storage.capitalike.com/files/29310'
                    src: 'https://storage.capitalike.com/files/31061', alt: 'IMAGEN 2' }))),
        React.createElement("div", { className: 'flex place-content-center mt-5 md:p-0 md:mt-5' },
            React.createElement("h1", { className: 'text-lg md:text-2xl' },
                "\u00A1CREAMOS ",
                React.createElement("span", { className: 'text-chime-pink' }, "MOMENTOS UNICOS!"))),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:ml-2' }),
            React.createElement("h4", { className: 'flex md:whitespace-nowrap w-full text-center md:justify-center' }, "CREAMOS DELICIAS VISUALES Y EXQUISITOS SABORES DESDE EL 2020"),
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:mr-2' })),
        React.createElement("div", { className: 'flex flex-row bg-chime-pink p-2' },
            React.createElement("div", { className: 'flex flex-1 place-content-center' },
                React.createElement("img", { className: 'w-full md:w-72 md:h-72  md:p-2 p-4', 
                    // src='https://storage.capitalike.com/files/31814' borrosa
                    src: 'https://storage.capitalike.com/files/31905', 
                    // src='https://storage.capitalike.com/files/29311' original
                    alt: 'IMAGEN 2' }),
                React.createElement("img", { className: 'hidden md:flex md:w-72 md:h-72  md:p-2', 
                    // src='https://storage.capitalike.com/files/31817' borrosa
                    src: 'https://storage.capitalike.com/files/31901', alt: 'IMAGEN 2' }),
                React.createElement("img", { className: 'hidden md:flex md:w-72 md:h-72 md:object-fill md:p-2', 
                    // src='https://storage.capitalike.com/files/31813' borrosa
                    src: 'https://storage.capitalike.com/files/31897', alt: 'IMAGEN 2' }))),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:ml-2 ' }),
            React.createElement("h4", { className: 'flex md:whitespace-nowrap md:justify-center w-full text-center' },
                "VIVE LA DULCE EXPERIENCIAN CON NOSOTROS, TOMA LAS FOTOS ",
                React.createElement("br", null),
                "M\u00C1S CUTE Y DISFRUTA CON TUS AMIGOS"),
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:ml-2 ' })),
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("h1", { className: 'text-chime-pink' }, "NUESTRA HISTORIA")),
            React.createElement("div", { className: 'flex flex-row flex-1 justify-center' },
                React.createElement("div", { className: 'flex justify-center w-full p-4 md:w-1/3 md:p-0' },
                    React.createElement("p", { className: 'text-center' },
                        React.createElement("span", { className: 'text-chime-pink font-bold leading-none' }, "La Chime"),
                        " es una innovadora empresa fundada en 2020 en la vibrante ciudad de Culiac\u00E1n, Sinaloa, M\u00E9xico. Nuestra misi\u00F3n es ofrecer productos deliciosos y visualmente impactantes que encanten a nuestros clientes. Nos especializamos en tres productos principales: boba, pan y helado suave, cada uno elaborado con los m\u00E1s altos est\u00E1ndares de calidad."))),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("img", { className: 'w-56 mt-2', src: 'https://storage.capitalike.com/files/28831', alt: 'LOGO' })),
            React.createElement("div", { className: 'space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent mt-5 place-content-center' },
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal group is-active md:ml-[670px]' },
                    React.createElement("div", { className: 'flex flex-row md:flex-row-reverse' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2020"),
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-10' })),
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-pink p-4 mr-7' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            "La Chime abre sus puertas en Culiac\u00E1n, Sinaloa, ofreciendo una experiencia \u00FAnica al fusionar boba, pan y helado en un solo lugar, conquistando los paladares locales desde su inicio.",
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:ml-10 md:mt-2', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' })))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:flex-row flex-row-reverse group is-active md:mr-[670px]' },
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-brown p-4 md:ml-5 mr-7 md:mr-0' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:mr-10 md:mt-2', src: 'https://storage.capitalike.com/files/31084', alt: 'LOGO' }),
                            "La Chime expande su presencia en Culiac\u00E1n con la apertura de una segunda ubicaci\u00F3n, consolidando su reputaci\u00F3n como el destino preferido para los amantes de la boba, el pan y el helado en la regi\u00F3n.")),
                    React.createElement("div", { className: 'flex flex-row-reverse md:flex-row' },
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-10' }),
                        React.createElement("div", { className: 'flex items-center ml-5  md:ml-0 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-brown text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2021"))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal group is-active md:ml-[670px]' },
                    React.createElement("div", { className: 'flex flex-row md:flex-row-reverse' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2022"),
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-10' })),
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-pink p-4 mr-7' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            "La Chime ampl\u00EDa su alcance a trav\u00E9s de alianzas estrat\u00E9gicas con proveedores locales, asegurando ingredientes frescos y de alta calidad para sus productos exclusivos de boba, pan y helado.",
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:ml-10 md:mt-2', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' })))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:flex-row flex-row-reverse group is-active md:mr-[670px]' },
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-brown p-4 md:ml-5 mr-7 md:mr-0' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:mr-10 md:mt-2', src: 'https://storage.capitalike.com/files/31084', alt: 'LOGO' }),
                            "La popularidad de La Chime trasciende las fronteras de Culiac\u00E1n, atrayendo la atenci\u00F3n de clientes de ciudades vecinas y turistas que buscan deleitarse con su innovadora oferta gastron\u00F3mica.")),
                    React.createElement("div", { className: 'flex flex-row-reverse md:flex-row' },
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-10' }),
                        React.createElement("div", { className: 'flex items-center ml-5  md:ml-0 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-brown text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2023"))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal group is-active md:ml-[670px]' },
                    React.createElement("div", { className: 'flex flex-row md:flex-row-reverse' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2024"),
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-10' })),
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-pink p-4 mr-7' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            "La Chime abre sus puertas en Culiac\u00E1n, Sinaloa, ofreciendo una experiencia \u00FAnica al fusionar boba, pan y helado en un solo lugar, conquistando los paladares locales desde su inicio.",
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:ml-10 md:mt-2', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' }))))))));
};
export default LaChimeEsTemplateComponent;
