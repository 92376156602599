import { Button, Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
var ResumenGenericComponent = function () {
    var navigate = useNavigate(); // Hook para manejar la navegación
    var handleNavigate = function () {
        navigate('pay'); // Redirige a la ruta /play
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: 'bg-slate-100 text-2xl font-bold ml-6' }, "Resumen de compra"),
        React.createElement("div", { className: 'flex-1 bg-white rounded-xl shadow-md mx-5 flex flex-col h-56 w-96 mt-3' },
            React.createElement("div", { className: 'flex flex-row justify-between font-semibold' },
                React.createElement("div", { className: 'ml-3 mt-3' }, "Producto"),
                React.createElement("div", { className: 'mr-3 mt-3' }, "$999.00")),
            React.createElement(Divider, { className: 'w-100 mt-3' }),
            React.createElement("div", { className: 'flex flex-row justify-between font-semibold' },
                React.createElement("div", { className: 'ml-3 mt-3' }, "Envio"),
                React.createElement("div", { className: 'mr-3 mt-3' }, "$999.00")),
            React.createElement(Divider, { className: 'w-100 mt-3' }),
            React.createElement("div", { className: 'flex flex-row justify-between font-semibold' },
                React.createElement("div", { className: 'ml-3 mt-3' }, "Total"),
                React.createElement("div", { className: 'mr-3 mt-3' }, "$999.00")),
            React.createElement(Divider, { className: 'w-100 mt-3' }),
            React.createElement("div", { className: 'text-center mt-5' },
                React.createElement(Button, { onClick: handleNavigate, className: 'bg-blue-600 text-white font-bold hover:bg-blue-700 w-80' }, "Continuar compra")))));
};
export default ResumenGenericComponent;
