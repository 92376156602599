import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Divider } from '@mui/material';
// import FormLabel from '@mui/material/FormLabel';
var PaymentGenericComponent = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'bg-slate-100 text-2xl font-bold ml-6' }, "M\u00E9todo de pago"),
        React.createElement("div", { className: 'flex-1 bg-white rounded-xl shadow-md mx-5 flex flex-col w-96 mt-3' },
            React.createElement(FormControl, null,
                React.createElement(RadioGroup, { "aria-labelledby": 'demo-radio-buttons-group-label', defaultValue: 'female', name: 'radio-buttons-group', className: 'font-bold' },
                    React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                        React.createElement("div", null, "Efectivo"),
                        React.createElement(FormControlLabel, { value: 'cash', control: React.createElement(Radio, null), label: '' })),
                    React.createElement(Divider, { className: 'w-full' }),
                    React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                        React.createElement("div", null, "Tarjeta"),
                        React.createElement(FormControlLabel, { value: 'card', control: React.createElement(Radio, null), label: '' })),
                    React.createElement(Divider, { className: 'w-full' }),
                    React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                        React.createElement("div", null, "Transferencia"),
                        React.createElement(FormControlLabel, { value: 'transfer', control: React.createElement(Radio, null), label: '' })),
                    React.createElement(Divider, { className: 'w-full' }))))));
};
export default PaymentGenericComponent;
