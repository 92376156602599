import React from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useParams } from 'react-router-dom';
import getTranslation from '../../../../locale/lachime/translation';
var FranchiseEsComponent = function () {
    var lang = useParams().lang;
    var currentLang = lang === 'en' ? 'en' : 'es';
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'mt-' }),
        React.createElement("img", { className: 'w-screen h-60 md:h-screen', src: 'https://storage.capitalike.com/files/31812', alt: 'IMAGEN 2' }),
        React.createElement("div", { className: 'flex place-content-center mt-20' },
            React.createElement("h4", { className: "font-['GoBoldBold'] flex whitespace-nowrap text-5xl text-chime-pink text-center" },
                getTranslation(currentLang, 'franchise.title1part1'),
                React.createElement("br", { className: 'md:hidden' }),
                " ",
                getTranslation(currentLang, 'franchise.title1part2'))),
        React.createElement("div", { className: "flex flex-row bg-chime-pink text-white h-96 mx-5 rounded-xl mt-10 font-['GoBoldLight']" },
            React.createElement("div", { className: 'flex flex-1' },
                React.createElement("img", { src: 'https://storage.capitalike.com/files/28800', alt: '', className: 'rounded-full w-96 h-96 ml-5 mt-20 shadow-md' })),
            React.createElement("div", { className: 'flex flex-[2] flex-col text-center text-base mt-5 mx-3 items-center' },
                React.createElement("img", { src: 'https://storage.capitalike.com/files/41019', alt: 'LOGO LACHIME', className: 'w-32' }),
                React.createElement("div", { className: 'mt-10' }, getTranslation(currentLang, 'franchise.text1part1')),
                React.createElement("div", { className: 'mt-10' }, getTranslation(currentLang, 'franchise.text1part2')))),
        React.createElement("div", { className: 'flex place-content-center mt-20' },
            React.createElement("h4", { className: "font-['GoBoldBold'] flex whitespace-nowrap text-5xl text-chime-pink" }, getTranslation(currentLang, 'franchise.title2'))),
        React.createElement("div", { className: 'flex flex-1 flex-row text-black font-bold items-center bg-chime-brown mx-5 rounded-xl mt-10' },
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'hidden md:flex md:h-96', src: 'https://storage.capitalike.com/files/28541', alt: 'IMAGEN 2' })),
            React.createElement("p", { className: "font-['GoBoldBold'] flex md:px-20 justify-center text-2xl md:text-3xl text-white w-full text-center" }, getTranslation(currentLang, 'franchise.text2')),
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'hidden md:flex md:h-96', src: 'https://storage.capitalike.com/files/28541', alt: 'IMAGEN 2' }))),
        React.createElement("div", { className: 'flex place-content-center mt-20' },
            React.createElement("h4", { className: "font-['GoBoldBold'] flex whitespace-nowrap text-5xl text-chime-pink text-center" },
                getTranslation(currentLang, 'franchise.title3'),
                React.createElement("br", { className: 'md:hidden' }))),
        React.createElement("div", { className: 'mt-5 mx-5 rounded-xl' },
            React.createElement("img", { src: 'https://storage.capitalike.com/files/28581', alt: '', className: 'object-contain w-screen rounded-xl' })),
        React.createElement("div", { className: 'flex flex-1 flex-col mb-5 text-center m-4 p-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: "font-['GoBoldBold'] text-center text-2xl font-bold mt-10 mb-10" },
                    getTranslation(currentLang, 'franchise.title4part1'),
                    React.createElement("br", { className: 'md:hidden' }),
                    React.createElement("span", { className: 'text-chime-pink' }, getTranslation(currentLang, 'franchise.title4part2'))),
                React.createElement("div", { className: "font-['GoBoldLight'] mt-3 text-center mb-5" }, getTranslation(currentLang, 'franchise.text4'))),
            React.createElement("div", { className: "font-['GoBoldBold'] text-left text-xl font-bold text-chime-pink mt-16" },
                getTranslation(currentLang, 'franchise.title5part1'),
                React.createElement("br", null),
                React.createElement("span", { className: "font-['GoBoldLight'] text-black font-normal text-base" }, getTranslation(currentLang, 'franchise.title5part2')))),
        React.createElement("div", { className: "font-['GoBoldLight'] font-light flex flex-1 flex-col m-4 p-4" },
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' },
                    getTranslation(currentLang, 'franchise.name'),
                    getTranslation(currentLang, 'franchise.lastname')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' },
                    " ",
                    getTranslation(currentLang, 'franchise.birtdate')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' },
                    " ",
                    getTranslation(currentLang, 'franchise.address')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.city')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.state')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.email')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.mobilephone')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question1')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question2')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col mt-8' },
                React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question3')),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("div", { className: 'flex flex-col mt-8' },
                    React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question4')),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col mt-8' },
                    React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question5')),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col mt-8' },
                    React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question6')),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col mt-8' },
                    React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question7')),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col mt-8' },
                    React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question8')),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col mt-8' },
                    React.createElement("p", { className: 'font-bold' }, getTranslation(currentLang, 'franchise.question9')),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col md:flex-row items-center' },
                    React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { defaultChecked: true, className: 'text-chime-pink' }), label: getTranslation(currentLang, 'franchise.labelprivacy') }),
                    React.createElement("a", { href: 'https://franquiciasdeexito.com.mx/aviso-de-privacidad/', className: 'text-chime-brown', target: '_blank', rel: 'noreferrer' }, "https://franquiciasdeexito.com.mx/aviso-de-privacidad/"))),
            React.createElement("div", { className: 'text-center' },
                React.createElement(Button, { variant: 'contained', endIcon: React.createElement(SendIcon, null), className: 'bg-chime-pink mb-10 mt-4 w-32' }, getTranslation(currentLang, 'franchise.buttonsend'))))));
};
export default FranchiseEsComponent;
