import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var HowBuyStaticInformationComponent = function () {
    return (React.createElement("div", { className: 'mx-10 md:h-screen h-full mt-32 mb-5 md:0' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h6', className: 'text-black/60', gutterBottom: true }, "\u00BFC\u00F3mo comprar?"),
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "Comprar es muy f\u00E1cil y 100% seguro"))),
            React.createElement("div", { className: 'flex flex-row flex-1 mt-10' },
                React.createElement("div", { className: 'h-full w-3 bg-capitalike-gray-font rounded-full' }),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement("ol", { className: 'ol' },
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Elige los productos que deseas comprar y a\u00F1adelos al carrito de compras.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Una vez hayas elegido los productos que deseas comprar, abre tu carrito de compras.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Presiona el bot\u00F3n 'Ir a pagar'.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Llena el formulario con tu informaci\u00F3n de contacto.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Si deseas que tu pedido sea enviado por paqueter\u00EDa, ingresa la direcci\u00F3n de env\u00EDo. En caso de recoger en tienda, solo tienes que habilitar la opci\u00F3n seleccionando el bot\u00F3n.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Selecciona la paqueter\u00EDa deseada (El costo de env\u00EDo var\u00EDa seg\u00FAn la paqueter\u00EDa y el plazo). Este formulario estar\u00E1 disponible solo si resgistraste tu domicilio.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Proporciona la forma de pago.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Si requieres emitir una factura electr\u00F3nica, proporciona tus datos fiscales (El CFDi ser\u00E1 emitido autom\u00E1ticamente).")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Presiona el bot\u00F3n 'Finalizar compra'.")),
                        React.createElement("li", null,
                            React.createElement("div", { className: 'mb-5' }, "Si todo sali\u00F3 bien, se abrir\u00E1 una ventana con el comprobante de pago y las instrucciones para recibir tu pedido. Recomendamos que consultes tu correo electr\u00F3nico."))))))));
};
export default HowBuyStaticInformationComponent;
