import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../../../../apollo/gql/ecommerce';
import LoadingProgress from '../../../../../../presentationals/LoadingProgress';
import ClassificationGenericComponent from './Classification';
import ReviewsGenericComponent from '../../../../SliderPrincipal';
var PrincipalV2GenericComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_ECOMMERCE.GET_EC_CLASSIFICATIONS, {
        variables: {
            ecName: params.ecName,
            order: ['name', 'asc'],
            limit: null,
        },
    }), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, { darkTheme: true });
    if (!data)
        return React.createElement("div", null);
    console.log(data.count);
    return (React.createElement("div", { className: 'h-screen bg-slate-100 flex flex-1 flex-col w-screen mt-16 md:mt-24' },
        React.createElement("div", { className: 'w-full' },
            React.createElement(ReviewsGenericComponent, null)),
        React.createElement("div", { className: 'flex flex-1 flex-col md:flex-row' },
            React.createElement("div", null, data.count === 1 ? (React.createElement("div", { className: 'ml-8' })) : (React.createElement("div", { className: 'flex flex-col md:flex-row md:w-56 md:ml-10 md:mt-5' },
                React.createElement(ClassificationGenericComponent, null)))),
            React.createElement("div", { className: 'flex flex-1 flex-col h-full w-full bg-slate-100 md:mt-10 md:ml-16' },
                React.createElement(Outlet, null)))));
};
export default PrincipalV2GenericComponent;
