import React from 'react';
import { Button } from '@mui/material';
// import FooterComponentEN from './Footer';
// import CopyrightEsComponent from './Copyright';
var LaChimeEnTemplateComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col ' },
        React.createElement("div", { className: 'mb-24' }),
        React.createElement("div", { className: 'flex flex-col items-center' },
            React.createElement("div", { className: 'place-content-center' },
                React.createElement("img", { className: 'w-screen relative', src: 'https://storage.capitalike.com/files/29298', alt: 'IMAGEN' })),
            React.createElement("div", { className: 'absolute mt-3 md:mt-16' },
                React.createElement(Button, { className: 'font-bold md:text-xl  text-white bg-chime-pink w-24 text-xs md:w-48 rounded-full  border-solid border-2 md:border-8 border-white hover:bg-pink-600' }, "See more"))),
        React.createElement("div", { className: 'flex flex-col items-center mt-5' },
            React.createElement("h1", { className: 'text-center text-2xl text-bold' },
                React.createElement("span", { className: 'text-chime-pink' }, "LA CHIME"),
                " ",
                React.createElement("br", null),
                "THE BEST BOBA YOU CAN TRY!")),
        React.createElement("div", { className: 'flex flex-col bg-chime-brown md:flex-row' },
            React.createElement("div", { className: 'flex flex-1 flex-col text-white font-bold items-center text-lg md:text-xl w-full p-3' },
                React.createElement("p", { className: 'flex md:px-20 m-2 text-center md:justify-normal mt-8' }, "La Chime was born from a shared passion for boba drink culture and a desire to provide unique experiences to beverage lovers. It was founded in a small shop in the heart of the city."),
                React.createElement("p", { className: 'flex md:px-20 m-2 text-center md:justify-normal mt-8' }, "Over time, its reputation grew, attracting a loyal and diverse clientele that was looking not only for a delicious drink but also for a welcoming and vibrant atmosphere. Inspired by their initial success, the team behind La Chime embarked on a journey of expansion, opening several locations throughout the city and beyond.")),
            React.createElement("div", { className: 'flex md:place-content-end mr-5' },
                React.createElement("img", { className: 'w-full ml-2 md:w-100 md:h-80 md:m-2 mb-2 md:mb-0 p-3', 
                    // src='https://storage.capitalike.com/files/29310'
                    src: 'https://storage.capitalike.com/files/31061', alt: 'IMAGEN 2' }))),
        React.createElement("div", { className: 'flex place-content-center mt-5 md:p-0 md:mt-5' },
            React.createElement("h1", { className: 'text-lg md:text-2xl' },
                "WE CREATE ",
                React.createElement("span", { className: 'text-chime-pink' }, "UNIQUE MOMENTS!"))),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:ml-2' }),
            React.createElement("h4", { className: 'flex md:whitespace-nowrap w-full text-center md:justify-center' }, "WE CREATE VISUAL DELIGHTS AND EXQUISITE FLAVORS SINCE 2020"),
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:mr-2' })),
        React.createElement("div", { className: 'flex flex-row bg-chime-pink p-2' },
            React.createElement("div", { className: 'flex flex-1 place-content-center' },
                React.createElement("img", { className: 'w-full md:w-72 md:h-72 md:object-fill md:p-2 p-4', 
                    // src='https://storage.capitalike.com/files/29311' primera
                    src: 'https://storage.capitalike.com/files/31905', alt: 'IMAGEN 2' }),
                React.createElement("img", { className: 'hidden md:flex md:w-72 md:h-72 md:object-fill md:p-2', 
                    // src='https://storage.capitalike.com/files/29314' primera
                    src: 'https://storage.capitalike.com/files/31901', alt: 'IMAGEN 2' }),
                React.createElement("img", { className: 'hidden md:flex md:w-72 md:h-72 md:object-fill md:p-2', 
                    // src='https://storage.capitalike.com/files/29313' primera
                    src: 'https://storage.capitalike.com/files/31897', alt: 'IMAGEN 2' }))),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:ml-2 ' }),
            React.createElement("h4", { className: 'flex md:whitespace-nowrap md:justify-center w-full text-black text-center  ' },
                "LIVE THE SWEET EXPERIENCEWITH US, TAKE THE PHOTOS",
                React.createElement("br", null),
                "CUTTER AND ENJOY WITH YOUR FRIENDS"),
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-brown bg-chime-brown md:w-3/5 md:mr-2' })),
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("h1", { className: 'text-chime-pink' }, "OUR HISTORY")),
            React.createElement("div", { className: 'flex flex-row flex-1 justify-center' },
                React.createElement("div", { className: 'flex justify-center w-full p-4 md:w-1/3 md:p-0' },
                    React.createElement("p", { className: 'text-center' },
                        React.createElement("span", { className: 'text-chime-pink font-bold leading-none' }, "La Chime"),
                        " is an innovative company founded in 2020 in the vibrant city of Culiac\u00E1n, Sinaloa, Mexico. Our mission is to offer delicious, visually striking products that delight our clients. We specialize in three main products: boba, bread, and soft ice cream, each made to the highest quality standards."))),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("img", { className: 'w-56 mt-2', src: 'https://storage.capitalike.com/files/28831', alt: 'LOGO' })),
            React.createElement("div", { className: 'space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent mt-5 place-content-center' },
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal group is-active md:ml-[670px]' },
                    React.createElement("div", { className: 'flex flex-row md:flex-row-reverse' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2020"),
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-10' })),
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-pink p-4 mr-7' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            "La Chime opened its doors in Culiac\u00E1n, Sinaloa, offering a unique experience by merging boba, bread, and ice cream in one place, captivating local palates from the very beginning.",
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:ml-10 md:mt-2', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' })))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:flex-row flex-row-reverse group is-active md:mr-[670px]' },
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-brown p-4 md:ml-5 mr-7 md:mr-0' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:mr-10 md:mt-2', src: 'https://storage.capitalike.com/files/31084', alt: 'LOGO' }),
                            "La Chime expanded its presence in Culiac\u00E1n with the opening of a second location, cementing its reputation as the preferred destination for boba lovers, as well as for bread and ice cream in the region.")),
                    React.createElement("div", { className: 'flex flex-row-reverse md:flex-row' },
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-10' }),
                        React.createElement("div", { className: 'flex items-center ml-5  md:ml-0 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-brown text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2021"))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal group is-active md:ml-[670px]' },
                    React.createElement("div", { className: 'flex flex-row md:flex-row-reverse' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2022"),
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-10' })),
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-pink p-4 mr-7' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            "La Chime expands its reach through strategic alliances with local suppliers, ensuring fresh, high-quality ingredients for their exclusive boba, bread, and ice cream products.",
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:ml-10 md:mt-2', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' })))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:flex-row flex-row-reverse group is-active md:mr-[670px]' },
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-brown p-4 md:ml-5 mr-7 md:mr-0' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:mr-10 md:mt-2', src: 'https://storage.capitalike.com/files/31084', alt: 'LOGO' }),
                            "La Chime's popularity transcends the borders of Culiac\u00E1n, attracting clients from neighboring cities and tourists eager to experience its innovative gastronomic offerings.")),
                    React.createElement("div", { className: 'flex flex-row-reverse md:flex-row' },
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-10' }),
                        React.createElement("div", { className: 'flex items-center ml-5  md:ml-0 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-brown text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2023"))),
                React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal group is-active md:ml-[670px]' },
                    React.createElement("div", { className: 'flex flex-row md:flex-row-reverse' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2024"),
                        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-10' })),
                    React.createElement("div", { className: 'md:w-[768px] bg-white  rounded border border-slate-200 shadow-md shadow-chime-pink p-4 mr-7' },
                        React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-center' },
                            "La Chime opens its doors in Culiac\u00E1n, Sinaloa, offering a unique experience to the merging boba, bread and ice cream in one place, conquering local palates since the beginning.",
                            React.createElement("img", { className: 'hidden md:flex md:w-20 md:ml-10 md:mt-2', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' }))))))));
};
export default LaChimeEnTemplateComponent;
