var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import React, { useState } from 'react';
import { inputError, snackbar } from '../../../../apollo/cache';
import getInputErrorFormat from '../../../../utils/getInputErrorFormat';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import setInputErrorFormat from '../../../../utils/setInputErrorFormat';
var defaultValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
};
var ContactUsStaticInformationComponent = function () {
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () {
        try {
            inputError({});
            if (!values.name || values.name.trim() === '')
                throw new Error(setInputErrorFormat('name', 'Proporciona tu nombre'));
            if (!values.email || values.email.trim() === '')
                throw new Error(setInputErrorFormat('email', 'Proporciona tu email'));
            if (!values.message || values.message.trim() === '')
                throw new Error(setInputErrorFormat('message', 'Proporciona tu mensaje'));
            setValues(defaultValues);
            snackbar({
                isOpen: true,
                time: 3000,
                label: 'Se ha enviado tu mensaje con éxito. Responderemos a la brevedad posible.',
                severity: 'success',
            });
        }
        catch (e) {
            getInputErrorFormat(e);
        }
    };
    return (React.createElement("div", { className: 'text-center h-screen mt-32 overflow-x-hidden' },
        React.createElement("div", { className: 'text-3xl font-bold mb-5' }, "Cont\u00E1ctanos"),
        React.createElement(TextFieldComponent, { name: 'name', defaultLabel: 'Nombre', value: values.name, error: errors.name, onChange: handleChange, size: 'medium', className: 'w-3/4 md:w-1/3' }),
        React.createElement(TextFieldComponent, { name: 'email', defaultLabel: 'Correo Electr\u00F3nico', type: 'email', value: values.email, error: errors.email, onChange: handleChange, size: 'medium', className: 'w-3/4 md:w-1/3' }),
        React.createElement(TextFieldComponent, { name: 'phone', type: 'phone', defaultLabel: 'Tel\u00E9fono (Opcional)', value: values.phone, error: errors.phone, onChange: handleChange, size: 'medium', className: 'w-3/4 md:w-1/3' }),
        React.createElement(TextFieldComponent, { name: 'message', defaultLabel: 'Mensaje', value: values.message, error: errors.message, onChange: handleChange, size: 'medium', multiline: true, rows: 3, className: 'w-3/4 md:w-1/3 h-80' }),
        React.createElement(Button, { variant: 'contained', onClick: handleClick, className: ' py-3 px-8 bg-[#333] hover:bg-[#474747] text-base w-1/3' }, "Enviar")));
};
export default ContactUsStaticInformationComponent;
