var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Typography, TextField, Button as ButtonMui } from '@mui/material';
import ForgotPasswordComponent from './ForgotPasswordLink';
import GQL from './_gql';
import { inputError, isECSessionOpen } from '../../../../../../../../apollo/cache';
import setInputErrorFormat from '../../../../../../../../utils/setInputErrorFormat';
import setSession from '../../../../../../../../utils/setSession';
import getInputErrorFormat from '../../../../../../../../utils/getInputErrorFormat';
var Button = styled(ButtonMui)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(2, 8, 2, 8),
        backgroundColor: '#333',
        color: '#fff',
        fontSize: 16,
        '&:hover': {
            backgroundColor: '#474747',
        },
    });
});
var defaultValues = {
    username: '',
    password: '',
};
var LoginDialogSessionActionNavigationSecondaryContentComponent = function (props) {
    var navigate = useNavigate();
    var location = useLocation();
    var errors = useReactiveVar(inputError);
    var companyName = useParams().companyName;
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useMutation(GQL.ECLOGIN), ecLogin = _b[0], loading = _b[1].loading;
    var handleAction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, user, name_1, phone, email, refreshToken, accessToken, currentLocation_1, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (!values.username || values.username === '')
                        throw new Error(setInputErrorFormat('username', 'El usuario es obligatorio.'));
                    if (!values.password || values.password === '')
                        throw new Error(setInputErrorFormat('password', 'La contraseña es obligatoria.'));
                    return [4 /*yield*/, ecLogin({
                            variables: {
                                ecName: companyName || 'encantacln',
                                username: values.username,
                                password: values.password,
                            },
                        })];
                case 1:
                    _a = (_b.sent()).data.ecLogin, user = _a.user, name_1 = _a.name, phone = _a.phone, email = _a.email, refreshToken = _a.refreshToken, accessToken = _a.accessToken;
                    setSession({ user: user, name: name_1, phone: phone, email: email, refreshToken: refreshToken, accessToken: accessToken });
                    currentLocation_1 = location.pathname.split('/');
                    isECSessionOpen(false);
                    inputError({});
                    setValues(defaultValues);
                    setTimeout(function () {
                        if ([
                            currentLocation_1[currentLocation_1.length - 1],
                            currentLocation_1[currentLocation_1.length - 2],
                        ].includes('payment'))
                            window.location.reload();
                        else
                            navigate('panel');
                    }, 1500);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _b.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (e) {
        var name = e.target.name;
        var value = e.target.value;
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'p-4', style: {
            borderBottom: props.isDialog ? '1px solid #dadada' : 'none',
        } },
        !!props.isDialog && (React.createElement(Typography, { variant: 'button', gutterBottom: true }, "Ya tengo una cuenta")),
        React.createElement(TextField, { label: 'Correo electr\u00F3nico o tel\u00E9fono', name: 'username', autoFocus: true, fullWidth: true, margin: 'dense', onChange: handleChange, value: values.username, error: !!errors.username, helperText: errors.username && errors.username }),
        React.createElement(TextField, { label: 'Contrase\u00F1a', type: 'password', fullWidth: true, name: 'password', onChange: handleChange, value: values.password, error: !!errors.password, helperText: errors.password && errors.password }),
        typeof errors === 'string' && React.createElement("small", { className: 'text-red-500' }, errors),
        React.createElement(Button, { disabled: loading, onClick: handleAction }, "Acceder"),
        React.createElement(ForgotPasswordComponent, null)));
};
export default LoginDialogSessionActionNavigationSecondaryContentComponent;
