import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
var TermsStaticInformationComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_COMPANY, {
        variables: {
            ecName: params.ecName,
        },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'mx-10 h-full md:h-screen mt-28' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "T\u00E9rminos y Condiciones"))),
            React.createElement("div", { className: 'flex flex-row flex-1 mt-10' },
                React.createElement("div", { className: 'h-full w-3 bg-capitalike-gray-font rounded-full' }),
                React.createElement(Grid, { item: true, xs: 12, className: 'ml-3' },
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' },
                        "Los presentes T\u00E9rminos y Condiciones regulan la compra en l\u00EDnea de los productos que",
                        data.name,
                        " ofrece en el sitio web."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' },
                        "En caso de no aceptar en forma absoluta y completa los T\u00E9rminos y Condiciones de este convenio, el Usuario deber\u00E1 abstenerse de acceder, utilizar y observar el sitio de",
                        data.name,
                        "."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "A trav\u00E9s de su sitio web pone a la disposici\u00F3n de sus clientes y usuarios la venta en l\u00EDnea de sus productos, los cuales podr\u00E1n adquirir al registrar y tener activa una cuenta en la p\u00E1gina, lo cual se realiza a trav\u00E9s del llenado del formulario de registro y siguiendo los pasos que indique para dichos efectos, debiendo el usuario o suscriptor aceptar los T\u00E9rminos y Condiciones."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' },
                        data.name,
                        " se reserva el derecho de cambiar, modificar, a\u00F1adir o eliminar partes de estos T\u00E9rminos y Condiciones de uso en cualquier momento, los cambios ser\u00E1n efectivos cuando se publiquen en el sitio, por favor revisa peri\u00F3dicamente las actualizaciones de estos T\u00E9rminos y Condiciones, ya que tendr\u00E1s que aceptar los cambios que se realicen para poder continuar accediendo al sitio y seguir comprando nuestros productos."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' },
                        "La compra en l\u00EDnea de los productos de ",
                        data.name,
                        ", s\u00F3lo podr\u00E1 realizarla las personas que tengan capacidad legal para contratar. No podr\u00E1n adquirir nuestros productos mediante plataformas digitales las personas que no tengan esa capacidad, los menores de edad podr\u00E1n ser asistidos por un adulto para hacer sus compras."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Las compras se pueden realizar con cargo a tu tarjeta de cr\u00E9dito y d\u00E9bito Visa, MasterCard."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Tambi\u00E9n puedes pagar con OXXO PAY, en este caso se te enviar\u00E1 un correo con un n\u00FAmero de referencia y las indicaciones para llevar a cabo tu pago."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Una vez que haya finalizado el proceso de compra recibir\u00E1 por correo la confirmaci\u00F3n de esta. Adicional, recibir\u00E1 el ticket de compra en el correo electr\u00F3nico registrado, informando el n\u00FAmero de pedido y productos comprados."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Como cliente puedes consultar el estatus de tu pedido en la opci\u00F3n de mi cuenta/pedidos en la Tienda en l\u00EDnea."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Podr\u00E1s solicitar tu factura en tu compra a trav\u00E9s de nuestro sitio web."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Cualquier duda o comentario deber\u00E1 comunicarse al tel\u00E9fono 6671111111, o mediante un correo electr\u00F3nico a servicio@encantacln.com"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' },
                        React.createElement("strong", null, "\u00DAltima actualizaci\u00F3n 25 de julio de 2022")))))));
};
export default TermsStaticInformationComponent;
