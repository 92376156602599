import React from 'react';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightCapitalikeComponent from '../Copyright';
var ServicesComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col mt-28' },
        React.createElement("div", { className: 'flex flex-col h-screen bg-white' },
            React.createElement("div", { className: 'text-center font-bold text-3xl' }, "Planes y Servicios"),
            React.createElement("div", { className: 'mt-5 h-screen pb-32 px-60' },
                React.createElement("div", { className: 'w-full h-full flex flex-row' },
                    React.createElement("div", { className: 'flex flex-1 flex-col rounded-xl bg-capitalike-gray/10 m-4 font-bold' },
                        React.createElement("div", { className: 'text-center mt-10 text-xl' }, "B\u00E1sico"),
                        React.createElement("div", { className: 'text-center text-4xl text-capitalike-blue mt-5' }, "$1199.99/a\u00F1o"),
                        React.createElement(Divider, { className: 'mt-4 mx-3 bg-capitalike-gray h-0.5 rounded-full' }),
                        React.createElement("div", { className: 'h-full mx-3' },
                            React.createElement("div", { className: 'items-center flex flex-row text-center mt-10' },
                                React.createElement(CheckIcon, { className: 'text-capitalike-blue' }),
                                React.createElement("div", null, " Punto de Venta")),
                            React.createElement("div", { className: 'items-center flex flex-row text-center mt-10' },
                                React.createElement(CheckIcon, { className: 'text-capitalike-blue' }),
                                React.createElement("div", null, " Usuarios ilimitados")),
                            React.createElement("div", { className: 'items-center flex flex-row text-center mt-10' },
                                React.createElement(CheckIcon, { className: 'text-capitalike-blue' }),
                                React.createElement("div", null, " 1 Sucursal"))),
                        React.createElement(Divider, { className: 'mb-10 mx-3 bg-capitalike-gray h-0.5 rounded-full' }),
                        React.createElement("div", { className: 'h-20 text-center' },
                            React.createElement(Link, { to: 'https://wa.me/message/S56SWNLK624UB1', target: '_blank', className: 'bg-capitalike-blue hover:bg-blue-700  text-white font-light no-underline rounded-full px-16 text-center py-4 ' }, "CONTACTAR"))),
                    React.createElement("div", { className: 'flex flex-1 flex-col rounded-xl bg-capitalike-gray/10 m-4 font-bold' },
                        React.createElement("div", { className: 'h-40' },
                            React.createElement("div", { className: 'text-center mt-10 text-xl' }, "Profesional"),
                            React.createElement("div", { className: 'text-center text-4xl text-capitalike-blue mt-5' }, "$2399.99/a\u00F1o")),
                        React.createElement(Divider, { className: 'mt-4 mx-3 bg-capitalike-gray h-0.5 rounded-full' }),
                        React.createElement("div", { className: 'h-full mx-3' },
                            React.createElement("div", { className: 'items-center flex flex-row text-center mt-10' },
                                React.createElement(CheckIcon, { className: 'text-capitalike-blue' }),
                                React.createElement("div", null, " Punto de Venta")),
                            React.createElement("div", { className: 'items-center flex flex-row text-center mt-2' },
                                React.createElement(CheckIcon, { className: 'text-capitalike-blue' }),
                                React.createElement("div", null, " Punto de Venta")),
                            React.createElement("div", { className: 'items-center flex flex-row text-center mt-2' },
                                React.createElement(CheckIcon, { className: 'text-capitalike-blue' }),
                                React.createElement("div", null, " Punto de Venta"))),
                        React.createElement(Divider, { className: 'mb-10 mx-3 bg-capitalike-gray h-0.5 rounded-full' }),
                        React.createElement("div", { className: 'h-20 text-center' },
                            React.createElement(Link, { to: 'https://wa.me/message/S56SWNLK624UB1', target: '_blank', className: 'bg-capitalike-blue hover:bg-blue-700 text-white font-light no-underline rounded-full px-16 text-center py-4 ' }, "CONTACTAR"))),
                    React.createElement("div", { className: 'flex flex-1 flex-col rounded-xl bg-capitalike-gray/10 m-4 font-bold' },
                        React.createElement("div", { className: 'h-40' },
                            React.createElement("div", { className: 'text-center mt-10 text-xl' }, "Enterprise"),
                            React.createElement("div", { className: 'text-center text-4xl text-capitalike-blue mt-5' }, "$3999.99/a\u00F1o")),
                        React.createElement(Divider, { className: 'mt-4 mx-3 bg-capitalike-gray h-0.5 rounded-full' }),
                        React.createElement("div", { className: 'h-full mx-3' },
                            React.createElement("div", { className: 'mt-10' },
                                React.createElement("span", { className: 'text-capitalike-blue' }, "\u2022"),
                                "Punto de Venta")),
                        React.createElement(Divider, { className: 'mb-10 mx-3 bg-capitalike-gray h-0.5 rounded-full' }),
                        React.createElement("div", { className: 'h-20 text-center' },
                            React.createElement(Link, { to: 'https://wa.me/message/S56SWNLK624UB1', target: '_blank', className: 'bg-capitalike-blue hover:bg-blue-700 text-white font-light no-underline rounded-full px-16 text-center py-4 ' }, "CONTACTAR")))))),
        React.createElement(CopyrightCapitalikeComponent, null)));
};
export default ServicesComponent;
