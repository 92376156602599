import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import getTranslation from '../../../locale/lachime/translation';
var LaChimeEsTemplateComponent = function () {
    var lang = useParams().lang;
    var currentLang = lang === 'en' ? 'en' : 'es';
    return (React.createElement(React.Fragment, null,
        React.createElement("video", { width: '100%', autoPlay: true, muted: true, loop: true, playsInline: true, className: 'sticky top-0 z-0 w-full object-cover' },
            React.createElement("source", { src: 'https://res.cloudinary.com/dqup64jlq/video/upload/v1732946439/LaChime/main.webm', type: 'video/webm' }),
            "Your browser does not support the video tag."),
        React.createElement("section", { className: 'relative z-10 bg-white' },
            React.createElement("div", { className: 'sticky top-0 z-20 flex flex-col items-center mt-5 bg-white h-28' },
                React.createElement("h1", { className: 'text-center text-2xl text-bold mt-3' },
                    React.createElement("span", { className: "text-chime-pink font-['GoBoldBold'] text-5xl" }, getTranslation(currentLang, 'main.title1')),
                    React.createElement("br", null),
                    React.createElement("div", { className: "font-['GoBoldLight'] md:text-2xl" }, getTranslation(currentLang, 'main.subtitle1')))),
            React.createElement("div", { className: 'flex flex-col bg-chime-brown/90  md:flex-row h-96 mx-5 rounded-xl' },
                React.createElement("div", { className: 'flex md:place-content-end ml-5 overflow-hidden' },
                    React.createElement("img", { className: 'w-80 object-contain', src: 'https://storage.capitalike.com/files/10325', alt: 'La Chime, delicious meal' })),
                React.createElement("div", { className: "font-['GoBold'] flex flex-1 flex-col text-white items-center text-lg md:text-xl w-full p-3 " },
                    React.createElement("p", { className: "flex md:px-20 m-2 text-center md:justify-normal mt-8 font-['GoBoldLight']" }, getTranslation(currentLang, 'main.text1part1')),
                    React.createElement("p", { className: "flex md:px-20 m-2 text-center md:justify-normal mt-8 font-['GoBoldLight']" }, getTranslation(currentLang, 'main.text1part2'))),
                React.createElement("img", { className: 'w-full md:w-52 md:h-96 mr-5 mt-40', src: 'https://storage.capitalike.com/files/10276', alt: 'La Chime, delicious meal' })),
            React.createElement("div", { className: "font-['GoBoldBold'] text-chime-pink text-5xl text-center mt-44" }, getTranslation(currentLang, 'main.title4')),
            React.createElement("div", { className: "font-['GoBoldLight']  text-black text-lg text-center" }, getTranslation(currentLang, 'main.subtitle4')),
            React.createElement("div", { className: "mx-5 h-96 rounded-xl mt-10  text-black grid grid-cols-4 gap-10 font-['GoBoldBold']" },
                React.createElement("div", { className: 'overflow-hidden text-center bg-capitalike-gray-font/10 rounded-xl' },
                    React.createElement(ReceiptOutlinedIcon, { className: 'w-32 h-32 text-chime-pink mt-12' }),
                    React.createElement("div", { className: 'mt-10 text-2xl text-chime-pink' }, getTranslation(currentLang, 'main.title5')),
                    React.createElement("div", { className: "font-['GoBoldLight']" }, getTranslation(currentLang, 'main.subtitle5'))),
                React.createElement("div", { className: 'overflow-hidden text-center bg-capitalike-gray-font/10 rounded-xl' },
                    React.createElement(WorkspacePremiumOutlinedIcon, { className: 'w-32 h-32 text-chime-pink mt-12' }),
                    React.createElement("div", { className: 'mt-10 text-2xl text-chime-pink' }, getTranslation(currentLang, 'main.title6')),
                    React.createElement("div", { className: "font-['GoBoldLight']" }, getTranslation(currentLang, 'main.subtitle6'))),
                React.createElement("div", { className: 'overflow-hidden text-center bg-capitalike-gray-font/10 rounded-xl' },
                    React.createElement(LightbulbOutlinedIcon, { className: 'w-32 h-32 text-chime-pink mt-12' }),
                    React.createElement("div", { className: 'mt-10 text-2xl text-chime-pink' }, getTranslation(currentLang, 'main.title7')),
                    React.createElement("div", { className: "font-['GoBoldLight'] mx-1" }, getTranslation(currentLang, 'main.subtitle7'))),
                React.createElement("div", { className: 'overflow-hidden text-center bg-capitalike-gray-font/10 rounded-xl' },
                    React.createElement(PermMediaOutlinedIcon, { className: 'w-32 h-32 text-chime-pink mt-12' }),
                    React.createElement("div", { className: 'mt-10 text-2xl text-chime-pink' }, getTranslation(currentLang, 'main.title8')),
                    React.createElement("div", { className: "font-['GoBoldLight']" }, getTranslation(currentLang, 'main.subtitle8')))),
            React.createElement("div", { className: 'flex place-content-center mt-5 md:p-0 md:mt-44' },
                React.createElement("h1", { className: "text-5xl font-['GoBoldBold'] text-center justify-center" },
                    getTranslation(currentLang, 'main.title2part1'),
                    React.createElement("span", { className: 'text-chime-pink' },
                        React.createElement("br", { className: 'md:hidden ' }),
                        " ",
                        getTranslation(currentLang, 'main.title2part2')))),
            React.createElement("div", { className: "flex flex-row  bg-chime-pink h-96 mx-5 rounded-xl mt-10 pl-10 font-['GoBoldLight'] overflow-hidden" },
                React.createElement("div", { className: 'w-96 h-96 bg-chime-brown rounded-full relative -mr-80 mt-32' }),
                React.createElement("div", { className: 'w-96 h-96 bg-chime-brown rounded-full relative mt-32' }),
                React.createElement("img", { className: 'w-full md:w-96 md:h-96 rounded-full absolute mt-10 shadow-md ml-5', src: 'https://res.cloudinary.com/dqup64jlq/image/upload/v1732956847/LaChime/enjoy.webp', alt: 'Enjoy with friends at La Chime.' }),
                React.createElement("div", { className: 'flex flex-1 flex-col items-center ' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/41019', alt: 'LOGO LACHIME', className: 'w-32 mt-5' }),
                    React.createElement("h4", { className: 'text-white text-xl text-center w-[500px] mt-10' },
                        getTranslation(currentLang, 'main.subtitle1part1'),
                        " ",
                        React.createElement("br", null),
                        getTranslation(currentLang, 'main.subtitle1part11')),
                    React.createElement("h4", { className: 'text-white text-xl text-center w-[500px] mt-2' },
                        getTranslation(currentLang, 'main.subtitle1part2'),
                        " ",
                        React.createElement("br", null),
                        getTranslation(currentLang, 'main.subtitle1part21')))),
            React.createElement("div", { className: "items-center font-['GoBoldBold'] mt-20" },
                React.createElement("div", { className: 'text-center text-5xl text-chime-pink mb-5' }, getTranslation(currentLang, 'main.title9')),
                React.createElement("div", { className: "font-['GoBoldLight'] text-black text-lg text-center" }, getTranslation(currentLang, 'main.subtitle9'))),
            React.createElement("div", { className: 'h-96 mx-5 rounded-xl mt-10 grid grid-cols-4 gap-10' },
                React.createElement("div", { className: 'flex flex-col group items-center rounded-xl hover:shadow-md hover:bg-capitalike-gray-font/10 cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1  duration-300' },
                    React.createElement("div", { className: 'h-96 overflow-hidden ' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/10266', alt: '', className: 'object-contain w-36 mt-2' })),
                    React.createElement("div", { className: 'text-center font-bold' }, "BOBA KING NUTELLA"),
                    React.createElement("div", { className: 'flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-2' },
                        React.createElement(Link, { to: 'menu', className: 'text-center font-bold bg-chime-pink text-white no-underline text-sm rounded-full w-24 mt-2 mb-2 ' }, getTranslation(currentLang, 'main.buttonmenu')))),
                React.createElement("div", { className: 'flex flex-col group items-center rounded-xl hover:shadow-md hover:bg-capitalike-gray-font/10 cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1  duration-300' },
                    React.createElement("div", { className: 'h-96  rounded-md overflow-hidden ' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/10280', alt: '', className: 'object-contain w-36 mt-6' })),
                    React.createElement("div", { className: 'text-center font-bold' }, "BOBA TARO"),
                    React.createElement("div", { className: 'flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-2' },
                        React.createElement(Link, { to: 'menu', className: 'text-center font-bold bg-chime-pink text-white no-underline text-sm rounded-full w-24' }, getTranslation(currentLang, 'main.buttonmenu')))),
                React.createElement("div", { className: 'flex flex-col group items-center rounded-xl hover:shadow-md hover:bg-capitalike-gray-font/10 cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1  duration-300' },
                    React.createElement("div", { className: 'h-96  rounded-md overflow-hidden ' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/10272', alt: '', className: 'object-contain w-40 mt-4' })),
                    React.createElement("div", { className: 'text-center font-bold' }, "BOBA CARAMELO"),
                    React.createElement("div", { className: 'flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-2' },
                        React.createElement(Link, { to: 'menu', className: 'text-center font-bold bg-chime-pink text-white no-underline text-sm rounded-full w-24' }, getTranslation(currentLang, 'main.buttonmenu')))),
                React.createElement("div", { className: 'flex flex-col group items-center rounded-xl hover:shadow-md hover:bg-capitalike-gray-font/10 cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1  duration-300' },
                    React.createElement("div", { className: 'h-96  rounded-md overflow-hidden  ' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/10279', alt: '', className: 'object-contain w-full h-96 mt-6 ' })),
                    React.createElement("div", { className: 'text-center font-bold' }, "BOBA MOKA BLANCO"),
                    React.createElement("div", { className: 'flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-2' },
                        React.createElement(Link, { to: 'menu', className: 'text-center font-bold bg-chime-pink text-white no-underline text-sm rounded-full w-24' }, getTranslation(currentLang, 'main.buttonmenu'))))),
            React.createElement("div", { className: 'bg-white h-32' }))));
};
export default LaChimeEsTemplateComponent;
