import React from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, } from '@mui/material';
import { useParams } from 'react-router-dom';
import CommentsReviewsComponent from './Comments';
import useApolloQuery from '../../../../../../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../../../../../../apollo/gql/ecommerce';
import LoadingProgress from '../../../../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../../../../presentationals/ErrorData';
var ProductSetComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_ECOMMERCE.GET_EC_PRODUCTS, {
        variables: {
            ecName: params.ecName,
            classificationId: params.classificationId,
            order: ['name', 'asc'],
            limit: null,
        },
    }), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, { darkTheme: true });
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    console.log(params.classificationId);
    return (React.createElement("div", { className: 'h-full flex flex-col mt-20 md:mt-24 ' },
        React.createElement("div", { className: 'mx-5 mt-5 h-full flex flex-col md:flex-row' },
            React.createElement("div", { className: 'flex flex-[2] flex-col rounded-xl border-2 border-solid border-capitalike-gray-font/20 bg-white' },
                React.createElement("div", { className: 'flex flex-row' },
                    React.createElement("div", { className: 'flex flex-1 flex-col items-center md:mt-8' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/33641', alt: 'ProductId', className: 'object-contain w-48 h-96 ' })))),
            React.createElement("div", { className: 'w-2 h-full bg-slate-100' }),
            React.createElement("div", { className: 'mt-5 md:mt-0 flex flex-1 flex-col h-full bg-white rounded-xl border-2 border-solid border-capitalike-gray-font/20' },
                React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
                    React.createElement("div", { className: 'mt-5  text-2xl ml-5 flex flex-row justify-between items-center' },
                        React.createElement("div", { className: 'font-bold' }, "MANGO FIT"),
                        React.createElement("div", null,
                            React.createElement(IconButton, { className: 'mr-5' },
                                React.createElement(FavoriteBorderIcon, null)))),
                    React.createElement("div", { className: 'flex flex-row ml-5 text-yellow-400' },
                        React.createElement(StarIcon, { className: 'w-5' }),
                        React.createElement(StarIcon, { className: 'w-5' }),
                        React.createElement(StarIcon, { className: 'w-5' }),
                        React.createElement(StarIcon, { className: 'w-5' }),
                        React.createElement(StarBorderIcon, { className: 'w-5' })),
                    React.createElement("div", { className: 'text-capitalike-gray-font font-bold text-xl ml-5' }, "$449.00"),
                    React.createElement("div", { className: 'mx-5 font-bold text-xl mt-5' }, "Descripci\u00F3n"),
                    React.createElement("div", { className: 'mx-5 text-justify mt-2 text-capitalike-gray-font' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'ml-5 flex-col flex rounded-lg items-center mt-5' },
                        React.createElement(Button, { className: 'bg-capitalike-blue font-bold text-white mb-3 w-52 hover:bg-blue-600' }, "Comprar"),
                        React.createElement(Button, { className: 'bg-capitalike-gray-font/20 font-bold text-black w-52' }, "Agregar")),
                    React.createElement("div", { className: 'mb-10' })))),
        React.createElement("div", { className: 'flex flex-1 flex-row' },
            React.createElement("div", { className: 'bg-white m-5 rounded-xl border-2 border-solid border-capitalike-gray-font/20 flex-[2]' },
                React.createElement("div", { className: 'mx-5 font-bold text-xl ml-10 mt-5' }, "Configuraci\u00F3n de producto"),
                React.createElement("div", { className: 'bg-capitalike-gray-font/10 font-bold mx-10 mt-2' },
                    React.createElement("div", { className: 'ml-3' }, "Tama\u00F1o")),
                React.createElement(FormControl, { className: 'flex flex-1 mx-10' },
                    React.createElement(RadioGroup, { "aria-labelledby": 'demo-radio-buttons-group-label', defaultValue: 'female', name: 'radio-buttons-group', className: 'font-bold flex flex-1' },
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Efectivo"),
                            React.createElement(FormControlLabel, { label: '', value: 'cash', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Tarjeta"),
                            React.createElement(FormControlLabel, { label: '', value: 'card', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Transferencia"),
                            React.createElement(FormControlLabel, { label: '', value: 'transfer', control: React.createElement(Radio, null) })))),
                React.createElement("div", { className: 'bg-capitalike-gray-font/10 font-bold mx-10 mt-5' },
                    React.createElement("div", { className: 'ml-3' }, "Tama\u00F1o")),
                React.createElement(FormControl, { className: 'flex flex-1 mx-10' },
                    React.createElement(RadioGroup, { "aria-labelledby": 'demo-radio-buttons-group-label', defaultValue: 'female', name: 'radio-buttons-group', className: 'font-bold flex flex-1' },
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Efectivo"),
                            React.createElement(FormControlLabel, { label: '', value: 'cash', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Tarjeta"),
                            React.createElement(FormControlLabel, { label: '', value: 'card', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Transferencia"),
                            React.createElement(FormControlLabel, { label: '', value: 'transfer', control: React.createElement(Radio, null) })))),
                React.createElement("div", { className: 'bg-capitalike-gray-font/10 font-bold mx-10 mt-5' },
                    React.createElement("div", { className: 'ml-3' }, "Tama\u00F1o")),
                React.createElement(FormControl, { className: 'flex flex-1 mx-10' },
                    React.createElement(RadioGroup, { "aria-labelledby": 'demo-radio-buttons-group-label', defaultValue: 'female', name: 'radio-buttons-group', className: 'font-bold flex flex-1' },
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Efectivo"),
                            React.createElement(FormControlLabel, { label: '', value: 'cash', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Tarjeta"),
                            React.createElement(FormControlLabel, { label: '', value: 'card', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Transferencia"),
                            React.createElement(FormControlLabel, { label: '', value: 'transfer', control: React.createElement(Radio, null) })))),
                React.createElement("div", { className: 'bg-capitalike-gray-font/10 font-bold mx-10 mt-5' },
                    React.createElement("div", { className: 'ml-3' }, "Tama\u00F1o")),
                React.createElement(FormControl, { className: 'flex flex-1 mx-10' },
                    React.createElement(RadioGroup, { "aria-labelledby": 'demo-radio-buttons-group-label', defaultValue: 'female', name: 'radio-buttons-group', className: 'font-bold flex flex-1' },
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Efectivo"),
                            React.createElement(FormControlLabel, { label: '', value: 'cash', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Tarjeta"),
                            React.createElement(FormControlLabel, { label: '', value: 'card', control: React.createElement(Radio, null) })),
                        React.createElement("div", { className: 'flex flex-row justify-between items-center ml-3' },
                            React.createElement("div", null, "Transferencia"),
                            React.createElement(FormControlLabel, { label: '', value: 'transfer', control: React.createElement(Radio, null) }))))),
            React.createElement("div", { className: 'md:flex flex-1 mr-3.5 hidden' })),
        React.createElement("div", { className: 'flex flex-row flex-1 mx-5 h-full md:h-screen mb-10' },
            React.createElement(CommentsReviewsComponent, null))));
};
export default ProductSetComponent;
