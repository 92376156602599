import { Button } from '@mui/material';
import React, { useState } from 'react';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
var CounterGenericComponent = function () {
    var _a = useState(1), count = _a[0], setCount = _a[1];
    var increment = function () {
        setCount(count + 1);
    };
    var decrement = function () {
        if (count > 1)
            setCount(count - 1);
    };
    var count2 = count === 1;
    return (React.createElement("div", { className: 'flex flex-row items-center mr-5 bg-slate-100 rounded-md' },
        count2 ? (React.createElement(Button, { onClick: decrement, disabled: true },
            React.createElement(HorizontalRuleOutlinedIcon, { className: 'text-slate-300 hover:text-slate-700' }))) : (React.createElement(Button, { onClick: decrement },
            React.createElement(HorizontalRuleOutlinedIcon, { className: 'text-slate-600 hover:text-slate-700' }))),
        React.createElement("div", null, count),
        React.createElement(Button, { onClick: increment },
            React.createElement(AddOutlinedIcon, { className: 'text-slate-600 hover:text-slate-700' }))));
};
export default CounterGenericComponent;
