import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Radio, RadioGroup, Typography, } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import usePresentationStore from '../../../../../../../../../store/usePresentationStore';
import ProductVariationsComponent from '../ProoductIngredient';
var ProductPresentationsComponent = function (props) {
    var selectedPresentation = usePresentationStore(function (state) { return state.selectedPresentation; });
    var setSelectedPresentation = usePresentationStore(function (state) { return state.setSelectedPresentation; });
    var resetSelectedPresentation = usePresentationStore(function (state) { return state.resetSelectedPresentation; });
    var handleChange = function (event) {
        var selectedId = event.target.value;
        var selectedPresentation = props.presentation.find(function (p) { return p.id === selectedId; });
        if (selectedPresentation) {
            resetSelectedPresentation();
            setSelectedPresentation(selectedPresentation);
        }
    };
    useEffect(function () {
        return function () {
            resetSelectedPresentation();
        };
    }, [resetSelectedPresentation]);
    return (React.createElement("div", { className: 'flex flex-[2] flex-col rounded-xl border-2 border-solid border-capitalike-gray-font/20 bg-white mx-5' },
        React.createElement("div", { className: 'font-bold text-xl ml-5 mt-5 mb-3' }, "Configuraci\u00F3n de producto"),
        React.createElement("div", { className: 'mx-5' },
            React.createElement(Accordion, null,
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ArrowDropDownIcon, null), "aria-controls": 'panel2-content', id: 'panel2-header' },
                    React.createElement(Typography, { className: 'font-bold' }, "Tama\u00F1o")),
                React.createElement(AccordionDetails, null,
                    React.createElement(FormControl, { className: 'flex flex-1' },
                        React.createElement(RadioGroup, { "aria-labelledby": 'demo-radio-buttons-group-label', name: 'radio-buttons-group', value: (selectedPresentation === null || selectedPresentation === void 0 ? void 0 : selectedPresentation.id) || '', onChange: handleChange }, props.presentation
                            .slice()
                            .sort(function (a, b) { return a.name.localeCompare(b.name); })
                            .map(function (presentation) { return (React.createElement("div", { key: presentation.id, className: 'flex flex-row flex-1 items-center justify-between ml-2 font-semibold' },
                            React.createElement("div", null,
                                presentation.name.toLowerCase(),
                                " - $",
                                presentation.price),
                            React.createElement(FormControlLabel, { label: '', value: presentation.id, control: React.createElement(Radio, null) }))); }))))),
            React.createElement("div", { className: 'mb-3' }),
            React.createElement(ProductVariationsComponent, null))));
};
export default ProductPresentationsComponent;
