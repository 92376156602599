var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GridContainer from './style';
import SessionAction from './SessionAction';
import StorePickupSwitch from './StorePickupSwitch';
import { ECPaymentForm, inputError } from '../../../../../apollo/cache';
import getSession from '../../../../../utils/getSession';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
var defaultValues = {
    name: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    between: '',
    reference: '',
    zipCode: '',
    city: '',
    state: '',
    country: 'México',
    isPickupActive: false,
    deliveryDate: null,
    municipality: '',
};
var countryOptions = [{ value: 'México', label: 'México' }];
var Form1BodyPaymentComponent = function () {
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState(false), session = _b[0], setSession = _b[1];
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var session;
            return __generator(this, function (_a) {
                session = getSession();
                if (!session)
                    return [2 /*return*/];
                setSession(true);
                setValues(function (values) { return (__assign(__assign({}, values), { name: session.name || '', phone: session.phone || '', email: session.email || '' })); });
                return [2 /*return*/];
            });
        }); };
        ECPaymentForm(defaultValues);
        f();
    }, []);
    var handleChange = function (name, value) {
        var _a;
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
        ECPaymentForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleSwitch = function (isActive) {
        setValues(function (values) { return (__assign(__assign({}, values), { isPickupActive: isActive })); });
        ECPaymentForm(__assign(__assign({}, ECPaymentForm()), { isPickupActive: isActive }));
        if (!isActive)
            return false;
        var newValues = !isActive
            ? values
            : __assign(__assign({}, defaultValues), { name: values.name, email: values.email, phone: values.phone, deliveryDate: values.deliveryDate });
        ECPaymentForm(__assign(__assign({}, newValues), { isPickupActive: isActive }));
        setValues(__assign(__assign({}, newValues), { isPickupActive: isActive }));
        inputError({});
    };
    return (React.createElement(GridContainer, { container: true, className: 'root', spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12, className: 'spaceBetween' },
            React.createElement(Typography, { variant: 'h6' }, "Informaci\u00F3n del contacto"),
            React.createElement(SessionAction, { session: session })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(TextFieldComponent, { disabled: !!session, label: 'Nombre completo', name: 'name', onChange: handleChange, value: values.name, error: errors.name })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(TextFieldComponent, { disabled: !!session, label: 'Correo electr\u00F3nico', name: 'email', onChange: handleChange, value: values.email, error: errors.email, type: 'email' })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(TextFieldComponent, { disabled: !!session, label: 'Tel\u00E9fono', name: 'phone', onChange: handleChange, value: values.phone, error: errors.phone, type: 'phone' })),
        React.createElement(Grid, { item: true, xs: 12, className: 'spaceBetween' },
            React.createElement(Typography, { variant: 'h6' }, "Direcci\u00F3n para el env\u00EDo"),
            React.createElement(StorePickupSwitch, { handleSwitch: handleSwitch })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(TextFieldComponent, { disabled: values.isPickupActive, label: 'Calle y n\u00FAmero', name: 'addressLine1', onChange: handleChange, value: values.addressLine1, error: errors.addressLine1 })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(TextFieldComponent, { disabled: values.isPickupActive, label: 'Col\u00F3nia / Asentamiento', name: 'addressLine2', onChange: handleChange, value: values.addressLine2, error: errors.addressLine2 })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(TextFieldComponent, { disabled: values.isPickupActive, label: 'Entrecalles (Opcional)', name: 'between', onChange: handleChange, value: values.between })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(TextFieldComponent, { disabled: values.isPickupActive, label: 'C\u00F3digo postal', name: 'zipCode', onChange: handleChange, value: values.zipCode, error: errors.zipCode })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(TextFieldComponent, { label: 'Ciudad', name: 'city', onChange: handleChange, value: values.city, error: errors.city })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(TextFieldComponent, { label: 'Estado', name: 'state', onChange: handleChange, value: values.state, error: errors.state })),
        React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(SelectFieldComponent, { disabled: values.isPickupActive, label: 'Pais', name: 'country', onChange: handleChange, value: values.country, error: errors.country, options: countryOptions, size: 'small' })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(TextFieldComponent, { disabled: values.isPickupActive, label: 'Indicaciones adicionales para entregar tu compra', multiline: true, rows: 2, name: 'reference', onChange: handleChange, value: values.reference }))));
};
export default Form1BodyPaymentComponent;
