import React from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// import CopyrightEnComponent from '../Copyright';
var FranchiseEnComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col' },
        React.createElement("div", { className: 'mt-24' }),
        React.createElement("img", { className: 'w-screen h-60 md:h-screen', 
            // src='https://storage.capitalike.com/files/31812'
            src: 'https://storage.capitalike.com/files/28542', alt: 'IMAGEN 2' }),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-2xl md:text-3xl text-chime-pink' }, "UNIQUE VISUAL EXPERIENCE"),
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("div", { className: 'flex flex-col md:flex-row flex-1' },
            React.createElement("img", { className: 'p-2 w-full md:w-36 flex flex-1 h-80 object-cover object-bottom', src: 'https://storage.capitalike.com/files/28800', alt: 'IMAGEN 2' }),
            React.createElement("div", { className: 'flex flex-1 text-center p-4 text-xl font-bold' },
                "At La Chime, we believe that the visual experience is as important as the taste. Our drinks are exquisitely decorated with adorable characters, adding a unique and charming touch that enhances with every sip. ",
                React.createElement("br", null),
                React.createElement("br", null),
                "Our facilities reflect our brand: vibrant and full of color. Pink tones and neon details predominate, creating the perfect setting for your photos. Whether you visit us in Mexico or the United States, each La Chime store is designed to be a place where you can enjoy and capture unforgettable moments.")),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-2xl md:text-3xl text-chime-pink' }, "OUR MISSION"),
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
        React.createElement("div", { className: 'flex flex-1 flex-row text-black font-bold items-center bg-chime-brown' },
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'hidden md:flex md:w-100 md:h-80 md:p-8', src: 'https://storage.capitalike.com/files/28541', alt: 'IMAGEN 2' })),
            React.createElement("p", { className: 'flex md:px-20 justify-center text-2xl md:text-3xl text-white w-full text-center' }, "To offer our clients a service that meets the highest standards of quality, guaranteeing unmatched products and flavors."),
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'hidden md:flex md:w-100 md:h-80 md:p-8', src: 'https://storage.capitalike.com/files/28541', alt: 'IMAGEN 2' }))),
        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
        React.createElement("div", { className: 'mb-20' }),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-2xl md:text-3xl text-chime-pink' }, "START WITH US!"),
            React.createElement("span", { className: 'hidden md:flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("div", { className: 'mt-5' },
            React.createElement("img", { src: 'https://storage.capitalike.com/files/28581', alt: '', className: 'object-contain w-screen' })),
        React.createElement("div", { className: 'flex flex-1 flex-col mb-5 text-center m-4 p-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'text-center text-2xl font-bold mt-10 mb-10' },
                    "PRE-APPLICATION ",
                    React.createElement("span", { className: 'text-chime-pink' }, "LA CHIME\u00AE")),
                React.createElement("div", { className: 'mt-3 text-center mb-5' }, "All information presented within this pre-application is strictly confidential and will be used solely for evaluating the granting of a LA CHIME\u00AE franchise to the investor interested in joining our family of franchisees. Once the information has been evaluated, you may receive a call from us to request additional information regarding your proposal. For any additional questions, please contact us at hola@franquiciasdeexito.com.mx")),
            React.createElement("div", { className: 'text-left text-xl font-bold text-chime-pink mt-16' },
                "PERSONAL INFORMATION ",
                React.createElement("br", null),
                React.createElement("span", { className: 'text-black font-normal text-base' }, "Please provide the information according to your ID and/or Passport"))),
        React.createElement("div", { className: 'flex flex-1 flex-col m-4 p-4' },
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Name (s) and Last Name (s) *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Birthdate *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Address *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "City *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "State *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Email *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Mobile phone *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "How did you find out about or get involved in La Chime? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "What do you currently do? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "What is your currently available investment amount? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "Are you interested in this project individually or with other partners? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "Will you use any type of financing to carry out the project? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "Do you already have a location seen and/or available? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "How long have you known the place of interest? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "Do you have experience in managing any license or franchise? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "When would you like to start this project? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col md:flex-row items-center' },
                    React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { defaultChecked: true, className: 'text-chime-pink' }), label: 'Do you accept the Privacy Notice?' }),
                    React.createElement("a", { href: 'https://franquiciasdeexito.com.mx/aviso-de-privacidad/', className: 'text-chime-brown', target: '_blank', rel: 'noreferrer' }, "https://franquiciasdeexito.com.mx/aviso-de-privacidad/"))),
            React.createElement("div", { className: 'text-center' },
                React.createElement(Button, { variant: 'contained', endIcon: React.createElement(SendIcon, null), className: 'bg-chime-pink mb-10 mt-4 w-32' }, "Send")))));
};
export default FranchiseEnComponent;
