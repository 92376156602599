import React from 'react';
import CopyrightCapitalikeComponent from './Copyright';
var CapitalikeComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col ' },
        React.createElement("div", { className: 'h-screen flex-col flex' },
            React.createElement("div", { className: 'flex h-1/2 bg-capitalike-blue text-white' },
                React.createElement("div", { className: 'flex flex-row flex-1 h-full  bg-capitalike-blue' },
                    React.createElement("div", { className: 'flex flex-1' }),
                    React.createElement("div", { className: 'flex flex-[3] flex-col items-center' },
                        React.createElement("div", { className: 'mt-52' }),
                        React.createElement("div", null,
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/32620', alt: 'ISO CAPITALIKE', className: 'w-3/4' })),
                        React.createElement("div", { className: 'text-center' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/32621', alt: 'LOGO CAPITALIKE', className: 'w-1/2' })),
                        React.createElement("div", { className: 'text-white' }, "Transforma tu negocio con nuestro sistema de Puntos de Venta Integral")),
                    React.createElement("div", { className: 'flex flex-1' }))),
            React.createElement("div", { className: 'flex flex-row flex-1 h-1/2 bg-white' },
                React.createElement("div", { className: 'bg-capitalike-gray/10 ml-7 rounded-md flex flex-1 flex-col text-center my-30px' },
                    React.createElement("div", { className: 'h-1/5 mt-3 font-bold' },
                        React.createElement("div", null, "Punto de Venta Integral")),
                    React.createElement("div", { className: 'w-80 h-52 overflow-hidden flex  items-center' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/32622', alt: 'POS', className: 'object-scale-down w-80 h-52' })),
                    React.createElement("div", { className: 'h-3/5 text-justify p-5' }, "Sistema POS h\u00EDbrido que gestiona ventas e inventarios online y offline, sincronizando datos autom\u00E1ticamente.")),
                React.createElement("div", { className: 'bg-capitalike-gray/10 ml-7 rounded-md flex flex-1 flex-col text-center my-30px' },
                    React.createElement("div", { className: 'h-1/5 mt-3 font-bold' },
                        React.createElement("div", null, "Tienda Online")),
                    React.createElement("div", { className: 'w-80 h-52 overflow-hidden flex items-center' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/32625', alt: 'POS', className: 'object-scale-down w-80 h-52' })),
                    React.createElement("div", { className: 'h-3/5 text-justify p-5' }, "Crea y gestiona tu tienda online f\u00E1cilmente con una interfaz intuitiva y flexible.")),
                React.createElement("div", { className: 'bg-capitalike-gray/10 ml-7 rounded-md flex flex-1 flex-col text-center my-30px' },
                    React.createElement("div", { className: 'h-1/5 mt-3 font-bold' },
                        React.createElement("div", null, "Plataforma Web de Gesti\u00F3n")),
                    React.createElement("div", { className: 'w-80 h-52 overflow-hidden flex  items-center' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/32624', alt: 'PLATAFORMA WEB', className: 'object-scale-down w-80 h-52' })),
                    React.createElement("div", { className: 'h-3/5 text-justify p-5' }, "Plataforma web para gestionar inventarios, ventas y clientes con informes y actualizaciones en tiempo real.")),
                React.createElement("div", { className: 'bg-capitalike-gray/10 ml-7 mr-7 rounded-md flex flex-1 flex-col text-center my-30px' },
                    React.createElement("div", { className: 'h-1/5 mt-3 font-bold' },
                        React.createElement("div", null, "Consultor\u00EDa de Gesti\u00F3n")),
                    React.createElement("div", { className: 'w-80 h-52 overflow-hidden flex  items-center' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/32623', alt: 'CONSULTORIA GESTI\u00D3N', className: 'object-scale-down w-80 h-52' })),
                    React.createElement("div", { className: 'h-3/5 text-justify p-5' }, "Optimiza tu empresa con nuestra consultor\u00EDa: estrategias personalizadas y soluciones para impulsar crecimiento y eficiencia.")))),
        React.createElement("div", { className: 'flex flex-col h-screen bg-white' },
            React.createElement("div", { className: 'text-center text-3xl font-bold mt-4 mb-4 text-capitalike-blue' }, "\u00A1Capitalike, tu mejor opci\u00F3n!"),
            React.createElement("div", { className: 'h-1/2 rounded-t-md mx-4 overflow-hidden' },
                React.createElement("div", { className: 'flex flex-row h-full' },
                    React.createElement("div", { className: 'flex flex-1 flex-col' },
                        React.createElement("div", { className: 'm-4 h-full rounded-lg' },
                            React.createElement("div", { className: 'font-bold mt-20' }, "Funcionamiento Offline y Online"),
                            React.createElement("div", null, "Esto significa que puedes seguir operando incluso cuando no tienes conexi\u00F3n a Internet, y sincronizar todos tus datos cuando vuelves a estar en l\u00EDnea."),
                            React.createElement("div", { className: 'font-bold mt-10' }, "Aplicaciones para Todos tus Dispositivos"),
                            React.createElement("div", { className: '' }, "Disponemos de versiones optimizadas para PC y Android, lo que te permite gestionar tu negocio desde el dispositivo que prefieras, ya sea en la oficina o en movimiento."))),
                    React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden  m-4 rounded-lg' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/33322', alt: 'HOME', className: 'object-contain w-full overflow-hidden' })))),
            React.createElement("div", { className: 'h-1/2 rounded-b-md mx-4 overflow-hidden' },
                React.createElement("div", { className: 'flex flex-row h-full' },
                    React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden m-4 rounded-lg' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/33323', alt: 'HOME', className: 'object-contain w-full overflow-hidden' })),
                    React.createElement("div", { className: 'flex flex-1 ' },
                        React.createElement("div", { className: 'm-4 h-full' },
                            React.createElement("div", { className: 'font-bold mt-16' }, "Centraliza tus Operaciones"),
                            React.createElement("div", null, "Nuestra plataforma web se integra completamente con el punto de venta, ofreci\u00E9ndote una visi\u00F3n centralizada de todas tus operaciones. Puedes gestionar inventarios, registrar productos, controlar empleados, y asignar permisos con facilidad."),
                            React.createElement("div", { className: 'font-bold mt-10' }, "An\u00E1lisis Avanzado"),
                            React.createElement("div", { className: '' }, "Accede a tablas y gr\u00E1ficas detalladas sobre todos los movimientos de tu negocio. Obt\u00E9n informes claros y precisos para tomar decisiones informadas y estrat\u00E9gicas.")))))),
        React.createElement("div", { className: 'flex flex-col h-screen bg-white' },
            React.createElement("div", { className: 'h-1/2 rounded-t-md mx-4 overflow-hidden' },
                React.createElement("div", { className: 'flex flex-row h-full' },
                    React.createElement("div", { className: 'flex flex-1 flex-col' },
                        React.createElement("div", { className: 'm-4 h-full rounded-lg' },
                            React.createElement("div", { className: 'font-bold mt-20' }, "Funcionamiento Offline y Online"),
                            React.createElement("div", null, "Esto significa que puedes seguir operando incluso cuando no tienes conexi\u00F3n a Internet, y sincronizar todos tus datos cuando vuelves a estar en l\u00EDnea."),
                            React.createElement("div", { className: 'font-bold mt-10' }, "Aplicaciones para Todos tus Dispositivos"),
                            React.createElement("div", { className: '' }, "Disponemos de versiones optimizadas para PC y Android, lo que te permite gestionar tu negocio desde el dispositivo que prefieras, ya sea en la oficina o en movimiento."))),
                    React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden m-4 rounded-lg' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/33321', alt: 'HOME', className: 'object-contain w-full overflow-hidden' })))),
            React.createElement("div", { className: 'h-1/2 rounded-b-md mx-4 overflow-hidden' },
                React.createElement("div", { className: 'flex flex-row h-full' },
                    React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden m-4 rounded-lg' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/33320', alt: 'HOME', className: 'object-contain w-full overflow-hidden' })),
                    React.createElement("div", { className: 'flex flex-1 ' },
                        React.createElement("div", { className: 'm-4 h-full' },
                            React.createElement("div", { className: 'font-bold mt-16' }, "Centraliza tus Operaciones"),
                            React.createElement("div", null, "Nuestra plataforma web se integra completamente con el punto de venta, ofreci\u00E9ndote una visi\u00F3n centralizada de todas tus operaciones. Puedes gestionar inventarios, registrar productos, controlar empleados, y asignar permisos con facilidad."),
                            React.createElement("div", { className: 'font-bold mt-10' }, "An\u00E1lisis Avanzado"),
                            React.createElement("div", { className: '' }, "Accede a tablas y gr\u00E1ficas detalladas sobre todos los movimientos de tu negocio. Obt\u00E9n informes claros y precisos para tomar decisiones informadas y estrat\u00E9gicas.")))))),
        React.createElement("div", { className: 'h-screen w-screen' },
            React.createElement("div", { className: 'text-center text-5xl text-black/60 font-bold mt-5' }, "Experiencias"),
            React.createElement("div", { className: 'flex flex-col text-5xl font-medium text-white' },
                React.createElement("div", { className: 'whitespace-nowrap overflow-y-hidden flex flex-row md:mt-3 scrollbar-hide' },
                    React.createElement("div", { className: 'flex items-center rounded-full text-black h-14 no-underline text-base md:p-5 ml-6  border-2 border-solid border-capitalike-gray-font' },
                        React.createElement("div", { className: 'flex flex-col justify-center items-center text-black text-base' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/31084', className: 'h-10 mt-1 md:mt-0 md:mb-1', alt: '' }),
                            "NAme"))))),
        React.createElement(CopyrightCapitalikeComponent, null)));
};
export default CapitalikeComponent;
React.createElement("div", { className: 'whitespace-nowrap overflow-y-hidden flex flex-row scrollbar-hide mx-4 gap-4 h-1/3 bg-capitalike-gray-font/20' },
    React.createElement("div", { className: 'flex flex-col items-center h-52 w-80 border-2 border-solid border-capitalike-gray-font/20 rounded-xl mt-7' },
        React.createElement("div", { className: 'w-36 h-36 mt-2' },
            React.createElement("img", { 
                // src='https://storage.capitalike.com/files/34122'
                // src='https://storage.capitalike.com/files/10504'
                // src='https://storage.capitalike.com/files/9963'
                src: 'https://storage.capitalike.com/files/13357', 
                // src='https://storage.capitalike.com/files/8872'
                // src='https://storage.capitalike.com/files/33206'
                // src='https://storage.capitalike.com/files/17499'
                alt: '', className: 'w-full object-contain' })),
        React.createElement("div", { className: 'text-black/60 text-xl font-bold mt-3' }, "Burriolis")),
    React.createElement("div", { className: 'flex flex-col items-center h-52 w-80 border-2 border-solid border-capitalike-gray-font/20 rounded-xl mt-7' },
        React.createElement("div", { className: 'w-36 h-36 mt-2' },
            React.createElement("img", { 
                // src='https://storage.capitalike.com/files/34122'
                // src='https://storage.capitalike.com/files/10504'
                // src='https://storage.capitalike.com/files/9963'
                src: 'https://storage.capitalike.com/files/13357', 
                // src='https://storage.capitalike.com/files/8872'
                // src='https://storage.capitalike.com/files/33206'
                // src='https://storage.capitalike.com/files/17499'
                alt: '', className: 'w-full object-contain' })),
        React.createElement("div", { className: 'text-black/60 text-xl font-bold mt-3' }, "Burriolis")),
    React.createElement("div", { className: 'flex flex-col items-center h-52 w-80 border-2 border-solid border-capitalike-gray-font/20 rounded-xl mt-7' },
        React.createElement("div", { className: 'w-36 h-36 mt-2' },
            React.createElement("img", { 
                // src='https://storage.capitalike.com/files/34122'
                // src='https://storage.capitalike.com/files/10504'
                // src='https://storage.capitalike.com/files/9963'
                src: 'https://storage.capitalike.com/files/13357', 
                // src='https://storage.capitalike.com/files/8872'
                // src='https://storage.capitalike.com/files/33206'
                // src='https://storage.capitalike.com/files/17499'
                alt: '', className: 'w-full object-contain' })),
        React.createElement("div", { className: 'text-black/60 text-xl font-bold mt-3' }, "Burriolis")),
    React.createElement("div", { className: 'flex flex-col items-center h-52 w-80 border-2 border-solid border-capitalike-gray-font/20 rounded-xl mt-7' },
        React.createElement("div", { className: 'w-36 h-36 mt-2' },
            React.createElement("img", { 
                // src='https://storage.capitalike.com/files/34122'
                // src='https://storage.capitalike.com/files/10504'
                // src='https://storage.capitalike.com/files/9963'
                src: 'https://storage.capitalike.com/files/13357', 
                // src='https://storage.capitalike.com/files/8872'
                // src='https://storage.capitalike.com/files/33206'
                // src='https://storage.capitalike.com/files/17499'
                alt: '', className: 'w-full object-contain' })),
        React.createElement("div", { className: 'text-black/60 text-xl font-bold mt-3' }, "Burriolis")),
    React.createElement("div", { className: 'flex flex-col items-center h-52 w-80 border-2 border-solid border-capitalike-gray-font/20 rounded-xl mt-7' },
        React.createElement("div", { className: 'w-36 h-36 mt-2' },
            React.createElement("img", { 
                // src='https://storage.capitalike.com/files/34122'
                // src='https://storage.capitalike.com/files/10504'
                // src='https://storage.capitalike.com/files/9963'
                src: 'https://storage.capitalike.com/files/13357', 
                // src='https://storage.capitalike.com/files/8872'
                // src='https://storage.capitalike.com/files/33206'
                // src='https://storage.capitalike.com/files/17499'
                alt: '', className: 'w-full object-contain' })),
        React.createElement("div", { className: 'text-black/60 text-xl font-bold mt-3' }, "Burriolis")),
    ' ',
    React.createElement("div", { className: 'flex flex-col items-center h-52 w-80 border-2 border-solid border-capitalike-gray-font/20 rounded-xl mt-7' },
        React.createElement("div", { className: 'w-36 h-36 mt-2' },
            React.createElement("img", { 
                // src='https://storage.capitalike.com/files/34122'
                // src='https://storage.capitalike.com/files/10504'
                // src='https://storage.capitalike.com/files/9963'
                src: 'https://storage.capitalike.com/files/13357', 
                // src='https://storage.capitalike.com/files/8872'
                // src='https://storage.capitalike.com/files/33206'
                // src='https://storage.capitalike.com/files/17499'
                alt: '', className: 'w-full object-contain' })),
        React.createElement("div", { className: 'text-black/60 text-xl font-bold mt-3' }, "Burriolis")));
