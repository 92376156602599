import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GQL_ECOMMERCE from '../../../../../../../../apollo/gql/ecommerce';
import useApolloQuery from '../../../../../../../../hooks/useApolloQuery';
import CONFIG from '../../../../../../../../config/config.json';
import usePresentationStore from '../../../../../../../../store/usePresentationStore';
import ErrorDataComponent from '../../../../../../../../presentationals/ErrorData';
import CommentsReviewsComponent from './Comments';
import ProductPresentationsComponent from './ProoductPresentation';
import StarProductComponent from './StarProduct';
import FavoriteProductComponent from './FavoriteProduct';
import BuyProductComponent from './BuyProduct';
var ProductSetComponent = function () {
    var params = useParams();
    var selectedPresentation = usePresentationStore(function (state) { return state.selectedPresentation; });
    var resetSelectedPresentation = usePresentationStore(function (state) { return state.resetSelectedPresentation; });
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_PRODUCT, {
        variables: {
            ecName: params.ecName,
            id: params.id,
        },
    }).data;
    useEffect(function () {
        return function () {
            resetSelectedPresentation();
        };
    }, [resetSelectedPresentation]);
    console.log(data === null || data === void 0 ? void 0 : data.ProductPresentations);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: ' h-full flex flex-col' },
        React.createElement("div", { className: 'flex flex-1 md:flex-row flex-col' },
            React.createElement("div", { className: 'flex flex-[2] rounded-xl border-2 border-solid border-capitalike-gray-font/20 bg-white mx-5 mb-5 md:mb-0' },
                React.createElement("div", { className: 'flex flex-1 flex-col items-center md:mt-8 md:mb-5 my-5 md:my-0' },
                    React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat((data === null || data === void 0 ? void 0 : data.fileId) || '28311'), alt: 'ProductId', className: 'object-contain w-48 h-96 ' }))),
            React.createElement("div", { className: 'w-5 h-full' }),
            React.createElement("div", { className: 'flex flex-[1] flex-col rounded-xl border-2 border-solid border-capitalike-gray-font/20 bg-white md:mx-0 mx-5 md:mr-5' },
                React.createElement("div", { className: 'flex flex-row items-center justify-between ml-5 mt-3 ' },
                    React.createElement("div", { className: 'font-bold text-xl' }, data === null || data === void 0 ? void 0 : data.name),
                    React.createElement(FavoriteProductComponent, null)),
                React.createElement("div", { className: 'ml-5' },
                    React.createElement(StarProductComponent, null)),
                React.createElement("div", { className: 'text-capitalike-gray-font font-bold text-xl ml-5 mb-1' },
                    "$",
                    (selectedPresentation === null || selectedPresentation === void 0 ? void 0 : selectedPresentation.price) !== undefined ? selectedPresentation === null || selectedPresentation === void 0 ? void 0 : selectedPresentation.price : data === null || data === void 0 ? void 0 : data.price),
                data.description && (React.createElement("div", { className: 'mx-5 text-justify  text-capitalike-gray-font' },
                    React.createElement("div", { className: 'font-bold text-xl text-black' }, "Descripci\u00F3n"),
                    React.createElement("div", { className: 'h-24 overflow-auto ' }, data.description))),
                React.createElement(BuyProductComponent, null),
                React.createElement("div", { className: 'flex md:hidden mb-5' }))),
        React.createElement("div", { className: 'flex md:flex-row flex-col mt-5 mb-5' },
            React.createElement(ProductPresentationsComponent, { presentation: data.ProductPresentations || [] }),
            React.createElement("div", { className: 'w-5 h-full' }),
            React.createElement("div", { className: 'flex flex-[1] mr-5' })),
        React.createElement(CommentsReviewsComponent, null)));
};
export default ProductSetComponent;
