import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Button, IconButton } from '@mui/material';
import FormReviewsComponent from '../../../../../../../reviewsPro/Form';
var CommentsReviewsComponent = function () {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement("div", { className: 'bg-white h-screen md:h-full flex flex-col rounded-xl border-2 shadow-xl border-solid border-capitalike-gray-font/20 ' },
        React.createElement("div", { className: 'flex flex-row md:flex-1 mb-5 md:mb-0' },
            React.createElement("div", { className: 'flex flex-col ml-7 ' },
                React.createElement("div", { className: 'font-bold text-xl mt-3' }, "Calificaci\u00F3n y rese\u00F1as"),
                React.createElement("div", { className: 'flex flex-row' },
                    React.createElement("div", { className: 'flexfont-bold text-3xl' }, "4.5"),
                    React.createElement("div", { className: 'flex flex-row ml-3 text-yellow-400' },
                        React.createElement(StarIcon, { className: 'text-3xl' }),
                        React.createElement(StarIcon, { className: 'text-3xl' }),
                        React.createElement(StarIcon, { className: 'text-3xl' }),
                        React.createElement(StarIcon, { className: 'text-3xl' }),
                        React.createElement(StarBorderIcon, { className: 'text-3xl' })))),
            React.createElement("div", { className: 'text-xl mt-1.5 ml-1' },
                React.createElement(IconButton, { onClick: handleOpen },
                    React.createElement(AddIcon, { className: 'text-green-500' })),
                React.createElement(FormReviewsComponent, { open: open, onClose: handleClose }))),
        React.createElement("div", { className: 'md:h-96 md:mt-5 mx-5 mb-5 grid-cols-1 grid md:grid-cols-3 gap-5' },
            React.createElement("div", { className: 'border border-solid border-capitalike-gray-font/50 rounded-xl overflow-hidden flex flex-col h-40' },
                React.createElement("div", { className: 'flex flex-row justify-between mx-3 text-sm mt-3' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, "Usuario"),
                        React.createElement("div", { className: 'flex flex-row ml-3 text-yellow-400' },
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarBorderIcon, { className: 'text-xl' }))),
                    React.createElement("div", { className: 'text-capitalike-gray-font' }, "20/10/2024")),
                React.createElement("div", { className: 'text-sm h-36 overflow-auto mx-3 text-justify mt-2' }, "Desde que empec\u00E9 a usar este suplemento, he visto mejoras notables en mi bienestar. Mi calidad de sue\u00F1o ha mejorado, durmiendo m\u00E1s profundamente y despertando m\u00E1s descansado. Me siento m\u00E1s relajado y menos ansioso, lo que ha impactado positivamente mi estado emocional. La digesti\u00F3n tambi\u00E9n ha mejorado; el estre\u00F1imiento se ha aliviado y mi cuerpo parece absorber mejor los nutrientes. La sensaci\u00F3n de saciedad es m\u00E1s fuerte, lo que ha facilitado mi esfuerzo por perder peso. Adem\u00E1s, mis niveles de az\u00FAcar en la sangre est\u00E1n m\u00E1s estables. En general, este suplemento ha sido una excelente adici\u00F3n a mi rutina diaria y lo recomiendo para quienes buscan un bienestar integral.")),
            React.createElement("div", { className: 'border border-solid border-capitalike-gray-font/50 rounded-xl overflow-hidden flex flex-col h-40' },
                React.createElement("div", { className: 'flex flex-row justify-between mx-3 text-sm mt-3' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, "Usuario"),
                        React.createElement("div", { className: 'flex flex-row ml-3 text-yellow-400' },
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarBorderIcon, { className: 'text-xl' }))),
                    React.createElement("div", { className: 'text-capitalike-gray-font' }, "20/10/2024")),
                React.createElement("div", { className: 'text-sm h-36 overflow-auto mx-3 text-justify mt-2' }, "Desde que empec\u00E9 a usar este suplemento, he visto mejoras notables en mi bienestar. Mi calidad de sue\u00F1o ha mejorado, durmiendo m\u00E1s profundamente y despertando m\u00E1s descansado. Me siento m\u00E1s relajado y menos ansioso, lo que ha impactado positivamente mi estado emocional. La digesti\u00F3n tambi\u00E9n ha mejorado; el estre\u00F1imiento se ha aliviado y mi cuerpo parece absorber mejor los nutrientes. La sensaci\u00F3n de saciedad es m\u00E1s fuerte, lo que ha facilitado mi esfuerzo por perder peso. Adem\u00E1s, mis niveles de az\u00FAcar en la sangre est\u00E1n m\u00E1s estables. En general, este suplemento ha sido una excelente adici\u00F3n a mi rutina diaria y lo recomiendo para quienes buscan un bienestar integral.")),
            React.createElement("div", { className: 'border border-solid border-capitalike-gray-font/50 rounded-xl overflow-hidden md:flex flex-col h-40 hidden' },
                React.createElement("div", { className: 'flex flex-row justify-between mx-3 text-sm mt-3' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, "Usuario"),
                        React.createElement("div", { className: 'flex flex-row ml-3 text-yellow-400' },
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarBorderIcon, { className: 'text-xl' }))),
                    React.createElement("div", { className: 'text-capitalike-gray-font' }, "20/10/2024")),
                React.createElement("div", { className: 'text-sm h-36 overflow-auto mx-3 text-justify mt-2' }, "Desde que empec\u00E9 a usar este suplemento, he visto mejoras notables en mi bienestar. Mi calidad de sue\u00F1o ha mejorado, durmiendo m\u00E1s profundamente y despertando m\u00E1s descansado. Me siento m\u00E1s relajado y menos ansioso, lo que ha impactado positivamente mi estado emocional. La digesti\u00F3n tambi\u00E9n ha mejorado; el estre\u00F1imiento se ha aliviado y mi cuerpo parece absorber mejor los nutrientes. La sensaci\u00F3n de saciedad es m\u00E1s fuerte, lo que ha facilitado mi esfuerzo por perder peso. Adem\u00E1s, mis niveles de az\u00FAcar en la sangre est\u00E1n m\u00E1s estables. En general, este suplemento ha sido una excelente adici\u00F3n a mi rutina diaria y lo recomiendo para quienes buscan un bienestar integral.")),
            React.createElement("div", { className: 'border border-solid border-capitalike-gray-font/50 rounded-xl overflow-hidden md:flex flex-col h-40 hidden' },
                React.createElement("div", { className: 'flex flex-row justify-between mx-3 text-sm mt-3' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, "Usuario"),
                        React.createElement("div", { className: 'flex flex-row ml-3 text-yellow-400' },
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarBorderIcon, { className: 'text-xl' }))),
                    React.createElement("div", { className: 'text-capitalike-gray-font' }, "20/10/2024")),
                React.createElement("div", { className: 'text-sm h-36 overflow-auto mx-3 text-justify mt-2' }, "Desde que empec\u00E9 a usar este suplemento, he visto mejoras notables en mi bienestar. Mi calidad de sue\u00F1o ha mejorado, durmiendo m\u00E1s profundamente y despertando m\u00E1s descansado. Me siento m\u00E1s relajado y menos ansioso, lo que ha impactado positivamente mi estado emocional. La digesti\u00F3n tambi\u00E9n ha mejorado; el estre\u00F1imiento se ha aliviado y mi cuerpo parece absorber mejor los nutrientes. La sensaci\u00F3n de saciedad es m\u00E1s fuerte, lo que ha facilitado mi esfuerzo por perder peso. Adem\u00E1s, mis niveles de az\u00FAcar en la sangre est\u00E1n m\u00E1s estables. En general, este suplemento ha sido una excelente adici\u00F3n a mi rutina diaria y lo recomiendo para quienes buscan un bienestar integral.")),
            React.createElement("div", { className: 'border border-solid border-capitalike-gray-font/50 rounded-xl overflow-hidden md:flex flex-col h-40 hidden' },
                React.createElement("div", { className: 'flex flex-row justify-between mx-3 text-sm mt-3' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, "Usuario"),
                        React.createElement("div", { className: 'flex flex-row ml-3 text-yellow-400' },
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarBorderIcon, { className: 'text-xl' }))),
                    React.createElement("div", { className: 'text-capitalike-gray-font' }, "20/10/2024")),
                React.createElement("div", { className: 'text-sm h-36 overflow-auto mx-3 text-justify mt-2' }, "Desde que empec\u00E9 a usar este suplemento, he visto mejoras notables en mi bienestar. Mi calidad de sue\u00F1o ha mejorado, durmiendo m\u00E1s profundamente y despertando m\u00E1s descansado. Me siento m\u00E1s relajado y menos ansioso, lo que ha impactado positivamente mi estado emocional. La digesti\u00F3n tambi\u00E9n ha mejorado; el estre\u00F1imiento se ha aliviado y mi cuerpo parece absorber mejor los nutrientes. La sensaci\u00F3n de saciedad es m\u00E1s fuerte, lo que ha facilitado mi esfuerzo por perder peso. Adem\u00E1s, mis niveles de az\u00FAcar en la sangre est\u00E1n m\u00E1s estables. En general, este suplemento ha sido una excelente adici\u00F3n a mi rutina diaria y lo recomiendo para quienes buscan un bienestar integral.")),
            React.createElement("div", { className: 'border border-solid border-capitalike-gray-font/50 rounded-xl overflow-hidden md:flex flex-col h-40 mhidden' },
                React.createElement("div", { className: 'flex flex-row justify-between mx-3 text-sm mt-3' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, "Usuario"),
                        React.createElement("div", { className: 'flex flex-row ml-3 text-yellow-400' },
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarIcon, { className: 'text-xl' }),
                            React.createElement(StarBorderIcon, { className: 'text-xl' }))),
                    React.createElement("div", { className: 'text-capitalike-gray-font' }, "20/10/2024")),
                React.createElement("div", { className: 'text-sm h-36 overflow-auto mx-3 text-justify mt-2' }, "Desde que empec\u00E9 a usar este suplemento, he visto mejoras notables en mi bienestar. Mi calidad de sue\u00F1o ha mejorado, durmiendo m\u00E1s profundamente y despertando m\u00E1s descansado. Me siento m\u00E1s relajado y menos ansioso, lo que ha impactado positivamente mi estado emocional. La digesti\u00F3n tambi\u00E9n ha mejorado; el estre\u00F1imiento se ha aliviado y mi cuerpo parece absorber mejor los nutrientes. La sensaci\u00F3n de saciedad es m\u00E1s fuerte, lo que ha facilitado mi esfuerzo por perder peso. Adem\u00E1s, mis niveles de az\u00FAcar en la sangre est\u00E1n m\u00E1s estables. En general, este suplemento ha sido una excelente adici\u00F3n a mi rutina diaria y lo recomiendo para quienes buscan un bienestar integral."))),
        React.createElement("div", { className: 'text-center mb-5 ' },
            React.createElement(Button, { className: 'border border-solid border-black rounded-full text-black text-xs' }, "Ver m\u00E1s"))));
};
export default CommentsReviewsComponent;
