var getProductMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'lachime':
            return 0;
        case 'menu':
            return 1;
        case 'location':
            return 2;
        case 'becomeOurPartner':
            return 3;
        case 'contact':
            return 4;
        default:
            return 0;
    }
};
export default getProductMenuValueFromPath;
