import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link, useParams } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import ChatButtonComponent from '../Footer/ChatButton';
import FooterLinkComponent from '../Footer/Link';
import MediaButtonComponent from '../Footer/MediaButton';
import getTranslation from '../../../../locale/lachime/translation';
var CopyrightEsComponent = function () {
    var lang = useParams().lang;
    var currentLang = lang === 'en' ? 'en' : 'es';
    return (React.createElement("div", { className: 'relative z-20 flex flex-1 flex-col bg-chime-pink text-white' },
        React.createElement("div", { className: 'm-4 sm:m-8 flex flex-1 flex-col sm:flex-row' },
            React.createElement("div", { className: 'flex flex-1 flex-row' },
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'text-lg mb-1' },
                        " ",
                        getTranslation(currentLang, 'copyright.help')),
                    React.createElement("div", null,
                        React.createElement(ChatButtonComponent, null)),
                    React.createElement("div", { className: 'text-sm leading-none mt-2' }, getTranslation(currentLang, 'copyright.hour'))),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'text-lg' }, getTranslation(currentLang, 'copyright.about')),
                    React.createElement(FooterLinkComponent, { to: 'aboutUs' }, getTranslation(currentLang, 'copyright.aboutUs')),
                    React.createElement(FooterLinkComponent, { to: 'ourShops' }, getTranslation(currentLang, 'copyright.ourShops')),
                    React.createElement(FooterLinkComponent, { to: 'contactUs' }, getTranslation(currentLang, 'copyright.contactUs'))),
                React.createElement("div", { className: 'flex sm:flex-1' }),
                React.createElement("div", { className: 'flex sm:flex-1' })),
            React.createElement("div", { className: 'flex flex-col mt-3 sm:mt-0 sm:items-end' },
                React.createElement("div", null,
                    React.createElement(MediaButtonComponent, { href: 'https://www.facebook.com/lachimemx' },
                        React.createElement(FacebookIcon, null)),
                    React.createElement(MediaButtonComponent, { href: 'https://www.instagram.com/lachimemx/' },
                        React.createElement(InstagramIcon, null))),
                React.createElement("div", { className: 'mt-1 text-sm' }, getTranslation(currentLang, 'copyright.social')))),
        React.createElement("div", { className: 'flex px-4 sm:px-10 py-4 bg-chime-pink flex-col sm:flex-row border-b-0 border-l-0 border-r-0 border-4 border-solid border-t-chime-pink' },
            React.createElement("div", { className: 'flex flex-1' }, getTranslation(currentLang, 'copyright.reserved')),
            React.createElement("div", { className: 'flex pt-2 sm:pt-0' },
                React.createElement(Link, { to: 'terms', className: 'text-white font-normal no-underline hover:underline mr-4' }, getTranslation(currentLang, 'copyright.terms')),
                React.createElement(Link, { to: 'privacy', className: 'text-white font-normal no-underline hover:underline' }, getTranslation(currentLang, 'copyright.privacity'))))));
};
export default CopyrightEsComponent;
