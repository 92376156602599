import { Menu, MenuItem, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import getProductMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromProductMenuValue from './getRouteFromMenuValue';
var HeaderEnChimeLayout = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getProductMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleChange = function (e, value) {
        console.log(1);
        var route = getRouteFromProductMenuValue(value);
        console.log(route);
        if (route)
            navigate(route);
    };
    return (React.createElement("div", { className: 'md:ml-8' },
        React.createElement("div", { className: 'mr-4 md:hidden ' },
            React.createElement(IconButton, { onClick: handleClick },
                React.createElement(MenuIcon, { className: 'text-chime-pink hover:text-chime-brown' })),
            React.createElement(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    'aria-labelledby': 'basic-button',
                } },
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: '/lachime/es' },
                    React.createElement("div", { className: 'font-bold text-sm' }, "Home")),
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: 'menu' },
                    React.createElement("div", { className: 'font-bold text-sm' }, "Menu")),
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: 'location' },
                    React.createElement("div", { className: 'font-bold text-sm' }, "Location")),
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: 'franchise' },
                    React.createElement("div", { className: 'font-bold text-sm' }, "Become our partner")),
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: 'contact' },
                    React.createElement("div", { className: 'font-bold text-sm' }, "Contact Us")))),
        React.createElement("div", { className: 'hidden md:flex' },
            React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": 'basic tabs example', textColor: 'secondary', indicatorColor: 'secondary' },
                React.createElement(Tab, { label: 'Home' }),
                React.createElement(Tab, { label: 'Menu' }),
                React.createElement(Tab, { label: 'Location' }),
                React.createElement(Tab, { label: 'Become our partner' }),
                React.createElement(Tab, { label: 'Contact' })))));
};
export default HeaderEnChimeLayout;
