var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useParams } from 'react-router-dom';
import useApolloQuery from '../../../../../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../../../../../apollo/gql/ecommerce';
import ErrorDataComponent from '../../../../../../../presentationals/ErrorData';
import ProductItemProductListComponent from './ProductItem';
var ClassificationGenericItemProductListComponent = function () {
    // const params = useParams<'classificationId'>();
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_PRODUCTS, {
        variables: {
            ecName: params.ecName,
            classificationId: params.classificationId,
            order: ['name', 'asc'],
            limit: null,
        },
    }).data;
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'overflow-auto' },
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-4 overflow-auto ml-7 md:ml-0 md:mx-0' }, data && data.map(function (el) { return React.createElement(ProductItemProductListComponent, __assign({ key: el.id }, el)); })),
        React.createElement("div", { className: 'mb-40' })));
};
export default ClassificationGenericItemProductListComponent;
// order: [params.classificationId ? 'name' : 'createdAt', 'asc'],
// limit: [params.classificationId ? null : 20],
