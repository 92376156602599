import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import ChatButtonComponent from '../Footer/ChatButton';
import FooterLinkComponent from '../Footer/Link';
import MediaButtonComponent from '../Footer/MediaButton';
var CopyrightEsComponent = function () {
    return (React.createElement("div", { className: ' flex flex-1 flex-col border-b-0 border-l-0 border-r-0 border-4 border-solid border-t-chime-pink bg-chime-brown/80 text-white' },
        React.createElement("div", { className: 'm-4 sm:m-8 flex flex-1 flex-col sm:flex-row' },
            React.createElement("div", { className: 'flex flex-1 flex-row' },
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'text-lg mb-1' }, "\u00BFNecesitas ayuda?"),
                    React.createElement("div", null,
                        React.createElement(ChatButtonComponent, null)),
                    React.createElement("div", { className: 'text-sm leading-none mt-2' }, "De lunes a viernes de 10:00 a 18:00")),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'text-lg' }, "Acerca de Nosotros"),
                    React.createElement(FooterLinkComponent, { to: 'aboutUs' }, "\u00BFQui\u00E9nes S\u00F3mos?"),
                    React.createElement(FooterLinkComponent, { to: 'ourShops' }, "Nuestras Tiendas"),
                    React.createElement(FooterLinkComponent, { to: 'contactUs' }, "Cont\u00E1ctanos")),
                React.createElement("div", { className: 'flex sm:flex-1' }),
                React.createElement("div", { className: 'flex sm:flex-1' })),
            React.createElement("div", { className: 'flex flex-col mt-3 sm:mt-0 sm:items-end' },
                React.createElement("div", null,
                    React.createElement(MediaButtonComponent, { href: 'https://www.facebook.com/lachimemx' },
                        React.createElement(FacebookIcon, null)),
                    React.createElement(MediaButtonComponent, { href: 'https://www.instagram.com/lachimemx/' },
                        React.createElement(InstagramIcon, null))),
                React.createElement("div", { className: 'mt-1 text-sm' }, "Encu\u00E9ntranos en redes sociales."))),
        React.createElement("div", { className: 'flex px-4 sm:px-10 py-4 bg-[#86633d] flex-col sm:flex-row' },
            React.createElement("div", { className: 'flex flex-1' }, "\u00A9 2024 La Chime Todos los derechos reservados."),
            React.createElement("div", { className: 'flex pt-2 sm:pt-0' },
                React.createElement(Link, { to: 'terms', className: 'text-white font-normal no-underline hover:underline mr-4' }, "T\u00E9rminos y Condiciones"),
                React.createElement(Link, { to: 'privacy', className: 'text-white font-normal no-underline hover:underline' }, "Aviso de Privacidad")))));
};
export default CopyrightEsComponent;
