import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../../apollo/gql/ecommerce';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
var HeaderProductListComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_ECOMMERCE.GET_EC_CLASSIFICATIONS, {
        variables: {
            ecName: 'lachime',
            order: ['name', 'asc'],
        },
    }), data = _a.data, loading = _a.loading;
    console.log(params.classificationId);
    if (loading)
        return React.createElement(LoadingProgress, { darkTheme: true });
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'pt-4 sm:pt-2 pb-2 text-center' },
        React.createElement("div", { className: 'flex flex-col text-5xl font-medium text-white' },
            React.createElement("div", { className: 'whitespace-nowrap overflow-y-hidden flex flex-row md:mt-3 scrollbar-hide' },
                data.rows.map(function (el) { return (React.createElement(Link, { key: el.id, to: "classification/".concat(el.id), className: 'md:m-2 flex items-center rounded-full text-black h-14 no-underline text-base md:p-5 ml-6' },
                    React.createElement("div", { className: 'flex flex-col justify-center items-center text-black text-base' },
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/31084', className: 'h-10 mt-1 md:mt-0 md:mb-1', alt: '' }),
                        el.name),
                    React.createElement(Divider, { orientation: 'vertical', className: 'ml-6 md:ml-14 bg-chime-brown w-0.5 h-10  items-center' }))); }),
                React.createElement("div", { className: 'mb-3' })))));
};
export default HeaderProductListComponent;
