import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import NotFoundComponent from './NotFound';
import LandingLayoutComponent from './LandingLayout';
import ForgotPasswordComponent from '../containers/_Session/ForgotPassword';
import PaymentComponentEcommerce from '../containers/_Payment';
import ContactEsComponentChime from '../containers/LaChime/LaChimeTemplateEs/Contact';
import FranchiseEsComponent from '../containers/LaChime/LaChimeTemplateEs/Franchise';
import LocationEsComponent from '../containers/LaChime/LaChimeTemplateEs/Location';
// import LaChimeEnTemplateComponent from '../containers/LaChime/LaChimeTemplateEn';
// import HearderEnComponentChime from './LaChime/LaChimeEn/LaChimeLayout';
// import MenuEnComponentChime from '../containers/LaChime/LaChimeTemplateEn/Menu';
// import ContactEnComponentChime from '../containers/LaChime/LaChimeTemplateEn/Contact';
// import LocationEnComponent from '../containers/LaChime/LaChimeTemplateEn/Location';
// import FranchiseEnComponent from '../containers/LaChime/LaChimeTemplateEn/Franchise';
import MenuEsComponentChime from '../containers/LaChime/LaChimeTemplateEs/Menu';
import HearderEsComponentChime from './LaChime/LaChimeEs/LaChimeLayout';
import LaChimeEsTemplateComponent from '../containers/LaChime/LaChimeTemplateEs';
import TopMenuEs from '../containers/LaChime/LaChimeTemplateEs/Menu/TopMenu';
import ClassificationItemProductListComponent from '../containers/LaChime/productList/Body/ClassificationItem';
// import TopMenuEn from '../containers/LaChime/LaChimeTemplateEn/Menu/TopMenu';
import HeaderGenericLayoutComponent from './Generic/GenericLayout';
import FavoriteGenericComponent from '../containers/Generic/Favorite';
import CarGenericComponent from '../containers/Generic/Car';
import ShippingGenericComponent from '../containers/Generic/Car/Shipping';
import ClassificationGenericItemProductListComponent from '../containers/Generic/Car/Product/ProductList/Body/ClassificationItem';
import PrincipalV2GenericComponent from '../containers/Generic/Car/Product/ProductList/Category';
import AboutUsStaticInformationComponent from '../containers/Generic/StaticInformation/aboutUs';
import ContactUsStaticInformationComponent from '../containers/StaticInformation/ContactUs/Form';
import InvoivingStaticInformationComponent from '../containers/Generic/StaticInformation/Invoicing';
import HowBuyStaticInformationComponent from '../containers/Generic/StaticInformation/howBuy';
import OurShopsStaticInformationComponent from '../containers/Generic/StaticInformation/ourShops';
import PaymentMethodsStaticInformationComponent from '../containers/Generic/StaticInformation/paymentMethods';
import PrivacyStaticInformationComponent from '../containers/Generic/StaticInformation/privacy';
import ReturnsStaticInformationComponent from '../containers/Generic/StaticInformation/returns';
import ShippingStaticInformationComponent from '../containers/Generic/StaticInformation/shipping';
import TermsStaticInformationComponent from '../containers/Generic/StaticInformation/terms';
import ProductSetComponent from '../containers/Generic/Car/Product/ProductList/Body/ClassificationItem/ProductSet';
import ReviewsProComponent from '../containers/Generic/reviewsPro';
import ChatComponent from '../containers/Chat';
import HistoryComponent from '../containers/LaChime/LaChimeTemplateEs/HistoryChime';
var children = [
    // { index: true, element: <HeaderGenericLayoutComponent /> },
    {
        path: 'menu',
        element: React.createElement(PrincipalV2GenericComponent, null),
        children: [
            { index: true, element: React.createElement(ClassificationGenericItemProductListComponent, null) },
            {
                path: 'classification/:classificationId',
                element: React.createElement(ClassificationGenericItemProductListComponent, null),
            },
            {
                path: 'classification/:classificationId/productSet/:id',
                element: React.createElement(ProductSetComponent, null),
            },
        ],
    },
    { path: 'favorites', element: React.createElement(FavoriteGenericComponent, null) },
    {
        path: 'car',
        children: [
            { index: true, element: React.createElement(CarGenericComponent, null) },
            {
                path: 'pay',
                element: React.createElement(ShippingGenericComponent, null),
            },
        ],
    },
    { path: 'aboutUs', element: React.createElement(AboutUsStaticInformationComponent, null) },
    { path: 'chat', element: React.createElement(ChatComponent, null) },
    { path: 'contactUs', element: React.createElement(ContactUsStaticInformationComponent, null) },
    { path: 'forgotPassword', element: React.createElement(ForgotPasswordComponent, null) },
    { path: 'howBuy', element: React.createElement(HowBuyStaticInformationComponent, null) },
    { path: 'invoicing', element: React.createElement(InvoivingStaticInformationComponent, null) },
    { path: 'ourShops', element: React.createElement(OurShopsStaticInformationComponent, null) },
    { path: 'paymentMethods', element: React.createElement(PaymentMethodsStaticInformationComponent, null) },
    { path: 'privacy', element: React.createElement(PrivacyStaticInformationComponent, null) },
    { path: 'returns', element: React.createElement(ReturnsStaticInformationComponent, null) },
    { path: 'shipping', element: React.createElement(ShippingStaticInformationComponent, null) },
    { path: 'terms', element: React.createElement(TermsStaticInformationComponent, null) },
    { path: 'reviewsProffesional', element: React.createElement(ReviewsProComponent, null) },
];
export var domainRouter = createBrowserRouter([
    {
        path: '/',
        element: React.createElement(LandingLayoutComponent, null),
        children: children,
    },
    { path: 'payment', element: React.createElement(PaymentComponentEcommerce, null) },
    { path: '*', element: React.createElement(NotFoundComponent, null) },
]);
var router = createBrowserRouter([
    {
        path: 'lachime/:lang',
        element: React.createElement(HearderEsComponentChime, null),
        children: [
            { index: true, element: React.createElement(LaChimeEsTemplateComponent, null) },
            {
                path: 'menu',
                element: React.createElement(MenuEsComponentChime, null),
                children: [
                    { index: true, element: React.createElement(TopMenuEs, null) },
                    {
                        path: 'classification/:classificationId',
                        element: React.createElement(ClassificationItemProductListComponent, null),
                    },
                ],
            },
            {
                path: 'history',
                element: React.createElement(HistoryComponent, null),
            },
            {
                path: 'contact',
                element: React.createElement(ContactEsComponentChime, null),
            },
            {
                path: 'branch',
                element: React.createElement(LocationEsComponent, null),
            },
            {
                path: 'franchise',
                element: React.createElement(FranchiseEsComponent, null),
            },
        ],
    },
    // {
    //   path: 'lachime/en',
    //   element: <HearderEnComponentChime />,
    //   children: [
    //     { index: true, element: <LaChimeEnTemplateComponent /> },
    //     {
    //       path: 'menu',
    //       element: <MenuEnComponentChime />,
    //       children: [
    //         { index: true, element: <TopMenuEn /> },
    //         {
    //           path: 'classification/:classificationId',
    //           element: <ClassificationItemProductListComponent />,
    //         },
    //       ],
    //     },
    //     {
    //       path: 'contact',
    //       element: <ContactEnComponentChime />,
    //     },
    //     {
    //       path: 'location',
    //       element: <LocationEnComponent />,
    //     },
    //     {
    //       path: 'becomeOurPartner',
    //       element: <FranchiseEnComponent />,
    //     },
    //   ],
    // },
    {
        path: ':ecName',
        element: React.createElement(HeaderGenericLayoutComponent, null),
        children: children,
    },
]);
export default router;
