import React from 'react';
import { useParams } from 'react-router-dom';
import getTranslation from '../../../../locale/lachime/translation';
import TimelineComponent from '../Timeline';
var HistoryComponent = function () {
    var lang = useParams().lang;
    var currentLang = lang === 'en' ? 'en' : 'es';
    return (React.createElement("div", null,
        React.createElement("div", { className: 'flex flex-col mt-5' },
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("h1", { className: "text-chime-pink font-['GoBoldBold'] text-5xl mt-10" }, getTranslation(currentLang, 'main.title3'))),
            React.createElement("div", { className: 'flex flex-row flex-1 justify-center' },
                React.createElement("div", { className: 'flex justify-center w-full p-4 md:w-1/3 md:p-0' },
                    React.createElement("p", { className: "text-center font-['GoBold'] mt-10" },
                        React.createElement("span", { className: "text-chime-pink font-bold leading-none font-['GoBoldBold']" }, getTranslation(currentLang, 'main.text3part1')),
                        getTranslation(currentLang, 'main.text3part2')))),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("img", { className: 'w-56 mt-2', src: 'https://storage.capitalike.com/files/28831', alt: 'LOGO' })),
            React.createElement(TimelineComponent, { lang: currentLang }))));
};
export default HistoryComponent;
