import React, { useState, useEffect } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton } from '@mui/material';
var FavoriteProductComponent = function () {
    // Cargar el estado inicial desde localStorage
    var storedFavorite = localStorage.getItem('isFavorite') === 'true';
    var _a = useState(storedFavorite), isFavorite = _a[0], setIsFavorite = _a[1];
    // Guardar en localStorage cada vez que se cambie el estado
    useEffect(function () {
        localStorage.setItem('isFavorite', isFavorite.toString());
    }, [isFavorite]);
    var handleFavoriteToggle = function () {
        setIsFavorite(!isFavorite);
    };
    return (React.createElement("div", { className: 'md:ml-5 ml-10' },
        React.createElement(IconButton, { onClick: handleFavoriteToggle }, isFavorite ? React.createElement(FavoriteIcon, { className: 'text-red-500' }) : React.createElement(FavoriteBorderIcon, null))));
};
export default FavoriteProductComponent;
