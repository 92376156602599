import React, { useEffect, useState, useRef } from 'react';
import getTranslation from '../../../../locale/lachime/translation';
var TimelineComponent = function (_a) {
    var _b;
    var lang = _a.lang;
    var _c = useState(0), activeIndex = _c[0], setActiveIndex = _c[1];
    var itemsRef = useRef([]);
    var timelineData = [
        {
            year: getTranslation(lang, 'timeline.title1'),
            description: getTranslation(lang, 'timeline.text1'),
            imgSrc: 'https://storage.capitalike.com/files/40907',
        },
        {
            year: getTranslation(lang, 'timeline.title2'),
            description: getTranslation(lang, 'timeline.text2'),
            imgSrc: 'https://storage.capitalike.com/files/40904',
        },
        {
            year: getTranslation(lang, 'timeline.title3'),
            description: getTranslation(lang, 'timeline.text3'),
            imgSrc: 'https://storage.capitalike.com/files/40905',
        },
        {
            year: getTranslation(lang, 'timeline.title4'),
            description: getTranslation(lang, 'timeline.text4'),
            imgSrc: 'https://storage.capitalike.com/files/40908',
        },
        {
            year: getTranslation(lang, 'timeline.title5'),
            description: getTranslation(lang, 'timeline.text5'),
            imgSrc: 'https://storage.capitalike.com/files/40909',
        },
    ];
    useEffect(function () {
        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var index = itemsRef.current.indexOf(entry.target);
                    setActiveIndex(index);
                }
            });
        }, { threshold: 0.5 });
        itemsRef.current.forEach(function (item) {
            if (item)
                observer.observe(item);
        });
        return function () { return observer.disconnect(); };
    }, []);
    return (React.createElement("div", { id: 'timeline-1', className: 'timeline-container', style: {
            backgroundImage: "url(".concat((_b = timelineData[activeIndex]) === null || _b === void 0 ? void 0 : _b.imgSrc, ")"),
        } },
        React.createElement("div", { className: 'timeline' }, timelineData.map(function (item, index) { return (React.createElement("div", { key: index, ref: function (el) {
                if (el)
                    itemsRef.current[index] = el;
            }, className: "timeline-item ".concat(index === activeIndex ? 'timeline-item--active' : '') },
            React.createElement("div", { className: 'timeline__content' },
                React.createElement("img", { className: 'timeline__img ', src: item.imgSrc, alt: item.year }),
                React.createElement("div", { className: 'timeline__content-title' }, item.year),
                React.createElement("div", { className: 'timeline__content-desc px-10' }, item.description)))); }))));
};
export default TimelineComponent;
