import { Divider } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React from 'react';
import { Link } from 'react-router-dom';
var CopyrightCapitalikeComponent = function () {
    return (React.createElement("div", { className: 'bg-white h-36 mx-10' },
        React.createElement(Divider, { className: 'h.5 bg-capitalike-gray/40' }),
        React.createElement("div", { className: 'flex flex-row h-full text-capitalike-gray mt-5' },
            React.createElement("div", { className: 'flex flex-1 flex-row mt-7 text-sm' },
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement("div", null, "\u00A9 Capitalike, 2024"),
                    React.createElement("div", null, "Software de punto de venta h\u00EDbrido")),
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement("div", null, "T\u00E9rminos y condiciones"),
                    React.createElement("div", null, "Pol\u00EDtica de privacidad"))),
            React.createElement("div", { className: 'flex flex-1 flex-row mt-7 text-sm' },
                React.createElement("div", { className: 'flex flex-1 flex-col' }),
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement(Link, { to: 'https://wa.me/526671272903', target: '_blank', className: 'bg-capitalike-blue rounded-md no-underline hover:bg-blue-700 text-white font-bold text-center items-center flex flex-row w-64 h-14' },
                        React.createElement(WhatsAppIcon, { className: 'ml-2 mr-1' }),
                        React.createElement("div", null, "Cont\u00E1ctanos por Whatsapp")))))));
};
export default CopyrightCapitalikeComponent;
