import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var OurShopsStaticInformationComponent = function () {
    return (React.createElement("div", { className: 'h-screen mt-24 ml-10' },
        React.createElement(Grid, { container: true, spacing: 4 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle', gutterBottom: true }, "Nuestras Tiendas")),
            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "Suc. Guadalupe Victoria")),
                React.createElement(Typography, { variant: 'body1' }, "Avenida Nakayama #645. Colonia Guadalupe Victoria. Culiac\u00E1n Sinaloaxw"),
                React.createElement("br", null),
                React.createElement(Typography, { variant: 'body1' }, "Abierto de lunes a jueves 10am a 1pm y 4pm a 8pm"),
                React.createElement(Typography, { variant: 'body1' }, "Viernes 4pm a 8pm."),
                React.createElement(Typography, { variant: 'body1' }, "S\u00E1bado 10am a 3.30pm."),
                React.createElement("br", null),
                React.createElement("iframe", { title: 'map', src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d303.40630664011576!2d-107.3717543084269!3d24.802672157779675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd745985b1a81%3A0xd90c6e42e137005a!2sJelouSoft!5e0!3m2!1ses-419!2smx!4v1731696769857!5m2!1ses-419!2smx', width: '600', height: '450', loading: 'lazy' })))));
};
export default OurShopsStaticInformationComponent;
