import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var ShippingStaticInformationComponent = function () {
    return (React.createElement("div", { className: 'mx-10 h-full mt-32' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h6', className: 'text-black/60', gutterBottom: true }, "Env\u00EDos y Paqueter\u00EDas"),
                React.createElement(Typography, { variant: 'h4', gutterBottom: true },
                    React.createElement("strong", null, "Enviamos a toda la rep\u00FAblica")),
                React.createElement(Typography, { variant: 'body1' }, "Identificamos a los mejores transportistas para cada env\u00EDo. Tendr\u00E1s un seguimiento // detallado de tu compra una vez que salga de nuestras bodegas.")),
            React.createElement("div", { className: 'flex flex-row flex-1 mt-10' },
                React.createElement("div", { className: 'h-full w-3 bg-capitalike-gray-font rounded-full mr-5' }),
                React.createElement(Grid, { item: true, xs: 12, className: 'pr-10 text-justify' },
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Realizamos env\u00EDos nacionales a toda la Rep\u00FAblica Mexicana ofreciendo los mejores tiempos de entrega."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "El costo de env\u00EDo ser\u00E1 calculado al momento de confirmar el pago, donde podr\u00E1s elegir la empresa de paqueter\u00EDa que desees que realice la entrega de tu compra, de entre un cat\u00E1logo de empresas de paqueter\u00EDas certificadas que se te presentar\u00E1, lo que nos permite brindar un servicio puntual y de calidad."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Las empresas de paqueter\u00EDa prestan su servicio de lunes a viernes, a excepci\u00F3n de d\u00EDas festivos. El tiempo de entrega ser\u00E1 de 3 a 5 d\u00EDas h\u00E1biles a partir de que recibas el ticket de compra, que ser\u00E1 enviado por correo electr\u00F3nico."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Los operadores de las empresas de paqueter\u00EDa realizar\u00E1n 2 intentos de entrega, si no localiza el cliente durante este periodo la compra ser\u00E1 cancelada."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "En compras mayores a $1,500.00 pesos mexicanos el env\u00EDo es gratis."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Si el pago de tu compra es confirmado de lunes a viernes, el primer d\u00EDa h\u00E1bil es el d\u00EDa en que recibes la confirmaci\u00F3n del pago y desde ese d\u00EDa cuentas el tiempo de entrega."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Si el pago de tu compra es confirmado el s\u00E1bado, domingo o d\u00EDa festivo, el tiempo de entrega estar\u00E1 disponible a partir del siguiente d\u00EDa h\u00E1bil de la confirmaci\u00F3n de tu pago."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Cuando realizas tu pedido en la p\u00E1gina te enviamos a tu correo electr\u00F3nico un n\u00FAmero de confirmaci\u00F3n de venta, una vez confirmado tu pago en nuestra plataforma, procedemos a generar un n\u00FAmero de gu\u00EDa que se enviar\u00E1 a tu correo electr\u00F3nico."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "\u00BFQu\u00E9 puedo hacer si no recib\u00ED mi pedido?"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Si no recibiste tu pedido en el plazo estipulado, te pedimos que por favor te comuniques con la empresa de paqueter\u00EDa que elegiste para la entrega de tu compra, con el n\u00FAmero de gu\u00EDa que te enviamos a tu correo electr\u00F3nico."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "En caso que no puedas comunicarte con la empresa de paqueter\u00EDa, podr\u00E1s enviarnos un correo electr\u00F3nico."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "\u00BFQu\u00E9 pasa si estoy ausente en el momento de entrega?"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Quien recibe el pedido en la direcci\u00F3n registrada debe ser una persona mayor de edad. De no recibirse el pedido en el domicilio se\u00F1alado despu\u00E9s de dos visitas por parte de la empresa de paqueter\u00EDa, el producto ser\u00E1 devuelto al almac\u00E9n que despach\u00F3, su pedido ser\u00E1 cancelado y le ser\u00E1 devuelto el pago que realiz\u00F3 por nuestro producto, sin embargo, el pago de costo del env\u00EDo no ser\u00E1 reembolsable por parte de la empresa de paqueter\u00EDa."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "\u00BFPuedo comprar por la p\u00E1gina y recoger en alg\u00FAn almac\u00E9n?"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Es posible comprar en la p\u00E1gina y recoger en tienda f\u00EDsica, \u00FAnicamente al momento de realizar la compra deber\u00E1 elegir dicha opci\u00F3n y una vez hecha la compra, se generar\u00E1 el ticket donde le se\u00F1alar\u00E1 donde puede recoger su compra."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Cabe recalcar que algunos productos y promociones son independientes de tienda f\u00EDsica y no comparten los mismos inventarios."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "\u00BFPuedo cambiar mi direcci\u00F3n de entrega luego de realizar mi pedido?"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "No es posible cambiar la direcci\u00F3n de entrega una vez realizado el pedido, ya que la empresa de paqueter\u00EDa habr\u00E1 generado la gu\u00EDa correspondiente."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "\u00BFQu\u00E9 debo tener en cuenta al momento de recibir mi pedido?"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Debes revisar muy bien los empaques y verificar que est\u00E9n en perfecto estado en compa\u00F1\u00EDa de la persona que entrega el paquete antes de firmar la gu\u00EDa. En caso de evidenciar una aver\u00EDa en el empaque, no reciba el producto ni firme de recibido, si firma sin revisar perder\u00E1 el derecho de reclamaci\u00F3n."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' },
                        React.createElement("strong", null, "\u00DAltima actualizaci\u00F3n 3 de febrero de 2020"))))),
        React.createElement("div", { className: 'mb-5' })));
};
export default ShippingStaticInformationComponent;
