import React from 'react';
import { Button, Card, TextField } from '@mui/material';
// import CopyrightEsComponent from '../Copyright';
var LocationEsComponent = function () {
    return (React.createElement("div", null,
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-auto' },
            React.createElement("div", { className: 'mb-32' }),
            React.createElement("div", { className: 'text-center text-xl' },
                React.createElement("span", { className: 'text-chime-pink font-bold' }, "\u00A1LA CHIME"),
                " ",
                React.createElement("br", null),
                "MAS CERCANA A TI!"),
            React.createElement("div", { className: 'flex flex-col-reverse md:flex-row p-8' },
                React.createElement("div", { className: 'flex flex-[1] flex-col font-bold m-2 p-4 border-2 border-solid border-chime-pink' },
                    React.createElement("div", { className: 'mt-2' },
                        React.createElement("div", { className: 'flex flex-1 flex-row items-center justify-between' },
                            React.createElement(TextField, { size: 'small', label: 'C\u00F3digo Postal', color: 'secondary' }),
                            React.createElement(Button, { className: 'text-white bg-chime-pink rounded m-2' }, "Buscar")),
                        React.createElement("div", { className: 'text-chime-pink' }, "LA ISLA"),
                        "Plaza 255, Canc\u00FAn 1472-Local 1, Fracc Musal\u00E1 Isla Bonita, 80065 Culiac\u00E1n Rosales, Sin.",
                        React.createElement("div", { className: 'flex flex- col mt-2 justify-between' },
                            React.createElement("div", { className: 'text-chime-brown' }, "Horario: 11:00 am - 11:00 pm"),
                            React.createElement("div", null,
                                React.createElement("a", { className: 'text-chime-brown', href: 'https://maps.app.goo.gl/qsFpeWvMiehTLW826', target: '_blank', rel: 'noreferrer' }, "Ver Mapa")))),
                    React.createElement("div", { className: 'border-2 border-dotted border-slate-200 mt-2' }),
                    React.createElement("div", { className: 'mt-2' },
                        React.createElement("div", { className: 'text-chime-pink' }, "PLAZA CUATRO RIOS"),
                        "Blvrd Enrique S\u00E1nchez Alonso LOCAL A019, PROYECTO URBANO, Desarrollo Urbano Tres R\u00EDos, 80040 Culiac\u00E1n Rosales, Sin.",
                        React.createElement("div", { className: 'flex flex- col mt-2 justify-between' },
                            React.createElement("div", { className: 'text-chime-brown' }, "Horario: 11:00 am - 09:00 pm"),
                            React.createElement("div", null,
                                React.createElement("a", { className: 'text-chime-brown', href: 'https://maps.app.goo.gl/ahJqftyVRuv5R2yp9', target: '_blank', rel: 'noreferrer' }, "Ver Mapa")))),
                    React.createElement("div", { className: 'border-2 border-dotted border-slate-200 mt-2' }),
                    React.createElement("div", { className: 'mt-2' },
                        React.createElement("div", { className: 'text-chime-pink' }, "CEIBA"),
                        "Congreso del Estado, 80100 Culiac\u00E1n Rosales, Sin.",
                        React.createElement("div", { className: 'flex flex- col mt-2 justify-between' },
                            React.createElement("div", { className: 'text-chime-brown' }, "Horario: 11:00 am - 11:00 pm"),
                            React.createElement("div", null,
                                React.createElement("a", { className: 'text-chime-brown', href: 'https://maps.app.goo.gl/QE1o67mj12Y2iEWT9', target: '_blank', rel: 'noreferrer' }, "Ver Mapa")))),
                    React.createElement("div", { className: 'border-2 border-dotted border-slate-200 mt-2' }),
                    React.createElement("div", { className: 'mt-2' },
                        React.createElement("div", { className: 'text-chime-pink' }, "VALLE ALTO"),
                        "Blvd. Alvaro del Portillo, Valle Alto, 80050 Culiac\u00E1n Rosales, Sin.",
                        React.createElement("div", { className: 'flex flex- col mt-2 justify-between' },
                            React.createElement("div", { className: 'text-chime-brown' }, "Horario: 02:00 am - 09:00 pm"),
                            React.createElement("div", null,
                                React.createElement("a", { className: 'text-chime-brown', href: 'https://maps.app.goo.gl/qsFpeWvMiehTLW826', target: '_blank', rel: 'noreferrer' }, "Ver Mapa")))),
                    React.createElement("div", { className: 'border-2 border-dotted border-slate-200 mt-2' })),
                React.createElement(Card, { className: 'flex flex-[3] m-2' },
                    React.createElement("img", { src: '/Captura de pantalla 2024-06-01 a la(s) 10.10.06\u202Fp.m..png', alt: '', className: 'w-full' }))))));
};
export default LocationEsComponent;
