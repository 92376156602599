import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
var TextFieldComponent = function (props) {
    var handleChange = function (e) {
        if (props.onChange)
            props.onChange(e.target.name, e.target.value, props.params);
    };
    return (React.createElement("div", { className: "".concat(props.noPadding ? '' : 'py-1', " w-full") },
        props.label && React.createElement(Typography, { variant: 'subtitle1' }, props.label),
        React.createElement(TextField, { label: props.defaultLabel, name: props.name, variant: props.variant || 'outlined', fullWidth: props.fullWidth || true, autoFocus: props.autoFocus, onChange: handleChange, onKeyDown: props.onKeyDown, value: props.value, error: !!props.error, helperText: props.error || props.helperText, autoComplete: 'off', size: props.size || 'small', type: props.type, placeholder: props.placeholder, disabled: props.disabled, rows: props.rows, multiline: props.multiline, className: props.className, margin: props.margin })));
};
export default TextFieldComponent;
