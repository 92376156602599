import React, { useEffect, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Outlet, Link } from 'react-router-dom';
import MenuComponentCapitalikeLayout from './Menu';
var HeaderCapitalikeComponent = function () {
    var _a = useState(false), scrolled = _a[0], setScrolled = _a[1];
    var handleScroll = function () {
        setScrolled(window.scrollY > 200);
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: scrolled
                ? 'hideen'
                : 'bg-capitalike-blue transition ease-out fixed w-full z-[999] flex flex-1 flex-row items-center font-bold text-white h-20' },
            React.createElement("div", { className: 'flex flex-1 flex-row items-center' },
                React.createElement("div", { className: 'flex flex-row flex-1' },
                    React.createElement(Link, { to: '/capitalike', className: 'mt-3' },
                        React.createElement("img", { 
                            // src='https://storage.capitalike.com/files/32620'
                            src: 'https://storage.capitalike.com/files/33480', alt: 'ISO CAPITALIKE', className: 'w-40 ml-6' })),
                    React.createElement(MenuComponentCapitalikeLayout, null))),
            React.createElement("div", { className: 'text-black flex flex-row flex-1 justify-between' },
                React.createElement("div", null),
                React.createElement(Link, { to: 'https://capitalike.com/login', className: 'mr-10 flex flex-row text-white no-underline' },
                    React.createElement(AccountCircleIcon, { className: 'mr-2' }),
                    React.createElement("div", { className: 'md:flex hidden' }, "Iniciar Sesi\u00F3n")))),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default HeaderCapitalikeComponent;
