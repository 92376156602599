import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var PrivacyStaticInformationComponent = function () {
    return (React.createElement("div", { className: 'mx-10 h-full mt-28' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "Aviso de Privacidad"))),
            React.createElement("div", { className: 'flex flex-row flex-1 mt-10' },
                React.createElement("div", { className: 'h-full w-3 bg-capitalike-gray-font rounded-full' }),
                React.createElement(Grid, { item: true, xs: 12, className: 'ml-3' },
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "De conformidad con los art\u00EDculos 3, fracci\u00F3n I, 15, 16, 40, 43 fracci\u00F3n III y dem\u00E1s relativos de la Ley Federal de Protecci\u00F3n de Datos Personales en Posesi\u00F3n de los Particulares, as\u00ED como su respectivo Reglamento, hacemos de su conocimiento para todos los fines que haya lugar, que ACERDEM con domicilio en calle Juan de Dios Batiz n\u00FAmero 221-M, colonia Guadalupe, Culiac\u00E1n Sinaloa, M\u00E9xico, C.P. 80220, es responsable de recabar sus datos personales, del uso que se le d\u00E9 a los mismos y de su protecci\u00F3n."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Datos personales que recogemos y su tratamiento"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Para las finalidades se\u00F1aladas en el presente Aviso de Privacidad, ACERDEM recabar\u00E1 los datos personales que usted libre y voluntariamente le proporcione o por cualquier otra tecnolog\u00EDa legalmente permitida como nuestra p\u00E1gina de internet, servicios en l\u00EDnea, correo electr\u00F3nico, redes sociales, encuestas de satisfacci\u00F3n, o buz\u00F3n de quejas y sugerencias, \u00FAnica y exclusivamente para los fines que se relacionan con los procesos de venta como: llevar a cabo las actividades y gestiones encaminadas al cumplimiento de las obligaciones originadas y derivadas de cualquier relaci\u00F3n comercial que establezcamos con motivo de la venta de nuestros productos; registrar sus datos en nuestros sistemas de informaci\u00F3n; facturaci\u00F3n; atenci\u00F3n a clientes; otorgamiento de garant\u00EDas o devoluciones de mercanc\u00EDa."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "De igual manera, su informaci\u00F3n personal ser\u00E1 utilizada para las siguientes finalidades: enviar notificaci\u00F3n de ofertas, avisos y/o mensajes promocionales; comunicaciones con fines de mercadotecnia, publicitarios o tele marketing sobre productos existentes; realizar encuestas; estad\u00EDsticas; estudios de mercado sobre h\u00E1bitos de consumo, intereses y comportamiento; realizar programas de beneficios e incentivos; participar en redes sociales, chats y/o foros de discusi\u00F3n; atender sus dudas, quejas y/o sugerencias, as\u00ED como dar seguimiento a las mismas; en general, cualquier otra que sea an\u00E1loga, compatible y/o complementaria de cualquier actividad encaminada a promover, mantener, mejorar y evaluar nuestros productos."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Puede oponerse al tratamiento de sus datos en cualquier momento a trav\u00E9s de los medios que hemos puesto a su disposici\u00F3n para el ejercicio de sus derechos ARCO."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Los datos requeridos son"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Nombre, Registro Federal de Contribuyentes (en caso de ser necesario), domicilio particular y/o fiscal, tel\u00E9fono, direcci\u00F3n de correo electr\u00F3nico (e-mail), n\u00FAmero de tarjeta de cr\u00E9dito o d\u00E9bito."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Uso de Cookies"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "El sitio web www.encantacln.com utiliza cookies para reconocer a los usuarios habituales y rastrear su comportamiento de uso en nuestra p\u00E1gina web. Las cookies no da\u00F1an sus archivos ni permiten que nadie tenga acceso a sus datos personales; simplemente nos permiten personalizar la informaci\u00F3n, as\u00ED como hacer m\u00E1s c\u00F3moda y eficiente sus visitas a nuestro sitio, pueden ser utilizadas para monitorear el comportamiento de un visitante, almacenar informaci\u00F3n sobre su direcci\u00F3n IP, duraci\u00F3n del tiempo de interacci\u00F3n en nuestra p\u00E1gina y el tipo de navegador utilizado. Asimismo, las p\u00E1ginas de Internet que visita, los v\u00EDnculos que sigue, duraci\u00F3n media de la visita, porcentaje de visitas nuevas, datos demogr\u00E1ficos, frecuencia de visitas y sistema operativo."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Transferencia de Datos"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Sus datos personales \u00FAnicamente podr\u00E1n ser compartidos con otras empresas y/o personas f\u00EDsicas, con quienes haya una relaci\u00F3n profesional directa o indirecta, para dar satisfacci\u00F3n a la relaci\u00F3n contractual y jur\u00EDdica que mantenemos."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Nos comprometemos a no transferir su informaci\u00F3n personal a terceros sin su consentimiento, salvo las excepciones previstas en el art\u00EDculo 37 de la Ley Federal de Protecci\u00F3n de Datos Personales en Posesi\u00F3n de los Particulares, as\u00ED como a realizar esta transferencia en los t\u00E9rminos que fija esa Ley."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Entendemos que ha otorgado su consentimiento a la transferencia de sus Datos Personales, al haber manifestado expresamente, que no existe oposici\u00F3n a que los mismos sean transferidos a trav\u00E9s de los medios que hemos puesto a su disposici\u00F3n y hecho de su conocimiento en t\u00E9rminos de este Aviso."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Derecho del Titular de los Datos Personales"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Usted tiene derecho al Acceso, Rectificaci\u00F3n y Cancelaci\u00F3n de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado, cuando considere que los mismos no est\u00E1n siendo utilizados conforme a los principios, deberes y obligaciones que establece la Ley. Estos derechos se conocen como derechos ARCO."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Nos comprometemos a que sus datos ser\u00E1n tratados bajo medidas de seguridad y garantizando su confidencialidad. En todo momento usted podr\u00E1 revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales y/o sensibles, en su caso, a fin de que dejemos de hacer uso de los mismos. Para ello, es necesario que presente su petici\u00F3n por correo electr\u00F3nico a servicio@encantacln.com"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "La solicitud deber\u00E1 contener por lo menos: nombre y domicilio del titular u otro medio para comunicarle la respuesta a su solicitud; descripci\u00F3n clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos; cualquier otro elemento o documento que facilite la localizaci\u00F3n de los Datos Personales. Deber\u00E1 acompa\u00F1ar los documentos que acrediten su identidad o, en su caso, la representaci\u00F3n legal del titular, as\u00ED como cualquier otro documento que exija la legislaci\u00F3n vigente al momento de la presentaci\u00F3n de la solicitud."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "A dicha solicitud se dar\u00E1 respuesta en un t\u00E9rmino no mayor a veinte d\u00EDas h\u00E1biles, en la que se le informar\u00E1 las medidas que se han realizado para la acci\u00F3n solicitada, la respuesta se dar\u00E1 por la misma v\u00EDa en que nos envi\u00F3 su solicitud."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Cambios al Aviso de Privacidad"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Nos reservamos el derecho de efectuar en cualquier momento las modificaciones, o actualizaciones al presente Aviso de Privacidad, para atenci\u00F3n de novedades, de pol\u00EDticas internas o nuevos requerimientos para la prestaci\u00F3n u ofrecimiento de nuestros servicios o productos."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Aceptaci\u00F3n de los T\u00E9rminos"),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' }, "Al enviar la informaci\u00F3n solicitada por medio de nuestra p\u00E1gina web, v\u00EDa telef\u00F3nica o de manera personal usted est\u00E1 aceptando nuestros t\u00E9rminos y condiciones, as\u00ED como la pol\u00EDtica o Aviso de Privacidad contenidos en nuestro sitio, lo cual constituye un acuerdo legal entre el usuario y la empresa. Si el usuario utiliza nuestros servicios, significa que ha le\u00EDdo, entendido y acordado los t\u00E9rminos antes expuestos."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify' },
                        React.createElement("strong", null, "\u00DAltima actualizaci\u00F3n 3 de febrero de 2020")))))));
};
export default PrivacyStaticInformationComponent;
