import { inputError, snackbar } from '../apollo/cache';
import parseError from './parseError';
var getInputErrorFormat = function (e) {
    var _a, _b;
    try {
        var serverError = parseError(e);
        if (serverError && serverError[0].name === 'INVENTORY_ERROR') {
            inputError({ pin: serverError[0].message });
            return;
        }
        if (serverError && serverError[0].name === 'BAD_USER_INPUT') {
            var inputErrorKey = Object.keys(serverError[0].message)[0];
            var response = (_a = {}, _a[inputErrorKey] = serverError[0].message[inputErrorKey], _a);
            inputError(response);
            return;
        }
        var unformattedError = e.message;
        var error = JSON.parse(unformattedError);
        inputError((_b = {}, _b[error.name] = error.message, _b));
    }
    catch (_c) {
        snackbar({ isOpen: true, label: e.message, severity: 'warning', time: 3000 });
    }
};
export default getInputErrorFormat;
