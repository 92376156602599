import React from 'react';
import { Outlet, Link, useParams } from 'react-router-dom';
import CopyrightEsComponent from '../../../../containers/LaChime/LaChimeTemplateEs/Copyright';
import LanguageButtonComponent from './LanguageButton';
import getTranslation from '../../../../locale/lachime/translation';
var HearderEsComponentChime = function () {
    var lang = useParams().lang;
    var currentLang = lang === 'en' ? 'en' : 'es';
    return (React.createElement("main", { className: 'w-screen h-screen overflow-y-auto' },
        React.createElement("header", { className: 'flex flex-row bg-gray-100 w-full items-center px-32 py-1.5' },
            React.createElement("div", { className: 'flex flex-1 items-center gap-4 justify-start' },
                React.createElement(Link, { to: 'menu', className: 'no-underline text-black font-bold cursor-pointer border-solid border-0 border-b-2 border-transparent hover:border-[#e72666] transition-all duration-100 ease-in-out' }, getTranslation(currentLang, 'header.menu')),
                React.createElement(Link, { to: 'history', className: 'no-underline text-black font-bold cursor-pointer border-solid border-0 border-b-2 border-transparent hover:border-[#e72666] transition-all duration-100 ease-in-out' }, getTranslation(currentLang, 'header.history')),
                React.createElement(Link, { to: 'branch', className: 'no-underline text-black font-bold cursor-pointer border-solid border-0 border-b-2 border-transparent hover:border-[#e72666] transition-all duration-100 ease-in-out' }, getTranslation(currentLang, 'header.branch'))),
            React.createElement("div", { className: 'flex flex-1 justify-center' },
                React.createElement(Link, { to: '/lachime/es', className: 'leading-none' },
                    React.createElement("img", { className: 'w-28', src: 'https://res.cloudinary.com/dqup64jlq/image/upload/v1732946437/LaChime/logo-1.webp', alt: 'La Chime' }))),
            React.createElement("div", { className: 'flex flex-1 items-center gap-4 justify-end' },
                React.createElement(Link, { to: 'contact', className: 'no-underline text-black font-bold cursor-pointer border-solid border-0 border-b-2 border-transparent hover:border-[#e72666] transition-all duration-100 ease-in-out' }, getTranslation(currentLang, 'header.contact')),
                React.createElement(Link, { to: 'franchise', className: 'no-underline bg-[#eb497f] hover:bg-[#e72666] px-5 py-3 rounded-full cursor-pointer transition-all duration-100 ease-in-out' },
                    React.createElement("div", { className: 'font-bold text-white' }, getTranslation(currentLang, 'header.franchise'))),
                React.createElement(LanguageButtonComponent, null))),
        React.createElement(Outlet, null),
        React.createElement(CopyrightEsComponent, null)));
};
export default HearderEsComponentChime;
