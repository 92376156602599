import { TextField } from '@mui/material';
import React from 'react';
var DeliveryGenericComponent = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'bg-slate-100 text-2xl font-bold ml-6' }, "Lugar de entrega"),
        React.createElement("div", { className: 'w-100 bg-white rounded-xl shadow-md mx-5 flex flex-col overflow-auto flex-1 p-5 mt-3' },
            React.createElement(TextField, { label: 'Nombre y Apellido', size: 'small', className: 'w-100 mt-4' }),
            React.createElement(TextField, { label: 'C\u00F3digo Postal', size: 'small', className: 'w-100 mt-4' }),
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement(TextField, { label: 'Estado', size: 'small', className: 'w-1/2 mt-4' }),
                React.createElement("div", { className: 'p-2.5' }),
                React.createElement(TextField, { label: 'Municipio', size: 'small', className: 'w-1/2 mt-4' })),
            React.createElement(TextField, { label: 'Colonia', size: 'small', className: 'w-100 mt-4' }),
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement(TextField, { label: 'Calle', size: 'small', className: 'w-1/2 mt-4' }),
                React.createElement("div", { className: 'p-2.5' }),
                React.createElement(TextField, { label: 'N\u00FAmero exterior', size: 'small', className: 'w-1/2 mt-4' })),
            React.createElement(TextField, { label: 'N\u00FAmero interior (opcional)', size: 'small', className: 'w-100 mt-4' }),
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement(TextField, { label: 'Calle 1', size: 'small', className: 'w-1/2 mt-4' }),
                React.createElement("div", { className: 'p-1.5' }),
                React.createElement(TextField, { label: 'Calle 2', size: 'small', className: 'w-1/2 mt-4' })),
            React.createElement(TextField, { label: 'Tel\u00E9fono Celular', size: 'small', className: 'w-100 mt-4' }),
            React.createElement(TextField, { label: 'Referiencias del domicilio', size: 'small', className: 'w-100 mt-4' }))));
};
export default DeliveryGenericComponent;
