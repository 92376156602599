import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var PaymentMethodsStaticInformationComponent = function () {
    return (React.createElement("div", { className: 'mx-10 h-screen mt-32' },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h6', className: 'text-black/60', gutterBottom: true }, "\u00BFC\u00F3mo pagar?"),
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "Pagar es muy f\u00E1cil"))),
            React.createElement("div", { className: 'flex flex-row flex-1 mt-10' },
                React.createElement("div", { className: 'h-full w-3 bg-capitalike-gray-font rounded-full mr-5' }),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle' }, "Efectivo"),
                    React.createElement(Typography, { variant: 'h4', gutterBottom: true },
                        React.createElement("strong", null, "Paga con efectivo en OXXO")),
                    React.createElement(Typography, { variant: 'body1' }, "Podr\u00E1s hacer pagos en efectivo acudiendo a la tienda m\u00E1s cercana de OXXO. Los pagos se acreditan al instante."),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle' }, "Tarjeta"),
                    React.createElement(Typography, { variant: 'h4', gutterBottom: true },
                        React.createElement("strong", null, "Paga de forma f\u00E1cil y segura con tu tarjeta")),
                    React.createElement(Typography, { variant: 'body1' }, "Podr\u00E1s pagar con tu tarjeta de cr\u00E9dito o d\u00E9bito de forma segura desde cualquier // dispositivo con conexi\u00F3n a internet."))))));
};
export default PaymentMethodsStaticInformationComponent;
