import React from 'react';
import ResumenGenericComponent from '../Resumen';
// import DeliveryGenericComponent from './Delivery';
// import ProductGenericComponent from '../Product';
import DeliveryGenericComponent from './Delivery';
import PaymentGenericComponent from './PaymentMethod';
var ShippingGenericComponent = function () {
    return (React.createElement("div", { className: 'h-full md:h-screen bg-slate-100 mt-28' },
        React.createElement("div", { className: 'flex flex-col md:flex-row flex-1 md:mx-32' },
            React.createElement("div", { className: 'flex flex-1 flex-col md:ml-20' },
                React.createElement("div", { className: 'flex flex-col scrollbar-hide' },
                    React.createElement(DeliveryGenericComponent, null))),
            React.createElement("div", { className: 'flex flex-1 flex-col mt-10' },
                React.createElement("div", { className: 'flex flex-col mb-3' },
                    React.createElement(PaymentGenericComponent, null)),
                React.createElement("div", { className: 'flex flex-col h-64 mt-5 mb-20' },
                    React.createElement(ResumenGenericComponent, null))))));
};
export default ShippingGenericComponent;
