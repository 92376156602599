import React from 'react';
import { Link, useParams } from 'react-router-dom';
// import { Divider } from '@mui/material';
import useApolloQuery from '../../../../../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../../../../../apollo/gql/ecommerce';
import LoadingProgress from '../../../../../../../presentationals/LoadingProgress';
var ClassificationGenericComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_ECOMMERCE.GET_EC_CLASSIFICATIONS, {
        variables: {
            ecName: params.ecName,
            order: ['name', 'asc'],
        },
    }), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, { darkTheme: true });
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", null,
        React.createElement("div", { className: 'text-2xl font-bold md:ml-1 mt-10 text-center md:text-left' }, "Categorias"),
        data.count === 1 ? (React.createElement("div", { className: 'hidden' })) : (React.createElement("div", { className: 'flex flex-row md:flex-col font-semibold overflow-auto h-16 md:h-full bg-capitalike-gray-font/20 md:bg-inherit mt-2 mb-3' }, data.rows.map(function (el) { return (React.createElement(Link, { key: el.id, to: "classification/".concat(el.id), className: 'md:m-2 flex items-center text-black  no-underline text-sm whitespace-nowrap md:text-base ml-2 mr-4 ' },
            React.createElement("div", null, el.name))); })))));
};
export default ClassificationGenericComponent;
