import { Button, Divider, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
var ResumenGenericCuponComponent = function () {
    var _a = useState(''), coupon = _a[0], setCoupon = _a[1];
    var _b = useState(false), dialogOpen = _b[0], setDialogOpen = _b[1];
    var navigate = useNavigate();
    var handleSubmit = function () {
        if (!coupon.trim())
            setDialogOpen(true);
        else
            console.log('Cupón ingresado:', coupon);
    };
    var handleDialogClose = function (proceed) {
        setDialogOpen(false);
        if (proceed)
            navigate('pay');
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: 'bg-slate-100 text-2xl font-bold ml-6' }, "Resumen de compra"),
        React.createElement("div", { className: 'flex-1 bg-white rounded-xl shadow-md mx-5 flex flex-col h-72 w-96 mt-3' },
            React.createElement("div", { className: 'flex flex-row justify-between font-semibold' },
                React.createElement("div", { className: 'ml-3 mt-3' }, "Producto"),
                React.createElement("div", { className: 'mr-3 mt-3' }, "$999.00")),
            React.createElement(Divider, { className: 'w-100 mt-3' }),
            React.createElement("div", { className: 'flex flex-row justify-between font-semibold' },
                React.createElement("div", { className: 'ml-3 mt-3' }, "Envio"),
                React.createElement("div", { className: 'mr-3 mt-3' }, "$999.00")),
            React.createElement(Divider, { className: 'w-100 mt-3' }),
            React.createElement("div", { className: 'flex flex-row justify-between font-semibold' },
                React.createElement("div", { className: 'ml-3 mt-3' }, "Total"),
                React.createElement("div", { className: 'mr-3 mt-3' }, "$999.00")),
            React.createElement(Divider, { className: 'w-100 mt-3' }),
            React.createElement("div", { className: 'flex flex-row ml-3 font-semibold justify-between mt-3' },
                React.createElement("div", { className: 'text-red-600' }, "Cup\u00F3n de Descuento:"),
                React.createElement(TextField, { size: 'small', className: 'animate-pulse mr-2', value: coupon, onChange: function (e) { return setCoupon(e.target.value); }, sx: {
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 2,
                                borderColor: 'red',
                            },
                            '&:hover fieldset': {
                                borderColor: 'red',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'red',
                            },
                        },
                    } })),
            React.createElement(Divider, { className: 'w-100 mt-3' }),
            React.createElement("div", { className: 'text-center mt-5' },
                React.createElement(Button, { onClick: handleSubmit, className: 'bg-blue-600 text-white font-bold hover:bg-blue-700 w-80' }, "Continuar compra")),
            React.createElement(Dialog, { open: dialogOpen, onClose: function () { return handleDialogClose(false); } },
                React.createElement(DialogTitle, { className: 'text-center font-bold' }, "Campo de Cup\u00F3n Vac\u00EDo"),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, { className: 'text-capitalike-gray-font' }, "No ingres\u00F3 un cup\u00F3n de descuento. \u00BFDesea regresar para ingresarlo o continuar sin uno?")),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: function () { return handleDialogClose(false); }, className: 'bg-blue-600 text-white font-bold hover:bg-blue-700' }, "Regresar"),
                    React.createElement(Button, { onClick: function () { return handleDialogClose(true); }, className: 'bg-red-600 text-white font-bold hover:bg-red-700', autoFocus: true }, "Continuar sin cup\u00F3n"))))));
};
export default ResumenGenericCuponComponent;
