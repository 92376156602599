import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Radio, RadioGroup, Typography, } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import usePresentationStore from '../../../../../../../../../store/usePresentationStore';
var ProductVariationsComponent = function () {
    var selectedPresentation = usePresentationStore(function (state) { return state.selectedPresentation; });
    var selectedIngredients = usePresentationStore(function (state) { return state.selectedIngredients; });
    var setSelectedIngredient = usePresentationStore(function (state) { return state.setSelectedIngredient; });
    var handleChange = function (variationName, ingredient) {
        setSelectedIngredient(variationName, ingredient);
    };
    console.log('d', selectedPresentation === null || selectedPresentation === void 0 ? void 0 : selectedPresentation.ProductIngredients);
    return (React.createElement("div", { className: 'mt-2' },
        selectedPresentation && selectedPresentation.ProductVariations ? (React.createElement("div", null, selectedPresentation.ProductVariations.map(function (variation) {
            var _a;
            return (React.createElement(Accordion, { key: variation.id, className: 'mt-2 mb-5' },
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ArrowDropDownIcon, null), "aria-controls": "panel-content-".concat(variation.id), id: "panel-header-".concat(variation.id) },
                    React.createElement(Typography, { className: 'font-bold' }, variation.name)),
                React.createElement(AccordionDetails, null,
                    React.createElement(FormControl, { className: 'flex flex-1' },
                        React.createElement(RadioGroup, { "aria-labelledby": "radio-buttons-group-".concat(variation.id), name: "radio-buttons-group-".concat(variation.id), value: ((_a = selectedIngredients[variation.name]) === null || _a === void 0 ? void 0 : _a.ingredient.id) || '', onChange: function (e) {
                                var _a;
                                return handleChange(variation.name, (_a = variation.ProductIngredients) === null || _a === void 0 ? void 0 : _a.find(function (ing) { return ing.id === e.target.value; }));
                            } }, variation.ProductIngredients && variation.ProductIngredients.length > 0 ? (React.createElement("div", null, variation.ProductIngredients.map(function (ingredient) { return (React.createElement("div", { key: ingredient.id, className: 'flex flex-1 flex-row items-center justify-between  font-semibold' },
                            React.createElement("div", null, ingredient.Ingredient.name),
                            React.createElement(FormControlLabel, { label: '', value: ingredient.id, control: React.createElement(Radio, null) }))); }))) : (React.createElement("p", null, "Sin ingredientes disponibles para esta variaci\u00F3n.")))))));
        }))) : (React.createElement("div", null)),
        selectedPresentation &&
            selectedPresentation.ProductIngredients &&
            selectedPresentation.ProductIngredients.length > 0 ? (React.createElement("div", null,
            React.createElement("div", { className: 'font-bold text-xl mt-10' }, "Extras"),
            selectedPresentation.ProductIngredients.map(function (variation) {
                var _a;
                return (React.createElement(Accordion, { key: variation.id, className: 'mt-2 mb-5' },
                    React.createElement(AccordionSummary, { expandIcon: React.createElement(ArrowDropDownIcon, null), "aria-controls": "panel-content-".concat(variation.id), id: "panel-header-".concat(variation.id) },
                        React.createElement(Typography, { className: 'font-bold' }, variation.Ingredient.name)),
                    React.createElement(AccordionDetails, null,
                        React.createElement(FormControl, { className: 'flex flex-1' },
                            React.createElement(RadioGroup, { "aria-labelledby": "radio-buttons-group-".concat(variation.id), name: "radio-buttons-group-".concat(variation.id), value: ((_a = selectedIngredients[variation.Ingredient.name]) === null || _a === void 0 ? void 0 : _a.ingredient.id) || '' }, variation.Ingredient.name)))));
            }))) : (React.createElement("div", null))));
};
export default ProductVariationsComponent;
