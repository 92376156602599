import { Button, Dialog, DialogContent, IconButton, TextField } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import React from 'react';
var FormReviewsComponent = function (props) {
    return (React.createElement(Dialog, { open: props.open, onClose: props.onClose },
        React.createElement(DialogContent, null,
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", null),
                React.createElement(IconButton, { onClick: props.onClose },
                    React.createElement(CloseIcon, { className: 'text-red-500' }))),
            React.createElement("div", { className: 'ml-10 font-bold text-xl' }, "A\u00F1adir comentario/rese\u00F1a"),
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(TextField, { label: 'Usuario', className: 'ml-10 mr-5 mt-5', size: 'medium', maxRows: 1 }),
                React.createElement(TextField, { label: 'Comentario', className: 'ml-10 mr-5 mt-5', multiline: true, size: 'medium', maxRows: 5 }),
                React.createElement("div", { className: 'flex flex-row ml-10' },
                    React.createElement("div", null,
                        React.createElement(Button, { className: 'text-capitalike-gray-font  text-xs  mt-5  font-bold h-14 flex flex-row mr-5' },
                            React.createElement(ImageIcon, { className: 'w-4 mr-2' }),
                            "A\u00F1adir Imagen")),
                    React.createElement("div", { className: 'ml-10' },
                        React.createElement(Button, { className: 'bg-capitalike-blue  text-xs  mt-5 text-white font-bold p-2  flex flex-row mr-5' },
                            React.createElement(SendIcon, { className: 'w-4 mr-2' }),
                            "Enviar")))))));
};
export default FormReviewsComponent;
