import React from 'react';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// import CopyrightEsComponent from '../Copyright';
var ContactEsComponentChime = function () {
    return (React.createElement("div", { className: 'flex flex-col flex-1 h-screen w-full text-white overflow-auto' },
        React.createElement("div", { className: 'mb-28' }),
        React.createElement("img", { className: 'w-full mb-4', src: 'https://storage.capitalike.com/files/31821', alt: 'IMAGEN' }),
        React.createElement("div", { className: 'md:px-44 ' },
            React.createElement("div", { className: 'text-chime-pink text-center text-3xl font-bold' }, "Cont\u00E1ctanos"),
            React.createElement("div", { className: 'flex flex-col px-10' },
                React.createElement("div", { className: 'flex flex-row mt-5' },
                    React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Nombre' }),
                    React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Apellido' })),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Correo electr\u00F3nico' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Motivo' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Estado' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Ciudad' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Mensaje' })),
            React.createElement("div", { className: 'text-center' },
                React.createElement(Button, { variant: 'contained', endIcon: React.createElement(SendIcon, null), className: 'bg-chime-pink mb-10 mt-4 w-32' }, "Enviar")))));
};
export default ContactEsComponentChime;
