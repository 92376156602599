import { Divider, IconButton } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
var FavoriteGenericComponent = function () {
    return (React.createElement("div", { className: 'h-full text-black  items-center mt-24 md:mx-80' },
        React.createElement("div", { className: 'flex flex-1 flex-col rounded-lg mt-3' },
            React.createElement("div", { className: 'text-center font-bold text-2xl mt-4 mb-4' }, "Favoritos"),
            React.createElement("div", { className: 'flex flex-row bg-white mx-5' },
                React.createElement("img", { className: 'w-28  h-28 md:w-[120px] md:h-[120px] ml-3 mt-5 rounded-xl object-contain', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "MAGNO FIT"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 text-justify pr-5 h-20 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold text-center mt-1' }, "$449.00"),
                        React.createElement("div", { className: 'mr-5' },
                            React.createElement(IconButton, { className: 'text-red-600' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(IconButton, { className: ' ml-10 text-black' },
                                React.createElement(ShoppingCartIcon, null)))))),
            React.createElement(Divider, { className: 'w-100' }),
            React.createElement("div", { className: 'flex flex-row bg-white mx-5' },
                React.createElement("img", { className: 'w-28  h-28 md:w-[120px] md:h-[120px] ml-3 mt-5 rounded-xl object-contain', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "MAGNO FIT"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 text-justify pr-5 h-20 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold text-center mt-1' }, "$449.00"),
                        React.createElement("div", { className: 'mr-5' },
                            React.createElement(IconButton, { className: 'text-red-600' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(IconButton, { className: ' ml-10 text-black' },
                                React.createElement(ShoppingCartIcon, null)))))),
            React.createElement(Divider, { className: 'w-100' }),
            React.createElement("div", { className: 'flex flex-row bg-white mx-5' },
                React.createElement("img", { className: 'w-28  h-28 md:w-[120px] md:h-[120px] ml-3 mt-5 rounded-xl object-contain', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "MAGNO FIT"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 text-justify pr-5 h-20 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold text-center mt-1' }, "$449.00"),
                        React.createElement("div", { className: 'mr-5' },
                            React.createElement(IconButton, { className: 'text-red-600' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(IconButton, { className: ' ml-10 text-black' },
                                React.createElement(ShoppingCartIcon, null)))))),
            React.createElement(Divider, { className: 'w-100' }),
            React.createElement("div", { className: 'flex flex-row bg-white mx-5' },
                React.createElement("img", { className: 'w-28  h-28 md:w-[120px] md:h-[120px] ml-3 mt-5 rounded-xl object-contain', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "MAGNO FIT"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 text-justify pr-5 h-20 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold text-center mt-1' }, "$449.00"),
                        React.createElement("div", { className: 'mr-5' },
                            React.createElement(IconButton, { className: 'text-red-600' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(IconButton, { className: ' ml-10 text-black' },
                                React.createElement(ShoppingCartIcon, null)))))),
            React.createElement(Divider, { className: 'w-100' }),
            React.createElement("div", { className: 'flex flex-row bg-white mx-5' },
                React.createElement("img", { className: 'w-28  h-28 md:w-[120px] md:h-[120px] ml-3 mt-5 rounded-xl object-contain', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "MAGNO FIT"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 text-justify pr-5 h-20 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold text-center mt-1' }, "$449.00"),
                        React.createElement("div", { className: 'mr-5' },
                            React.createElement(IconButton, { className: 'text-red-600' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(IconButton, { className: ' ml-10 text-black' },
                                React.createElement(ShoppingCartIcon, null)))))),
            React.createElement(Divider, { className: 'w-100' }),
            React.createElement("div", { className: 'flex flex-row bg-white mx-5' },
                React.createElement("img", { className: 'w-28  h-28 md:w-[120px] md:h-[120px] ml-3 mt-5 rounded-xl object-contain', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "MAGNO FIT"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 text-justify pr-5 h-20 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold text-center mt-1' }, "$449.00"),
                        React.createElement("div", { className: 'mr-5' },
                            React.createElement(IconButton, { className: 'text-red-600' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(IconButton, { className: ' ml-10 text-black' },
                                React.createElement(ShoppingCartIcon, null)))))),
            React.createElement(Divider, { className: 'w-100' }),
            React.createElement("div", { className: 'flex flex-row bg-white mx-5' },
                React.createElement("img", { className: 'w-28  h-28 md:w-[120px] md:h-[120px] ml-3 mt-5 rounded-xl object-contain', src: 'https://storage.capitalike.com/files/33655', alt: 'LOGO' }),
                React.createElement("div", { className: 'flex flex-col flex-1' },
                    React.createElement("div", { className: 'ml-3 mt-3 font-bold' }, "MAGNO FIT"),
                    React.createElement("div", { className: 'ml-3 text-gray-500 text-justify pr-5 h-20 overflow-hidden' }, "Mejora la calidad del sue\u00F1o, promueve la relajaci\u00F3n, reduce sintomas de estr\u00E9s y ansiedad, alivia el estre\u00F1imiento, promueve la absorci\u00F3n eficiente de nutrientes, aumenta la sensaci\u00F3n de saciedad, apoya la p\u00E9rdida de peso, ayuda a mantener niveles saludables de azucar"),
                    React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                        React.createElement("div", { className: 'ml-3 font-bold text-center mt-1' }, "$449.00"),
                        React.createElement("div", { className: 'mr-5' },
                            React.createElement(IconButton, { className: 'text-red-600' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(IconButton, { className: ' ml-10 text-black' },
                                React.createElement(ShoppingCartIcon, null)))))),
            React.createElement(Divider, { className: 'w-100' })),
        React.createElement("div", { className: 'mt-10' })));
};
export default FavoriteGenericComponent;
