import React from 'react';
import { Grid, Typography } from '@mui/material';
import FormComponent from './Form';
var InvoivingStaticInformationComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col mt-32' },
        React.createElement(Grid, { container: true, spacing: 2, className: 'md:p-5 md:px-36 border-solid border-[#ccc] border-0 border-b-[1px] h-screen' },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement("div", { className: 'flex flex-row' },
                    React.createElement("div", null,
                        React.createElement("div", { className: 'h-full w-3 bg-capitalike-gray-font rounded-full mr-5' })),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle', gutterBottom: true }, "Inicio / Facturaci\u00F3n"),
                        React.createElement(Typography, { variant: 'h4' },
                            React.createElement("strong", null, "Facturaci\u00F3n"),
                            React.createElement("br", null),
                            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Emite un CFDi con el folio de tu compra, tienes 7 d\u00EDas para hacerlo.")))),
                React.createElement(Grid, { container: true, className: 'md:ml-8 p-4 md:p-0' },
                    React.createElement(Grid, { item: true, xs: 12, md: 5 },
                        React.createElement(FormComponent, null)))))));
};
export default InvoivingStaticInformationComponent;
