var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import GQL from '../../_gql';
import { ECCustomerSessionData, ECPaymentForm, ECPaymentStep, ECShipmentRates, ECShipping, inputError, } from '../../../../../../apollo/cache';
import setInputErrorFormat from '../../../../../../utils/setInputErrorFormat';
import getCart from '../../../../../../utils/getCart';
import apolloClient from '../../../../../../apollo/index';
import getInputErrorFormat from '../../../../../../utils/getInputErrorFormat';
var step1ValidationHelpersContinueButtonActionBodyPayment = function (props) { return __awaiter(void 0, void 0, void 0, function () {
    var values, shipping, cartVar, sessionData, Products, response, currentCart, tmpTotal, Products, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                values = ECPaymentForm();
                shipping = ECShipping();
                inputError({});
                if (values.name === '')
                    throw new Error(setInputErrorFormat('name', 'Proporciona tu nombre completo.'));
                if (values.email === '')
                    throw new Error(setInputErrorFormat('email', 'Proporciona un correo electrónico.'));
                if (!isEmail(values.email))
                    throw new Error(setInputErrorFormat('email', 'Proporciona un correo electrónico valido.'));
                if (values.phone === '')
                    throw new Error(setInputErrorFormat('phone', 'Proporciona un número de teléfono.'));
                if (!isMobilePhone("+52".concat(values.phone), ['es-MX'], { strictMode: true }))
                    throw new Error(setInputErrorFormat('phone', 'Proporciona un número de teléfono valido.'));
                if (!values.isPickupActive) {
                    if (values.addressLine1 === '')
                        throw new Error(setInputErrorFormat('addressLine1', 'Proporciona una calle y número de exterior.'));
                    if (values.addressLine2 === '')
                        throw new Error(setInputErrorFormat('addressLine2', 'Proporciona una colonia.'));
                    if (values.zipCode === '')
                        throw new Error(setInputErrorFormat('zipCode', 'Proporciona un código postal.'));
                    if (values.city === '')
                        throw new Error(setInputErrorFormat('city', 'Proporciona una ciudad.'));
                    if (values.state === '')
                        throw new Error(setInputErrorFormat('state', 'Proporciona un estado.'));
                    if (values.country === '')
                        throw new Error(setInputErrorFormat('country', 'Proporciona un país.'));
                }
                if (!(values.isPickupActive || shipping)) return [3 /*break*/, 3];
                return [4 /*yield*/, getCart()];
            case 1:
                cartVar = _a.sent();
                sessionData = ECCustomerSessionData();
                Products = cartVar.map(function (el) { return ({
                    productId: el.productId,
                    productPresentationId: el.productPresentationId,
                    quantity: 1,
                    discount: 0,
                    commentary: '',
                    ProductAttributes: el.attributesAdded
                        .map(function (elCh) { return ({
                        id: elCh.id,
                        attribute: elCh.attribute.toString(),
                    }); })
                        .filter(function (el) { return el.id; }),
                    ProductIngredients: el.ProductIngredients
                        ? el.ProductIngredients.map(function (elCh) { return ({
                            productId: elCh.productId,
                            productPresentationId: elCh.productPresentationId,
                            quantity: 1,
                            ProductAttributes: elCh.attributesAdded
                                .map(function (elCh1) { return ({
                                id: elCh1.id,
                                attribute: elCh1.attribute.toString(),
                            }); })
                                .filter(function (el) { return el.id; }),
                        }); })
                        : [],
                }); });
                return [4 /*yield*/, apolloClient.mutate({
                        mutation: GQL.CREATE_EC_SALE_CHECKOUT,
                        variables: {
                            ecName: props.companyName,
                            type: values.isPickupActive ? 3 : 2,
                            Customer: {
                                firstName: values.name,
                                phone: values.phone,
                                email: values.email,
                                addressLine1: values.addressLine1 || '',
                                addressLine2: values.addressLine2 || '',
                                zipCode: values.zipCode || '',
                                city: values.city || '',
                                municipality: values.municipality || '',
                                state: values.state || '',
                                country: values.country || '',
                                reference: values.reference || '',
                            },
                            discount: 0,
                            deliveryDate: values.deliveryDate,
                            Products: Products,
                            shipping: shipping || 0,
                            miEnvioShipmentId: (sessionData === null || sessionData === void 0 ? void 0 : sessionData.miEnvioShipmentId) || null,
                            miEnvioShipmentRateId: (sessionData === null || sessionData === void 0 ? void 0 : sessionData.miEnvioShipmentRateId) || null,
                            miEnvioToAddressId: (sessionData === null || sessionData === void 0 ? void 0 : sessionData.miEnvioToAddressId) || null,
                            miEnvioFromAddressId: (sessionData === null || sessionData === void 0 ? void 0 : sessionData.miEnvioFromAddressId) || null,
                        },
                    })];
            case 2:
                response = _a.sent();
                ECCustomerSessionData(__assign(__assign({}, sessionData), { conektaId: response.data.createECSaleCheckout.id, conektaCheckoutId: response.data.createECSaleCheckout.checkoutId }));
                ECPaymentStep(2);
                return [2 /*return*/];
            case 3:
                if (!!values.isPickupActive) return [3 /*break*/, 5];
                currentCart = getCart();
                tmpTotal = currentCart.reduce(function (total, el) { return total + el.price; }, 0);
                Products = currentCart.map(function (el) { return ({ productId: el.productId }); });
                return [4 /*yield*/, apolloClient.mutate({
                        mutation: GQL.CREATE_EC_SHIPMENT,
                        variables: {
                            ecName: props.companyName,
                            addressLine1: values.addressLine1,
                            addressLine2: values.addressLine2,
                            zipCode: values.zipCode,
                            city: values.city,
                            municipality: values.municipality,
                            state: values.state,
                            country: values.country,
                            phone: values.phone,
                            email: values.email,
                            reference: values.reference,
                            name: values.name,
                            total: tmpTotal,
                            Products: Products,
                        },
                    })];
            case 4:
                response = _a.sent();
                ECCustomerSessionData({
                    miEnvioShipmentId: response.data.createECShipment.miEnvioShipmentId,
                    miEnvioToAddressId: response.data.createECShipment.miEnvioToAddressId,
                    miEnvioFromAddressId: response.data.createECShipment.miEnvioFromAddressId,
                });
                ECShipmentRates(response.data.createECShipment.ShipmentRates);
                ECPaymentStep(1);
                return [2 /*return*/];
            case 5:
                ECPaymentStep(1);
                return [2 /*return*/, true];
            case 6:
                e_1 = _a.sent();
                getInputErrorFormat(e_1);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); };
export default step1ValidationHelpersContinueButtonActionBodyPayment;
