import React from 'react';
import { Link } from 'react-router-dom';
// import ForumIcon from '@mui/icons-material/Forum';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CopyrightCapitalikeComponent from '../Copyright';
var ContactCapitalikeComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'h-screen  flex flex-row' },
            React.createElement("div", { className: 'flex flex-col flex-1 ' },
                React.createElement("div", { className: 'text-capitalike-blue font-bold text-2xl text-center  mx-40 mt-28' }, "Cont\u00E1ctanos desde donde est\u00E9s y a cualquier hora que necesites."),
                React.createElement("div", { className: 'flex flex-1 flex-row mt-10 ml-10' },
                    React.createElement(QuestionAnswerOutlinedIcon, { className: 'w-20 h-20 ml-6 text-capitalike-gray-font ' }),
                    React.createElement("div", { className: 'w-2 h-20 rounded-full bg-capitalike-blue/40 ' }),
                    React.createElement("div", { className: 'flex flex-col flex-1 mx-6' },
                        React.createElement("div", { className: 'text-justify text-capitalike-gray-font font-bold' }, "No importa d\u00F3nde te encuentres ni a qu\u00E9 hora nos necesites, puedes contactarnos en cualquier momento. Tu comodidad y tranquilidad son nuestra prioridad."),
                        React.createElement(Link, { to: 'https://wa.me/message/S56SWNLK624UB1', target: '_blank', className: 'border-2 border-solid border-capitalike-gray-font hover:bg-capitalike-blue hover:text-white hover:border-capitalike-blue no-underline text-capitalike-gray-font font-bold w-56 mt-4 text-center rounded-lg' },
                            "Cont\u00E1ctanos por",
                            React.createElement("br", null),
                            " WhatsApp"))),
                React.createElement("div", { className: 'flex flex-1 flex-row mt-10 ml-10' },
                    React.createElement(SupportAgentIcon, { className: 'w-20 h-20 ml-6 text-capitalike-gray-font' }),
                    React.createElement("div", { className: 'w-2 h-20 bg-capitalike-blue/40 rounded-full' }),
                    React.createElement("div", { className: 'flex flex-col flex-1 mx-6' },
                        React.createElement("div", { className: 'text-justify text-capitalike-gray-font font-bold' }, "No importa d\u00F3nde te encuentres ni a qu\u00E9 hora nos necesites, puedes contactarnos en cualquier momento. Tu comodidad y tranquilidad son nuestra prioridad."),
                        React.createElement(Link, { to: 'tel:+526671272903', className: 'border-2 border-solid border-capitalike-gray-font hover:bg-capitalike-blue hover:text-white hover:border-capitalike-blue no-underline text-capitalike-gray-font font-bold w-56 mt-4 text-center rounded-lg' },
                            "Cont\u00E1ctanos por",
                            React.createElement("br", null),
                            " Llamada")))),
            React.createElement("div", { className: 'flex flex-col flex-1 items-center' },
                React.createElement("div", { className: 'mx-10 mt-28 bg-capitalike-gray/10 flex flex-1 flex-col rounded-lg w-1/2 items-center text-capitalike-gray-font' },
                    React.createElement(EditOutlinedIcon, { className: 'w-10 h-10 mt-5 text-capitalike-blue/80' }),
                    React.createElement("div", { className: 'mt-2 text-xl font-bold text-capitalike-blue/80' }, "Atenci\u00F3n a medida"),
                    React.createElement("div", { className: 'text-center mt-2 mx-4' }, "Cada cliente reciba un servicio adaptado a sus necesidades y un enfoque \u00FAnicos.")),
                React.createElement("div", { className: 'mx-10 my-5 bg-capitalike-gray/10 flex flex-1 flex-col rounded-lg w-1/2 items-center text-capitalike-gray-font' },
                    React.createElement(ShowChartOutlinedIcon, { className: 'w-10 h-10 mt-5 text-capitalike-blue/80' }),
                    React.createElement("div", { className: 'mt-2 text-xl font-bold text-capitalike-blue/80' }, "Progresamos lado a lado"),
                    React.createElement("div", { className: 'text-center mt-2 mx-4' }, "Avanzamos en conjunto, apoy\u00E1ndonos mutuamente para lograr el \u00E9xito compartido.")),
                React.createElement("div", { className: 'mx-10 mb-5 bg-capitalike-gray/10 flex flex-1 flex-col rounded-lg w-1/2 items-center text-capitalike-gray-font' },
                    React.createElement(BuildOutlinedIcon, { className: 'w-10 h-10 mt-5 text-capitalike-blue/80' }),
                    React.createElement("div", { className: 'mt-2 text-xl font-bold text-capitalike-blue/80' }, "Soporte t\u00E9cnico"),
                    React.createElement("div", { className: 'text-center mt-2 mx-4' }, "Garantizando asistencia constante y colaborativa para resolver cualquier problema.")))),
        React.createElement(CopyrightCapitalikeComponent, null)));
};
export default ContactCapitalikeComponent;
{
    /* <div className='h-screen flex flex-row flex-1 '>
  <div className='flex flex-1 flex-col mt-20'>
    <div className='text-capitalike-blue font-bold text-2xl text-center  mx-40'>
      Contáctanos desde donde estés y a cualquier hora que necesites.
    </div>
    <div className='flex flex-col flex-1 mt-10'>
      <div className='flex flex-row'>
        <img
          src='https://storage.capitalike.com/files/33427'
          alt='Chat'
          className='w-20 h-20 object-contain ml-4'
        />
        <div className='flex flex-col flex-1 mx-5'>
          <div className='text-justify text-capitalike-gray-font font-bold'>
            No importa dónde te encuentres ni a qué hora nos necesites, puedes contactarnos en
            cualquier momento. Tu comodidad y tranquilidad son nuestra prioridad.
          </div>
          <Link
            to='https://wa.me/526671272903'
            className='bg-capitalike-gray-font hover:bg-capitalike-blue no-underline text-white font-bold w-56 mt-4 text-center rounded-lg'
          >
            Contáctanos por
            <br /> WhatsApp
          </Link>
        </div>
      </div>
      <div className='flex flex-row mt-40'>
        <img
          src='https://storage.capitalike.com/files/33451'
          alt='Chat'
          className='w-20 h-20 object-contain ml-4'
        />
        <div className='flex flex-col flex-1 mx-5'>
          <div className='text-justify text-capitalike-gray-font font-bold'>
            No importa dónde te encuentres ni a qué hora nos necesites, puedes contactarnos en
            cualquier momento. Tu comodidad y tranquilidad son nuestra prioridad.
          </div>
          <Link
            to='tel:+526671272903'
            className='border-2 border-solid border-capitalike-gray-font hover:bg-capitalike-blue no-underline text-capitalike-gray-font hover:text-white hover:border-capitalike-blue font-bold w-56 mt-4 text-center rounded-lg'
          >
            Contáctanos por
            <br /> Llamada
          </Link>
        </div>
      </div>
    </div>
  </div>
  </div> */
}
