import { Menu, MenuItem, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import getRouteFromProductMenuValueEs from './getRouteFromMenuValue';
import getProductMenuEsValueFromPath from './getMenuValueFromPath';
var MenuComponentCapitalikeLayout = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getProductMenuEsValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleChange = function (e, value) {
        console.log(1);
        var route = getRouteFromProductMenuValueEs(value);
        console.log(route);
        if (route)
            navigate(route);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'mr-4 md:hidden' },
            React.createElement(IconButton, { onClick: handleClick },
                React.createElement(MenuIcon, { className: 'text-white font-bold' })),
            React.createElement(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    'aria-labelledby': 'basic-button',
                } },
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: '/capitalike' }),
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: 'services' }),
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: 'aboutUs' }),
                React.createElement(MenuItem, { onClick: handleClose, className: 'items-center', component: RouterLink, to: 'contact' }))),
        React.createElement("div", { className: 'hidden md:flex' },
            React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": 'basic tabs example', textColor: 'inherit', indicatorColor: 'primary' },
                React.createElement(Tab, { label: 'Inicio' }),
                React.createElement(Tab, { label: 'Planes y servicios' }),
                React.createElement(Tab, { label: 'Quienes somos' }),
                React.createElement(Tab, { label: 'Contacto' })))));
};
export default MenuComponentCapitalikeLayout;
