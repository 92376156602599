import { createHttpLink } from '@apollo/client';
import config from '../config/config.json';
var httpLink = createHttpLink({
    uri: config.apolloServer.uri,
    credentials: 'same-origin', //include if different backend domain
    headers: {
        'Apollo-Require-Preflight': 'true',
    },
});
export default httpLink;
