import React from 'react';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import getTranslation from '../../../../../locale/lachime/translation';
var ChatButtonComponent = function () {
    var lang = useParams().lang;
    var currentLang = lang === 'en' ? 'en' : 'es';
    var navigate = useNavigate();
    var handleClick = function () {
        navigate('chat');
    };
    return (React.createElement(Button, { variant: 'outlined', onClick: handleClick, className: 'px-6 py-4 border-2 hover:border-2 border-white text-white hover:text-white hover:border-white', fullWidth: false }, getTranslation(currentLang, 'chat.text')));
};
export default ChatButtonComponent;
